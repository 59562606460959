/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderAttach { 
    orderAttachId?: number;
    orderId?: number;
    readonly docId: string;
    docCategoryCode: string;
    fileName: string;
    fileSize: number;
    uploadedTs?: string;
    uploadedBy?: string;
}

