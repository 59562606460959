import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ListItemDtl } from '../../../components/mdm/openapi';
import { ContactCardData } from '../../shared-interfaces/shared-interfaces';
import {attribCanBePrimary} from '../../constants';

@Component({
  selector: 'prism-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnChanges {

  @Input() isReadOnly = true;
  @Input() type = 'existent';
  @Input() contactData: ContactCardData;
  @Input() deleteEnabled = true;
  @Input() height = '190px';
  @Output() addAction: EventEmitter<void> = new EventEmitter();
  @Output() editAction: EventEmitter<void> = new EventEmitter();
  @Output() setDefaultAction: EventEmitter<void> = new EventEmitter();
  @Output() deleteAction: EventEmitter<void> = new EventEmitter();
  public contextEdit = {text: 'Edit', action: 'edit'};
  public contextDelete = {text: 'Delete', action: 'delete'};
  public contextItems = [this.contextEdit, this.contextDelete];
  public openDeleteModal: boolean;
  public deleteModalActions = [
    {
      text: 'Cancel',
      type: 'fifth',
      callback: () => this.openDeleteModal = false
    },
    {
      text: 'Delete Contact',
      icon: 'trash',
      type: 'danger',
      callback: () => this.deleteAction.emit()
    }
  ];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deleteEnabled) {
      this.contextItems = changes.deleteEnabled.currentValue ?
        [this.contextEdit, this.contextDelete] :
        [this.contextEdit];
    }
  }

  onContextSelect(event): void {
    if (event.item.action === 'delete') {
      this.openDeleteModal = true;
    } else if (event.item.action === 'edit') {
      this.editAction.emit();
    }
  }

  setAsDefault(): void {
    this.setDefaultAction.emit();
  }

  closeDeleteModal(): void {
    this.openDeleteModal = false;
  }

  public getContactValMask = (code: string, infoList: Array<ListItemDtl>): string => {
    const cntctType = infoList.find(type => type.listDtlCode === code);
    if (cntctType?.attributes) {
      const attr = cntctType.attributes.find(cntctAttr => cntctAttr.listAttrName === 'contact_mask');
      return attr ? attr.listDtlAttrValue : null;
    } else {
      return null;
    }
  }

  public canUseAsMbrPrimary = (code: string): string => {
    const cntctType = this.contactData.cntctInfoTypes.find(type => type.listDtlCode === code);
    if (cntctType?.attributes) {
      const attr = cntctType.attributes.find(cntctAttr => cntctAttr.listAttrName === attribCanBePrimary);
      return attr ? attr.listDtlAttrValue : null;
    } else {
      return null;
    }
  }

  public getCntctDescription = (code: string, source: string): string => {
    if (source === 'CONTACT_INFO_TYPE') {
      return this.contactData.cntctInfoTypes.find(type => type.listDtlCode === code)?.listDtlDesc;
    } else if (source === 'CONTACT_TYPE') {
      return this.contactData.cntctTypes?.find(type => type.listDtlCode === code)?.listDtlDesc;
    } else {
      return null;
    }
  }
}
