/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Action { 
    id: string;
    domainName: string;
    domainKey: number;
    actionMsg: string;
    actionMsgNum: number;
    actionType: string;
    actionStatus: string;
    actionMeta: object;
    actionTS: string;
    actionBy: string;
    receivedTS: string;
}

