import { throttle } from 'lodash';
import { Subject, Subscription } from 'rxjs';

export class DropDownPagingUtil {
    public pageNumber = 0;
    public allowLoadMore = true;
    public pageSize = 20;
    public onScrollCallback: () => void;

    private scrollSubscription: Subscription;
    private scrollSubject: Subject<any> = new Subject<any>();

    constructor() {
        this.scrollSubscription = this.scrollSubject.subscribe(() => this.loadMore());
    }

    public get restartRowNumber(): number {
        return this.pageNumber * this.pageSize;
    }

    unsubscribe(): void {
        this.scrollSubscription.unsubscribe();
    }

    resetPageNumber(): void {
        this.pageNumber = 0;
    }

    loadMore(): void {
        if (!this.allowLoadMore) {
          return;
        }
        this.pageNumber++;
        this.onScrollCallback();
    }

    addScrollListener(): void {
        setTimeout(() => {
          const elements = Array.from(document.querySelectorAll('.k-list-content'));
          elements.forEach(e => {
            e.addEventListener('scroll', throttle(() => {
              if (!this.allowLoadMore) { return }
              const listItems = e.querySelectorAll('li');
              const el = listItems[listItems.length - 1];
              const rect     = el.getBoundingClientRect();
              const vWidth   = window.innerWidth || document.documentElement.clientWidth;
              const vHeight  = window.innerHeight || document.documentElement.clientHeight;
              if ((rect.right >= 0 || rect.bottom >= 0 || rect.left <= vWidth || rect.top <= vHeight) &&
                (el.contains(document.elementFromPoint(rect.left,  rect.top)) ||
                el.contains(document.elementFromPoint(rect.right, rect.top)) ||
                el.contains(document.elementFromPoint(rect.right, rect.bottom)) ||
                el.contains(document.elementFromPoint(rect.left,  rect.bottom)))) {
                  // not all elements in the list are selectable values
                  this.scrollSubject.next(listItems.length);
              }
            }, 250));
          });
        }, 0);
    }
}
