import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CodeDescPipe } from './dispStatus/code-desc.pipe';
import { Icd10Pipe } from './icd10/icd10.pipe';
import { PhonePipe } from './phone/phone.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { TimePipe } from './time/time.pipe';
import { NullWithDefaultPipe } from './null-with-default/null-with-default.pipe';

const declareExportPipes = [
  CodeDescPipe,
  Icd10Pipe,
  PhonePipe,
  SafeHtmlPipe,
  TimePipe,
  NullWithDefaultPipe,
];

@NgModule({
  declarations: [
    ...declareExportPipes
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ...declareExportPipes
  ]
})
export class SharedPipesModule { }
