import { Component, OnInit } from '@angular/core';
import { MsalService } from './msal.service';

@Component({
  /* eslint "@angular-eslint/component-selector" : "off" */
  selector: 'app-redirect',
  template: ''
})
export class MsalRedirectComponent implements OnInit {

  constructor(private authService: MsalService) { }

  ngOnInit(): void {
    this.authService.getLogger().verbose('MsalRedirectComponent activated');
    this.authService.handleRedirectObservable().subscribe();
  }

}
