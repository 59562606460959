import { Injectable } from '@angular/core';

declare global {
  interface Window { integra: any; }
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  // base path for all API calls
  public basePath = '';

  // TODO:// Dummy JWT token - remove when we build IDM service
  public jwtToken = '';

  // version check interval
  private readonly verCheckInterval = 60;

  constructor() {
    this.basePath = window.integra.basePath;
    this.jwtToken = window.integra.jwtToken;
    if (window.integra.version?.interval) {
      this.verCheckInterval = window.integra.version.interval;
    }
  }

  public getVersionRefreshInterval(): number {
    return this.verCheckInterval;
  }
}
