/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PayerSearch { 
    readonly payerSearchId?: number;
    readonly payerFamId?: number;
    readonly payerFamName?: string;
    readonly payerPlanId?: number;
    readonly payerPlanName?: string;
    readonly payerAcctId?: number;
    readonly payerAcctName?: string;
    readonly payerLobId?: number;
    readonly payerLobName?: string;
    readonly payerName?: string;
}

