import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppComponentService {

  constructor(
      private httpClient: HttpClient
  ) { }

  public getAppVersion(): Observable<string> {
    return this.httpClient.get('assets/version', {
      responseType: 'text',
      // force to not cache version
      headers: new HttpHeaders({
        'Cache-Control': 'no-store, no-cache',
        Pragma: 'no-cache',
      })
    });
  }
}
