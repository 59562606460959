import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MemberFullInformation} from '../../../components/members/openapi';
import {DateHelperService} from '../../shared-services/date-helper/date-helper.service';
import {DateTime} from 'luxon';
import {AuditInfo} from '../../shared-interfaces';

@Component({
  selector: 'prism-audit-info',
  templateUrl: './audit-info.component.html',
  styleUrls: ['./audit-info.component.scss']
})
export class AuditInfoComponent implements OnInit, OnChanges {

  @Input() record: AuditInfo;
  public show = false;
  public createdBy: string;
  public createdTs: string;
  public modifiedBy: string;
  public modifiedTs: string;

  constructor(private dateHelperService: DateHelperService) { }

  ngOnInit(): void {
    this.updateFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFields();
  }

  private updateFields(): void {
    if (this.record !== undefined && this.record.createdTs != null) {
      this.show = true;
      this.createdBy = this.record.createdBy;
      this.createdTs = this.processTimeStamp(this.record.createdTs);
      this.modifiedBy = this.record.modifiedBy;
      this.modifiedTs = this.processTimeStamp(this.record.modifiedTs);
    } else {
      this.show = false;
    }
  }

  private processTimeStamp(field: string | Date): string {
    if (typeof field === 'string') {
      return this.formatDateTimeFromString(field as string);
    } else {
      return this.formatDateTimeFromDate(field);
    }
  }

  private formatDateTimeFromString(from: string): string {
    return this.formatDateTimeFromDate(this.dateHelperService.fromISO(from));
  }

  private formatDateTimeFromDate(field: Date): string {
    return this.dateHelperService.toLocaleString(field, DateTime.DATETIME_SHORT);
  }
}
