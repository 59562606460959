/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderGrouped { 
    orderId?: number;
    order_num?: string;
    payerFamilyId?: number;
    payerAcctId?: number;
    payerLobId?: number;
    provFacId?: number;
    mbrId?: number;
    authCodes?: Array<string>;
    ordStatusCode?: string;
    expOrderDeliveryTS?: string;
}

