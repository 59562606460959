import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[prismAsideDrawerContent]',
})
export class DrawerAsideContentDirective {
  @Input() collapsible: boolean = true;
  @Input() size: string;
  @Input() resizable: boolean = false;
  constructor(public templateRef: TemplateRef<any>) {}
}
