/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PayerPlanDetails { 
    readonly payerPlanId?: number;
    payerFamId?: number;
    readonly payerFamName?: string;
    payerPlanName: string;
    readonly status: string;
    activeFromDt: string;
    activeToDt?: string | null;
    readonly modifiedBy?: string;
    readonly modifiedTs?: string;
    readonly createdBy?: string;
    readonly createdTs?: string;
}

