/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderProvider } from './orderProvider';


export interface OrderListEntry { 
    statusAge?: number | null;
    orderId?: number;
    readonly payerLobId?: number;
    readonly payerLobName?: string;
    readonly payerAcctId?: number;
    readonly payerAcctName?: string;
    readonly payerPlanId?: number;
    readonly payerPlanName?: string;
    readonly payerFamId?: number;
    readonly payerFamName?: string;
    readonly mbrId?: number;
    readonly mbrFirstName?: string;
    readonly mbrLastName?: string;
    readonly altSysId?: string | null;
    readonly orderStatusAssignedTS?: string;
    readonly expOrderDeliveryTS?: string;
    orderNum?: string;
    orderStatusCode?: string;
    readonly orderStatusDesc?: string;
    authWinFromDt?: string;
    authWinToDt?: string;
    staIndvAddressID?: number;
    staLine1?: string;
    staLine2?: string;
    staCity?: string;
    staState?: string;
    staZip?: string;
    staTypeCode?: string;
    mbrHasRef?: boolean;
    isMedicarePrimOrd?: boolean;
    isTransitionOrder: boolean;
    orderDlvInstr?: string;
    addtnlCmnt?: string;
    organizationName?: string;
    facilityName?: string;
    orderProvider?: OrderProvider;
    authCodes?: Array<string>;
    hcpcCodes?: Array<string>;
    readonly shippedTs?: string;
    readonly deliveredTs?: string;
    readonly createdTs?: string;
    readonly createdBy?: string;
    readonly assignedTs?: string;
    readonly isLateDlvry?: boolean;
    readonly modifiedTs?: string;
    readonly modifiedBy?: string;
    readonly trackingNum?: string;
    statusReasonCode?: string;
    orderSystemType?: string;
    statusReasonDesc?: string;
    statusSubReasonCode?: string;
    statusSubReasonDesc?: string;
    prevInterReasonCode?: string;
    prevInterReasonDesc?: string;
}

