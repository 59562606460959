import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  ContextMenuItem,
  DataSource,
  IndvAddressesShared,
} from '../../shared-interfaces/shared-interfaces';

@Component({
  selector: 'prism-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent {
  @Input() type: string;
  @Input() addressData: IndvAddressesShared;
  @Input() isReadOnly = false;
  @Input() isMain = false;
  @Input() selectable = true;
  @Output() setSelectedAddress: EventEmitter<IndvAddressesShared> =
    new EventEmitter();
  @Output() setDefaultAddressAction: EventEmitter<void> = new EventEmitter();
  @Output() addAction: EventEmitter<void> = new EventEmitter();
  @Output() editAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() delete: EventEmitter<number> = new EventEmitter();
  @Output() relocateAction: EventEmitter<number> = new EventEmitter();
  @Input() contextItems: Array<ContextMenuItem> = [
    { text: 'Edit', action: 'edit' },
    { text: 'Delete', action: 'delete' },
  ];
  public openDeleteModal = false;
  public deleteModalActions = [
    {
      text: 'Cancel',
      type: 'fifth',
      callback: () => (this.openDeleteModal = false),
    },
    {
      text: 'Delete address',
      icon: 'trash',
      type: 'danger',
      callback: () => this.deleteAddress(),
    },
  ];
  public dataSources = DataSource;

  constructor() {}

  onContextSelect(event): void {
    if (event.item.action === 'delete') {
      this.openDeleteModal = true;
    } else if (event.item.action === 'edit') {
      this.editAction.emit();
    } else if (event.item.action === 'relocate') {
      this.relocateAction.emit();
    }
  }

  deleteAddress(): void {
    this.delete.emit(this.addressData.id);
    this.openDeleteModal = false;
  }

  closeDeleteModal(): void {
    this.openDeleteModal = false;
  }

  setAsDefaultAddress(): void {
    this.setDefaultAddressAction.emit();
  }
}
