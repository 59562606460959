import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutTemplateModule } from '../layout/layout.module';
import { LayoutAccessResolver } from '../shared/shared-access-module/resolver/layout/layout-access-resolver';
import { SharedComponentsModule } from '../shared/shared-components/shared-components.module';
import { SharedServicesModule } from '../shared/shared-services/shared-services.module';
import { NotAuthorizedComponent } from './home/unauthorized.page/not-authorized.component';
import { LogoutComponent } from './logout/logout.component';
import { routes } from './routes';

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  declarations: [
    NotAuthorizedComponent,
    LogoutComponent,
  ],
  imports: [
    CommonModule,
    SharedServicesModule,
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
      paramsInheritanceStrategy: 'always'
    }),
    LayoutTemplateModule,
    SharedComponentsModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    LayoutAccessResolver
  ]
})
export class ComponentsModule { }

