import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { TextAreaResize } from '@progress/kendo-angular-inputs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

export const PRISM_TEXTAREA_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaComponent),
  multi: true
};

@Component({
  selector: 'prism-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [PRISM_TEXTAREA_CONTROL_VALUE_ACCESSOR]
})
export class TextareaComponent {

  @Input() autoResize: TextAreaResize = 'auto';
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() rows: number;

  private content: string;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() { }

  get value(): any {
    return this.content;
  }

  writeValue(value: any): void {
    if (typeof value === 'string' && value !== this.content) {
      this.content = value;
      this.onChangeCallback(value);
    }
  }

  set value(value: any) {
    if (value !== this.content) {
      this.content = value;
      this.onChangeCallback(value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
