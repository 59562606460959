/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListItemCtx } from './listItemCtx';
import { ListItemAttr } from './listItemAttr';


export interface ListItem { 
    listTypeId?: number;
    listTypeName: string;
    listTypeDesc: string;
    listTypeStartDt: string;
    isSysList?: boolean;
    isRoList?: boolean;
    attributes?: Array<ListItemAttr>;
    contexts?: Array<ListItemCtx>;
}

