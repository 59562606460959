/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Errors } from '../model/errors';
// @ts-ignore
import { HcpcCategory } from '../model/hcpcCategory';
// @ts-ignore
import { HcpcCode } from '../model/hcpcCode';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class HCPCListService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get HCPC categories by category type code
     * @param hcpcCatgTypeCode HCPC Category Type Code
     * @param pageSize 
     * @param restartRowId 
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHcpcCatgByType(hcpcCatgTypeCode: string, pageSize?: number, restartRowId?: number, filter?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<HcpcCategory>>;
    public getHcpcCatgByType(hcpcCatgTypeCode: string, pageSize?: number, restartRowId?: number, filter?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<HcpcCategory>>>;
    public getHcpcCatgByType(hcpcCatgTypeCode: string, pageSize?: number, restartRowId?: number, filter?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<HcpcCategory>>>;
    public getHcpcCatgByType(hcpcCatgTypeCode: string, pageSize?: number, restartRowId?: number, filter?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (hcpcCatgTypeCode === null || hcpcCatgTypeCode === undefined) {
            throw new Error('Required parameter hcpcCatgTypeCode was null or undefined when calling getHcpcCatgByType.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (pageSize !== undefined && pageSize !== null) {
            localVarHeaders = localVarHeaders.set('Page-Size', String(pageSize));
        }
        if (restartRowId !== undefined && restartRowId !== null) {
            localVarHeaders = localVarHeaders.set('Restart-Row-Id', String(restartRowId));
        }
        if (filter !== undefined && filter !== null) {
            localVarHeaders = localVarHeaders.set('Filter', String(filter));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/hcpcs/types/${this.configuration.encodeParam({name: "hcpcCatgTypeCode", value: hcpcCatgTypeCode, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/categories`;
        return this.httpClient.request<Array<HcpcCategory>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a HCPC by code
     * @param hcpcCode HCPC code to retrieve
     * @param withCatgInfo If provided, return items only applicable in specified category code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHcpcCode(hcpcCode: string, withCatgInfo?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HcpcCode>;
    public getHcpcCode(hcpcCode: string, withCatgInfo?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<HcpcCode>>;
    public getHcpcCode(hcpcCode: string, withCatgInfo?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<HcpcCode>>;
    public getHcpcCode(hcpcCode: string, withCatgInfo?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (hcpcCode === null || hcpcCode === undefined) {
            throw new Error('Required parameter hcpcCode was null or undefined when calling getHcpcCode.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (withCatgInfo !== undefined && withCatgInfo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withCatgInfo, 'withCatgInfo');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/hcpcs/code/${this.configuration.encodeParam({name: "hcpcCode", value: hcpcCode, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<HcpcCode>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of HCPCs
     * @param sort 
     * @param restartRowId 
     * @param pageSize 
     * @param filter 
     * @param categoryCode If provided, return items only applicable in specified category code
     * @param categoryType If provided, return items only applicable in specified category type
     * @param withCatgInfo If provided, return items only applicable in specified category code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHcpcCodes(sort?: string, restartRowId?: number, pageSize?: number, filter?: string, categoryCode?: string, categoryType?: string, withCatgInfo?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<HcpcCode>>;
    public getHcpcCodes(sort?: string, restartRowId?: number, pageSize?: number, filter?: string, categoryCode?: string, categoryType?: string, withCatgInfo?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<HcpcCode>>>;
    public getHcpcCodes(sort?: string, restartRowId?: number, pageSize?: number, filter?: string, categoryCode?: string, categoryType?: string, withCatgInfo?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<HcpcCode>>>;
    public getHcpcCodes(sort?: string, restartRowId?: number, pageSize?: number, filter?: string, categoryCode?: string, categoryType?: string, withCatgInfo?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (categoryCode !== undefined && categoryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>categoryCode, 'categoryCode');
        }
        if (categoryType !== undefined && categoryType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>categoryType, 'categoryType');
        }
        if (withCatgInfo !== undefined && withCatgInfo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withCatgInfo, 'withCatgInfo');
        }

        let localVarHeaders = this.defaultHeaders;
        if (sort !== undefined && sort !== null) {
            localVarHeaders = localVarHeaders.set('Sort', String(sort));
        }
        if (restartRowId !== undefined && restartRowId !== null) {
            localVarHeaders = localVarHeaders.set('Restart-Row-Id', String(restartRowId));
        }
        if (pageSize !== undefined && pageSize !== null) {
            localVarHeaders = localVarHeaders.set('Page-Size', String(pageSize));
        }
        if (filter !== undefined && filter !== null) {
            localVarHeaders = localVarHeaders.set('Filter', String(filter));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/hcpcs`;
        return this.httpClient.request<Array<HcpcCode>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
