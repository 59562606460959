import { Injectable, inject, signal } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { CanDeactivateComponent } from '../../shared-interfaces/can-deactivate-component.interface';

export const canDeactivateGuard: CanDeactivateFn<CanDeactivateComponent> = (
  component: CanDeactivateComponent,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
) => {
  const hasUnsavedChangesService = inject(CanDeactivateSignalService);

  if (!component.hasUnsavedChanges || hasUnsavedChangesService.confirmed()) {
    hasUnsavedChangesService.confirmed.set(false);
    return true;
  }

  hasUnsavedChangesService.open.set(true);
  hasUnsavedChangesService.newPath.set(nextState.url);

  return false;
};

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateSignalService {
  open = signal(false);
  newPath = signal('');
  confirmed = signal(false);
}
