import { Component } from '@angular/core';
import { MyProfileService } from '../../shared-services/my-profile/my-profile.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilitiesService } from '../../shared-services/utilities/utilities.service';

@Component({
  templateUrl: './terms-conditions-dialog.component.html',
  styleUrls: ['./terms-conditions-dialog.component.scss']
})
export class TermsConditionsDialogComponent {

  public isActionEnabled = false;
  private redirectUrl: string;
  private privacy: string;
  private terms: string;

  constructor(
      private myProfileService: MyProfileService,
      private route: ActivatedRoute,
      protected http: HttpClient, private sanitizer: DomSanitizer ,
      private utilitiesService: UtilitiesService,
  ) {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.myProfileService.getMyProfile().pipe(take(1)).subscribe((userProfile) => {
        this.http.get('assets/meta/privacy.html', { responseType: 'text'}).subscribe((privacy) => {
          this.http.get('assets/meta/terms.html', { responseType: 'text'}).subscribe((terms) => {
            this.initTcView(userProfile.isTermsAccepted, params.redirect, privacy, terms);
          });
        });
      });
    });
  }

  private close(): void {
    this.utilitiesService.navigateToRoute(this.redirectUrl);
  }

  confirm(): void {
    this.myProfileService.patchMyProfile({
      isTermsAccepted: true,
    }).subscribe(() => {
      this.close();
    });
  }

  private initTcView(isTermsAccepted: boolean, redirectUrl: string, privacy: string, terms: string): void {
    this.redirectUrl = redirectUrl || '/home';
    this.privacy = privacy;
    this.terms = terms;
    if (isTermsAccepted) { // terms already accepted
      this.close();
    } else {
      this.isActionEnabled = true;
    }
  }

  disagree(): void {
    this.redirectUrl = '/logout';
    this.close();
  }
}
