/**
 * Access Control Management
 * Access Control Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountItem } from './accountItem';
import { RoleItem } from './roleItem';


export interface UserWithRolesAccts { 
    readonly userId?: number;
    firstName: string;
    lastName: string;
    userName: string;
    emailAddress: string;
    phoneNumber?: number | null;
    jobTitle?: string | null;
    userStatusCode: string;
    readonly userTypeCode?: string;
    isTermsAccepted?: boolean;
    isPwdResetRequired?: boolean;
    roles: Array<RoleItem>;
    accounts: Array<AccountItem>;
    readonly modifiedTs?: string;
    readonly modifiedBy?: string;
    readonly createdTs?: string;
    readonly createdBy?: string;
}

