import { Component, Input, OnInit } from '@angular/core';
import { TooltipIcon } from '../../shared-enums/tooltipIcon.enum';

@Component({
  selector: 'prism-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})

export class TooltipComponent{
  @Input() tooltipIcon: TooltipIcon = TooltipIcon.info;
  @Input() title: string;
  @Input() tooltipWidth = '200';
  @Input() tooltipHeight = 'auto';

  constructor() { }
}
