import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { DropDownPageEvent } from '../../shared-interfaces';
import { DropDownPagingUtil } from '../dropdown-paging-utils';


export const PRISM_DROPDOWN_LIST_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownListComponent),
  multi: true
};

@Component({
  selector: 'prism-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
  providers: [PRISM_DROPDOWN_LIST_CONTROL_VALUE_ACCESSOR]
})
export class DropdownListComponent implements ControlValueAccessor, OnInit {

  @Input() disabled = false;
  @Input() data: any[];
  @Input() pageSize = 0;

  @Output() loadNextPage: EventEmitter<DropDownPageEvent> = new EventEmitter<DropDownPageEvent>();

  public get paging(): boolean {
    return this.pageSize > 0;
  }

  private innerValue: any;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  private pagingUtil = new DropDownPagingUtil();

  constructor() { }

  ngOnInit(): void {
    if (this.paging) {
      this.pagingUtil.pageSize = this.pageSize;
      this.pagingUtil.onScrollCallback = this.onScrollCallback.bind(this);
    }
  }

  expandDropdown(): void {
    if (this.paging) {
      this.pagingUtil.addScrollListener();
    }
  }
  onScrollCallback() {
    this.loadNextPage.emit({
      pageNumber: this.pagingUtil.pageNumber,
      restartRowNumber: this.pagingUtil.restartRowNumber,
      pageSize: this.pagingUtil.pageSize,
      filter: ''
    });
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    this.writeValue(value);
  }

  writeValue(value): void {
    this.innerValue = value;
    this.onChangeCallback(this.innerValue);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

}
