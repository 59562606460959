/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderChangesByStatusReq { 
    byStatus: OrderChangesByStatusReq.ByStatusEnum;
}
export namespace OrderChangesByStatusReq {
    export type ByStatusEnum = 'PENDING_ACCEPTANCE' | 'PROVIDER_NAQ' | 'RECURRING';
    export const ByStatusEnum = {
        PendingAcceptance: 'PENDING_ACCEPTANCE' as ByStatusEnum,
        ProviderNaq: 'PROVIDER_NAQ' as ByStatusEnum,
        Recurring: 'RECURRING' as ByStatusEnum
    };
}


