import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchDataList } from '../../shared-interfaces';
import { noop } from 'rxjs';
import { flatMap } from 'lodash';

export const MULTISELECTTREE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectTreeDropdownComponent),
  multi: true
};
@Component({
  selector: 'prism-multiselect-tree-dropdown',
  templateUrl: './multiselect-tree-dropdown.component.html',
  styleUrls: ['./multiselect-tree-dropdown.component.scss'],
  providers: [MULTISELECTTREE_CONTROL_VALUE_ACCESSOR]
})
export class MultiselectTreeDropdownComponent implements ControlValueAccessor {
  @Input() data: SearchDataList[] = [];
  @Input() placeholder = '';
  @Input() disabled = false;

  @Output() selectionChange: EventEmitter<any[]> = new EventEmitter();

  protected innerValue: any[] = [];
  protected innerModel;

  private get flatData(): SearchDataList[] {
    return flatMap(this.data, item => {
      if (item.items) {
        return [item, ...item.items];
      }
      return item;
    });
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: () => void = noop;

  get value(): any {
    return this.innerValue;
  }

  set value(obj: any) {
    this.writeValue(obj);
  }

  get model(): any {
    return this.innerModel;
  }

  set model(obj: any) {
    const values = Array.isArray(obj) ? obj.map(item => item.id) : [];
    this.writeValue(values);
  }

  writeValue(obj: any): void {
    this.innerValue = obj;
    this.innerModel = Array.isArray(this.flatData) ? this.flatData.filter(item => this.innerValue?.includes(item.id)) : [];
    this.selectionChange.emit(this.innerValue);
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public isItemSelected(item: SearchDataList): boolean {
    return this.innerModel.includes(item);
  }
}
