/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PendingIntegraReviewDtls { 
    reasonCode: string;
    readonly reasonCodeDesc?: string;
    notes?: string | null;
    readonly prevInterReasonCode?: string;
    readonly prevInterReasonCodeDesc?: string;
}

