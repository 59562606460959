import { Pipe, PipeTransform } from '@angular/core';
import { MdmService } from '../../services/mdm.service';

@Pipe({
  name: 'mdmCodeToDesc'
})
export class MdmCodeToDescPipe implements PipeTransform {
  constructor(private mdmService: MdmService) {}

  transform(value: string): string {
    return this.mdmService.codeToDesc(value);
  }

}
