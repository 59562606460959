import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreadCrumbItemI } from '../../shared-interfaces';

@Injectable({
  providedIn: 'root'
})

export class BreadcrumbsService {
  private items: BehaviorSubject<BreadCrumbItemI[]> = new BehaviorSubject<BreadCrumbItemI[]>([]);

  public items$: Observable<BreadCrumbItemI[]> = this.items.asObservable();

  constructor(
    private router: Router
  ) { }

  reset(): void {
    this.setItems([]);
  }

  setItems(items: BreadCrumbItemI[]): void {
    this.items.next(items);
  }

  selectItem(item: BreadCrumbItemI): void {
    if (item.path) {
      this.router.navigate(item.path, { state: item.state });
    }
  }
}
