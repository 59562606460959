import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuEvent, MenuSelectEvent } from '@progress/kendo-angular-menu';
import { filter, map, tap } from 'rxjs/operators';
import { datePipeLocale } from 'src/app/shared/constants';

import { environment } from '../../environments/environment';
import { SESSION_INSTANCE } from '../msal/constants';
import { MsalService } from '../msal/msal.service';
import { AccessInfo } from '../shared/shared-access-module/openapi';
import { AccessControlService } from '../shared/shared-access-module/services/access-control.service';
import {
  DrawerConfig,
  MenuItem,
  ModalAction,
  UserMenuItem,
} from '../shared/shared-interfaces/shared-interfaces';
import {
  SessionInfo,
  SessionService,
} from '../shared/shared-services/session/session.service';
import { MenuService } from './services/menu/menu.service';
import { FeatureFlagService } from '../shared/shared-services/feature-flag/feature-flag.service';
import { combineLatest } from 'rxjs';
import { uniq } from 'lodash';

@Component({
  selector: 'prism-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public drawerConfig: DrawerConfig = {
    mode: 'push',
    isExpanded: true,
    autoCollapse: false,
  };
  public menuItems: MenuItem[];
  public userMenuItems: UserMenuItem[];
  public breadcrumbs: string;
  public currentYear = Date.now();
  public privacyPolicyURL = environment.privacyPolicyURL;
  public termsURL = environment.termsURL;
  public subMenuOpen = false;
  public selectedMenu: MenuItem;
  public fullName: string;
  public userTypeCode: string;
  public iconsMap: { [key: string]: string } = {
    userHeader: 'k-i-arrow-chevron-down',
    settings: 'k-i-cogs',
    notifications: 'k-i-info',
    Logout: 'k-i-logout',
    Administration: 'k-i-gear',
    'Patient Management': 'k-i-zoom',
    'Member Management': 'k-i-user',
    'Payers Management': 'k-i-paste-plain-text',
    'Order Management': 'k-i-copy',
  };
  public classMap: { [key: string]: string } = {
    userFullName: 'user-full-name',
  };
  public dateLocalePipe = datePipeLocale;

  public showMaintenanceDialog = false;
  public timeUntilRedirect: string;
  public maintenancePopupActions: ModalAction[] = [
    {
      text: 'OK',
      type: 'primary',
      callback: (_) => { this.showMaintenanceDialog = false; }
    }
  ];

  constructor(
    private menuService: MenuService,
    private router: Router,
    private accessControlService: AccessControlService,
    private authService: MsalService,
    private featureFlagService: FeatureFlagService,
    @Inject(SESSION_INSTANCE) private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.featureFlagService.onChanges().pipe(filter(changes => {
      return changes.flagKey === 'platform-maintenance' && changes.current;
    })).subscribe(_ => {
      this.showMaintenanceDialog = true;
      this.timeUntilRedirect = window.integra.maintenance.timeText;
      setTimeout(() => {
        this.router.navigate(['/maintenance']);
      }, window.integra.maintenance.timeout);
    });
    this.sessionService.sessionInfo
      .pipe(filter(Boolean))
      .subscribe((sessionInfo: SessionInfo) => {
        this.userTypeCode = sessionInfo.userTypeCode;
        this.fullName =
          sessionInfo.givenName.toUpperCase() +
          ' ' +
          sessionInfo.familyName.toUpperCase();
      });
    this.getMenuItems();
    this.getUserMenuItems();
  }

  getMenuItems(): void {
    const menuItems = this.menuService.getMenuItems();
    const flags = uniq(menuItems.filter(item => !!item.featureFlag))
      .map(item => item.featureFlag).concat(menuItems.map(item =>
        item.items?.filter(child => !!child.featureFlag)
          .map(child => child.featureFlag))
        .reduce((accumulator, value) => accumulator.concat(value), []));
    if (flags.length > 0) {
      const flagOBs = flags.map(flag => {
        return this.featureFlagService.getFlag(flag).pipe(map(value => {
          return {
            flag: flag,
            value: value
          };
        }));
      });
      combineLatest(flagOBs).pipe(
        map(value => value.filter(val => !val.value).map(val => val.flag))
      ).subscribe(flags => {
        const filtered = menuItems.filter(item => this.isHiddenByFlag(item, flags))
          .map(item => {
            if (item.items?.length) {

              item.items = item.items.filter(child => this.isHiddenByFlag(child, flags));
            }
            return item;
          });
        this.getAccessForMenuItems(filtered);
      });
    }
    else {
      this.getAccessForMenuItems(menuItems);
    }
  }

  private isHiddenByFlag(menuItem: MenuItem, featureFlags: string[]): boolean {
    return !menuItem.featureFlag || !featureFlags.includes(menuItem.featureFlag);
  }

  private getAccessForMenuItems(menuItems: MenuItem[]): void {
    for (let idx = menuItems.length - 1; idx >= 0; idx--) {
      this.accessControlService.addPermission([menuItems[idx]], true);
      if (menuItems[idx].items) {
        this.accessControlService.addPermission(menuItems[idx].items, true);
        menuItems[idx].items = menuItems[idx].items.filter(item =>
          item.actions?.includes(AccessInfo.ActionsEnum.Enabled));
      }
    }
    this.menuItems = menuItems.filter(item =>
      item.items?.length || item.actions?.includes(AccessInfo.ActionsEnum.Enabled))
      .map(item => {
        item.collapsedItem = [{
          text: item.text,
          header: true,
          items: item.items.map(sub => ({ text: sub.text, path: sub.path }))
        }];
        return item;
      });
  }

  getUserMenuItems(): void {
    this.userMenuItems = this.menuService.getUserMenuItems(this.fullName);
  }

  iconClass({ text }: UserMenuItem): string | undefined {
    return this.iconsMap[text];
  }

  textClass(text: string): string | undefined {
    return this.classMap[text];
  }

  onSelect(selected: MenuItem): void {
    selected.expanded = !selected.expanded;
    if (!!selected.path) {
      this.router.navigate([selected.path]);
    }
  }

  onSelectCollapsed($event: MenuEvent): void {
    if (!!$event.item.path) {
      this.router.navigate([$event.item.path]);
    }
  }

  getCollapsedMenuItem(item: MenuItem): UserMenuItem[] {
    const itemr = [
      {
        text: item.text,
        items: item.items.map((sub) => ({ text: sub.text })),
      },
    ];
    return itemr;
  }

  selectUserMenuItem($event: MenuSelectEvent): void {
    if ($event.item.action === 'logoutFromApp') {
      this.authService.logout();
    }
  }

  toggleSubMenu(item: MenuItem = null): void {
    this.selectedMenu = item;
    this.menuItems.forEach((menuItem) => {
      menuItem.expanded = item === menuItem ? !menuItem.expanded : false;
    });
    this.subMenuOpen = this.menuItems.some((item) => item.expanded);
  }
}
