import {LDFlagValue} from 'launchdarkly-js-client-sdk';
import {Observable, of} from 'rxjs';
import {FlagChange} from './feature-flag.service';
import {FeatureFlag} from './feature-flag.type';

export class FeatureFlagNoOpService implements FeatureFlag {

  constructor() {
    console.warn('using no-op feature flag management! fix configuration...');
  }

  public getFlag(flagKey: string, defaultValue: LDFlagValue): Observable<LDFlagValue> {
    return of(false);
  }

  public getBooleanFlag(flagKey: string, defaultValue: boolean = false): Observable<boolean> {
    return of(false);
  }

  public onChanges(): Observable<FlagChange> {
    return of({flagKey: '', previous: true, current: false});
  }
  public setSession(userId: string, familyName: string, giveName: string, email: string, userType: string, roles: string[]): void {}
  public resetSession(): void {}
}
