/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProviderPosition } from './providerPosition';
import { FacilityHcpcCategory } from './facilityHcpcCategory';
import { BusinessHrs } from './businessHrs';
import { Languages } from './languages';


export interface FacilityDto { 
    isVerifiedLoc?: boolean;
    isHistProv?: boolean;
    organizationId?: number;
    organizationLegalName?: string | null;
    organizationDBAName?: string | null;
    organizationStatus?: string | null;
    facilityId?: number;
    storeName?: string | null;
    status?: string | null;
    /**
     * distance from member in miles
     */
    distanceFromMbr?: number | null;
    phoneNumber?: string | null;
    faxNumber?: string | null;
    businessHrs?: Array<BusinessHrs> | null;
    languages?: Array<Languages> | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    county?: string | null;
    rank?: Array<FacilityDto.RankEnum> | null;
    ormEnabled?: FacilityDto.OrmEnabledEnum;
    providerPosition?: ProviderPosition;
    isNationWideProvider?: boolean;
    isVerifiedProvider?: boolean;
    hcpcCategories?: Array<FacilityHcpcCategory>;
}
export namespace FacilityDto {
    export type RankEnum = 'Base' | 'AvoidSLA' | 'AvoidPI';
    export const RankEnum = {
        Base: 'Base' as RankEnum,
        AvoidSla: 'AvoidSLA' as RankEnum,
        AvoidPi: 'AvoidPI' as RankEnum
    };
    export type OrmEnabledEnum = 'All' | 'Enabled' | 'Disabled';
    export const OrmEnabledEnum = {
        All: 'All' as OrmEnabledEnum,
        Enabled: 'Enabled' as OrmEnabledEnum,
        Disabled: 'Disabled' as OrmEnabledEnum
    };
}


