/**
 * Access Control Management
 * Access Control Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccessInfo { 
    item?: string;
    widget?: string;
    route?: string;
    actions: Array<AccessInfo.ActionsEnum>;
}
export namespace AccessInfo {
    export type ActionsEnum = 'enabled' | 'visible' | 'view.in.grid' | 'view.edd' | 'view.provider';
    export const ActionsEnum = {
        Enabled: 'enabled' as ActionsEnum,
        Visible: 'visible' as ActionsEnum,
        ViewInGrid: 'view.in.grid' as ActionsEnum,
        ViewEdd: 'view.edd' as ActionsEnum,
        ViewProvider: 'view.provider' as ActionsEnum
    };
}


