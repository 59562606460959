import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagService } from '../../shared-services/feature-flag/feature-flag.service';
import { map } from 'rxjs/operators';

export function maintenanceModeGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const currentPath = route.routeConfig?.path;
  const router = inject(Router);
  return inject(FeatureFlagService).getFlag('platform-maintenance', false).pipe(
    map(isEnabled => {
      if (isEnabled && currentPath !== 'maintenance') {
        router.navigate(['/maintenance']);
        return false;
      }

      if (!isEnabled && currentPath === 'maintenance') {
        return false;
      }
      
      return true;
    })
  );
}
