/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Contact } from './contact';
import { LobTimeliness } from './lobTimeliness';


export interface PayerLobDetails { 
    readonly payerLobId?: number;
    payerFamId?: number;
    readonly payerFamName?: string;
    payerPlanId?: number;
    readonly payerPlanName?: string;
    payerAcctId?: number;
    readonly payerAcctName?: string;
    payerLobName: string;
    altSysID?: string;
    orderReceiptMethodCode: string;
    readonly status: string;
    benefitTypeCode: string;
    ormEnabled: boolean;
    clmEnabled: boolean;
    activeFromDt: string;
    activeToDt?: string | null;
    readonly modifiedBy?: string;
    readonly modifiedTs?: string;
    readonly createdBy?: string;
    readonly createdTs?: string;
    addresses: Array<Address>;
    contacts: Array<Contact>;
    lobTimeliness?: Array<LobTimeliness>;
}

