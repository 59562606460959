import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RoleItem, RoleItemList, RolesService, UserRoleType } from 'src/app/shared/shared-access-module/openapi';
import { SettingsService } from 'src/app/shared/shared-services/settings/settings.service';
import { UserRolesUtilitiesService } from './user-roles-utilities.service';
import { LookUpListItemDtl } from '../../../shared/shared-interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  constructor(
    private settingsService: SettingsService,
    private rolesService: RolesService
  ) {
    this.rolesService.configuration.basePath = `${this.settingsService.basePath}/ss/acm`;
    this.rolesService.configuration.withCredentials = true;
  }

  public getRoles(): Observable<RoleItemList[]> {
    return this.rolesService.getRoles();
  }

  public updateRolesByUser(userRoleType: UserRoleType): Observable<RoleItemList[]> {
    return this.rolesService.updateRolesByUser(userRoleType);
  }

  public mapUserRoleType(apiConfig: any): UserRoleType {
    return {
      userId: apiConfig.userId ? Number(apiConfig.userId) : 0,
      userTypeCode: 'PAYER'
    } as UserRoleType;
  }

  public getDataList(listTypeName: string, apiConfig: any): Observable<LookUpListItemDtl[]> {
    if (listTypeName === 'roles') {
      return this.updateRolesByUser(this.mapUserRoleType(apiConfig)).pipe(
          map(UserRolesUtilitiesService.mapRolesToLookupItems)
        );
    }
  }

  public getRolesByUser(payerFamId: number, userId: number): Observable<Array<RoleItem>> {
    return this.rolesService.getRolesByUser(payerFamId, userId)
      .pipe(
        catchError(error => {
          return of([]);
        })
      );
  }

  public updateUserRoles(
    payerFamId: number, userId: number, roleItem: Array<RoleItem>, oldRoles: Array<RoleItem>
  ): Observable<Array<RoleItem>> {
    const diff = UserRolesUtilitiesService.rolesDiff(oldRoles, roleItem);
    return oldRoles.length !== roleItem.length || diff.length ?
      this.rolesService.updateUserRoles(payerFamId, userId, roleItem) :
      of([]);
  }
}
