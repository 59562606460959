import { Injectable } from '@angular/core';
import { AccessControlService } from '../../shared-access-module/services/access-control.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessInfo } from '../../shared-access-module/openapi';

export interface PathAccessConfig {
  loadRoutes: string[],
  loadWidgets: string[]
}
@Injectable({
  providedIn: 'root'
})
export class RouteActivationService {
  constructor(
    private accessControlService: AccessControlService,
    private router: Router
  ) { }

  public hasPermission(route: ActivatedRouteSnapshot, config: PathAccessConfig): Observable<boolean> {
    return this.loadAccessInfo(config).pipe(
      map(_ => {
        if (this.computeRouteAndAccess(route)) {
          return true;
        }
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      })
    );
  }

  private computeRouteAndAccess(route: ActivatedRouteSnapshot): boolean {
    let baseUrl = '';
    let parent = route.parent;
    while (parent.routeConfig) {
      if (parent.routeConfig.path !== '') {
        if (baseUrl === '') {
          baseUrl = parent.routeConfig.path;
        }
        else {
          baseUrl = parent.routeConfig.path + '/' + baseUrl;
        }
        parent = parent.parent ? parent.parent : null;
      }
    }

    let computeUrl = route.routeConfig.path;
    let child = route.firstChild;
    while (child) {
      if (child.routeConfig.path !== '') {
        computeUrl += '/' + child.routeConfig.path;
      }
      child = child.firstChild ? child.firstChild : null;
    }

    const computedRoute = [baseUrl, computeUrl].filter(Boolean).join('/');
    const routeActions = this.accessControlService.getAccessForRoute(computedRoute);
    return routeActions.includes(AccessInfo.ActionsEnum.Enabled);
  }

  private loadAccessInfo(config: PathAccessConfig): Observable<boolean> {
    const accessObs = [];

    if (config.loadRoutes?.length) {
      accessObs.push(...config.loadRoutes.map(originService => {
        return this.accessControlService.loadAccess({
          originService,
          loadRoutes: true,
          loadWidgets: false
        });
      }));
    }

    if (config.loadWidgets?.length) {
      accessObs.push(...config.loadWidgets.map(originService => {
        return this.accessControlService.loadAccess({
          originService,
          loadRoutes: false,
          loadWidgets: true
        });
      }));
    }

    return forkJoin(accessObs).pipe((map(_ => true)));
  }
}
