import { Injectable } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const EXCLUDED_ROUTE_PREFIXES = [
  '/payments/ddr',
];

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingSubject: Subject<boolean> = new Subject<boolean>();

  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(
    private router: Router
  ) {
    this.loadingSubject.next(false);
    this.router.events.subscribe((event: Event | RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  public setLoading(loading: boolean): void {
    this.loadingSubject.next(loading);
  }

  isExcluded(url: string) {
    return EXCLUDED_ROUTE_PREFIXES.some(prefix => url.startsWith(prefix));
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: Event | RouterEvent): void {
    if ('url' in event && this.isExcluded(event.url)) {
      return;
    }
    if (event instanceof NavigationStart) {
      this.setLoading(true);
    }
    if (event instanceof NavigationEnd) {
      this.setLoading(false);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.setLoading(false);
    }
    if (event instanceof NavigationError) {
      this.setLoading(false);
    }
  }
}
