import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icd10'
})
export class Icd10Pipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    const trimValue = value.replace(/\s|\./g, '');
    return trimValue?.length > 3 ? trimValue.substring(0, 3) + '.' + trimValue.substring(3) : trimValue;
  }

}
