/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChangeItem } from './changeItem';


export interface Change { 
    id: string;
    domainName: string;
    domainKey: number;
    action: string;
    changes?: Array<ChangeItem>;
    changeSeq: number;
    changeTS: string;
    changedBy: string;
    receivedTS: string;
}

