<kendo-grid [data]="gridView"
            [height]="height"
            [groupable]="true"
            [pageSize]="pageSize"
            [pageable]="pageable"
            [sortable]="true"
            [skip]="skip"
            (pageChange)="loadMore($event)"
            (groupChange)="groupChange($event)"
            (dataStateChange)="dataStateChange($event)"
            [group]="state.group"
            [sort]="state.sort">
  <kendo-grid-column
    *ngFor="let column of columns"
    title="{{ column.title }}"
    [field]="column.field"
    [headerStyle]="getHeaderStyle(column.widthStyle)"
    [style]="getStyle(column.widthStyle)"
  >
    <ng-template
      [ngSwitch]="column.type"
      kendoGridCellTemplate
      let-dataItem
      let-rowIndex="rowIndex"
    >
      <span *ngSwitchCase="'date'">{{
        dataItem[column.field]
          | date : "MM/dd/yyyy, h:mm a" : null : dateLocalePipe
      }}</span>
      <span *ngSwitchCase="'text'"
        class="grouped-grid-text-col"
      >{{ dataItem[column.field] }}</span>
      <span *ngSwitchCase="'html'" [innerHtml]="dataItem[column.field] | safeHtml"></span>
      <span *ngSwitchCase="'pill'">
        <prism-pill
          *ngIf="!dataItem[column.field].value"
          [status]="dataItem[column.field]"
          class="d-flex"
        ></prism-pill>
      </span>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate>
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>
