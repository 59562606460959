import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

function hasValidLength(value: any): boolean {
  // non-strict comparison is intentional, to check for both `null` and `undefined` values
  return value != null && typeof value.length === 'number';
}

@Injectable({
  providedIn: 'root'
})
export class PasswordValidatorService {

  public static uppercase(control: FormControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      // don't validate empty values to allow optional controls
      // don't validate values without `length` property
      return null;
    }
    const valid = /[A-Z]/.test(control.value);
    if (!valid) {
        return { uppercase: true };
    }
    return null;
  }

  public static numeral(control: FormControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      // don't validate empty values to allow optional controls
      // don't validate values without `length` property
      return null;
    }
    const valid = /[0-9]/.test(control.value);
    if (!valid) {
        return { numeral: true };
    }
    return null;
  }

  public static specialChar(control: FormControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      // don't validate empty values to allow optional controls
      // don't validate values without `length` property
      return null;
    }
    const valid = /[!@#$%^&*]/.test(control.value);
    if (!valid) {
        return { specialChar: true };
    }
    return null;
  }

  public static fieldsMatch(fieldsToMatch: string[], errorName: string, fieldsError?: string[]): ValidationErrors | null {

    const errorFields = fieldsError ? fieldsError : fieldsToMatch;

    return (control: AbstractControl) => {
      const values = fieldsToMatch.map((fieldName) => control.get(fieldName).value);
      const equals = values.every(val => val === values[0]);
      const error = equals ? null : { [errorName] : true };
      errorFields.forEach((fieldName) => {
        const fieldControl = control.get(fieldName);
        const errors = { ...fieldControl.errors };
        if (!error) {
          delete errors[errorName];
        }
        const mergedErrors = {
          ...error,
          ...errors
        };
        const finalErrors = Object.keys(mergedErrors).length === 0 ? null : mergedErrors;
        fieldControl.setErrors(finalErrors);
      });
    };
  }



}
