import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(timeStr: string): string {
    let hour = (timeStr.split(':'))[0];
    let min = (timeStr.split(':'))[1];
    const part = +hour > 12 ? 'PM' : 'AM';

    min = (min + '').length === 1 ? `0${min}` : min;
    hour = +hour > 12 ? (+hour - 12).toString() : hour;
    hour = (hour + '').length === 1 ? `0${hour}` : hour;

    return `${hour}:${min} ${part}`;
  }

}
