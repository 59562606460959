import { Component, ContentChild, Input } from '@angular/core';
import { DrawerHeaderContentDirective } from '../../shared-directives/drawer-header-content/drawer-header-content.directive';
import { DrawerAsideContentDirective } from '../../shared-directives/drawer-aside-content/drawer-aside-content.directive';
import { DrawerMainContentDirective } from '../../shared-directives/drawer-main-content/drawer-main-content.directive';

@Component({
  selector: 'prism-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent {
  @ContentChild(DrawerHeaderContentDirective) additionalHeaderContent;
  @ContentChild(DrawerAsideContentDirective) drawerContent;
  @ContentChild(DrawerMainContentDirective) mainContent;

  @Input() title: string;
  @Input() orientation = 'horizontal';

  public expanded = true;

  onMenuButtonClick() {
    this.expanded = !this.expanded;
  }
}
