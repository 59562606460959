import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  FileInfo,
  FileRestrictions,
  FileState,
  UploadComponent as KendoUploadComponent,
  NavigationService,
  SelectEvent
} from '@progress/kendo-angular-upload';
import { noop } from 'rxjs';

export const PRISM_UPLOAD_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UploadComponent),
  multi: true
};

enum FileValidations {
  invalidMaxFileSize = 'invalidMaxFileSize',
  invalidFileExtension = 'invalidFileExtension'
}

const fileGroupMap = {
  image: [
    '.ai', '.dds', '.heic', '.jpe', 'jfif', '.jif', '.jp2', '.jps', '.eps', '.bmp', '.gif', '.jpeg', '.jpg', '.png', '.ps', '.psd',
    '.svg', '.svgz', '.tif', '.tiff'
  ],
  txt: [
    '.doc', '.docx', '.log', '.pages', '.tex', '.wpd', '.wps', '.odt', '.rtf', '.text', '.txt', '.wks'
  ],
  data: [
    '.xlr', '.xls', '.xlsx'
  ],
  presentation: [
    '.key', '.odp', '.pps', '.ppt', '.pptx'
  ],
  pdf: [
    '.pdf'
  ]
};
export interface PrismFileInfo extends FileInfo {
  fileRecId?: number;
  fileUploadedBy?: string;
  fileUploadedTs?: string;
}

const ATTACH_UID_OFFSET = 9999;

@Component({
  selector: 'prism-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [PRISM_UPLOAD_CONTROL_VALUE_ACCESSOR]
})
export class UploadComponent implements ControlValueAccessor {

  @Input() uploadDocument: string;
  @Input() deleteDocument = 'temp-url';
  @Input() saveField = 'files';
  @Input() autoUpload = false;
  @Input() disabled = false;
  @Input() allowMultiple = true;
  @Input() deleteEnabled = true;
  @Input() viewEnabled = true;
  @Input() hideUpload = false;
  @Input() hideActions = false;
  @Input() fileRestrictions: FileRestrictions = {
    maxFileSize: 5000000,
    allowedExtensions: ['.jpg', '.png', '.pdf', '.txt', '.doc', '.docx', '.csv', '.ppt', '.pptx']
  };
  @Input() deleteStrategy: 'delete' | 'emit' = 'emit';
  @Output() selectedFile: EventEmitter<Array<PrismFileInfo>> = new EventEmitter<Array<PrismFileInfo>>();
  @Output() deletedFile: EventEmitter<PrismFileInfo> = new EventEmitter<PrismFileInfo>();
  @Output() downloadFile: EventEmitter<PrismFileInfo> = new EventEmitter<PrismFileInfo>();
  @ViewChild('kendoUploadComponent') fileUpload: KendoUploadComponent;
  @ViewChild(NavigationService) fileUploadNavSvc: NavigationService;
  public filesList: Array<PrismFileInfo> = [];
  public prismFileMap: Map<string, PrismFileInfo> = new Map();


  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  

  writeValue(value: any): void {
    this.filesList = [];
    this.prismFileMap = new Map<string, PrismFileInfo>();
    if (value) {
      value.forEach(entry => {
        entry.uid = (- (ATTACH_UID_OFFSET - this.filesList.length)).toString();
        this.filesList.push(entry);
        this.prismFileMap.set(entry.uid, entry);
      });
    }
    this.onChangeCallback(value);

  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  deleteAttachment(fileInfo: FileInfo): void {
    const fileToDelete = this.prismFileMap.get(fileInfo.uid);
    if (this.deleteStrategy === 'delete') {
      this.prismFileMap.delete(fileInfo.uid);
      this.filesList = this.filesList.filter(file => file.uid !== fileInfo.uid);
    }
    this.deletedFile.emit(fileToDelete || fileInfo);
  }

  onSelectEvent(filesList: SelectEvent): void {
    // this prevents component to add file automatically to the bottom of the list
    filesList.preventDefault();
    if (!this.allowMultiple) {
      this.deleteAllDocuments();
    }
    // validation
    filesList.files.forEach(file => {
      if (file.validationErrors) {
        file.state = FileState.Failed;
      }
      return file;
    });

    if (this.filesList && this.allowMultiple) {
      // append files to existing file list
      this.filesList = [
        ...filesList.files,
        ...this.filesList
      ];
    } else {
      // replace file list
      this.filesList = [
        ...filesList.files];
    }

    this.selectedFile.emit(this.filesList);
  }

  public getFileExtensionCssClass(file): string {
    const initial = 'k-i-file';
    const fileExtension = file.extension ? file.extension : '';
    for (const group in fileGroupMap) {
      if (fileGroupMap[group].indexOf(fileExtension) >= 0) {
        return `${initial}-${group}`;
      }
    }
    return initial;
  }

  downloadAttachment(fileInfo: FileInfo): void {
    this.downloadFile.emit(this.prismFileMap.get(fileInfo.uid));
  }

  public isUploadSuccessful = (fileInfo: FileInfo): boolean => {
    return fileInfo.state === FileState.Uploaded;
  }
  public isUploadFailed = (fileInfo: FileInfo): boolean => {
    return fileInfo.state === FileState.Failed;
  }

  public isNotYetUploaded = (fileInfo: FileInfo): boolean => {
    return !this.isUploadFailed(fileInfo) && !this.isUploadSuccessful(fileInfo);
  }

  public getFileStatusText = (fileInfo: PrismFileInfo): string => {
    if (fileInfo.state === FileState.Uploaded) {
      return 'Files uploaded successfully';
    } else if (fileInfo.state === FileState.Failed) {
      console.log('fileInfo.validationErrors', fileInfo.validationErrors);
      switch (fileInfo.validationErrors[0]) {
        case FileValidations.invalidFileExtension:
          return 'Invalid file extension';
        case FileValidations.invalidMaxFileSize:
          return 'File too large';
        default:
          return 'File upload failed';
      }
    } else if (fileInfo.state === FileState.Initial || this.isNotYetUploaded(fileInfo)) {
      let statusText = fileInfo.size > 0 ? (fileInfo.size / 1024).toFixed(2) + 'KB' : '';
      if (fileInfo.fileUploadedTs) {
        statusText += ', Uploaded On - ' + fileInfo.fileUploadedTs;
      }
      return statusText;
    }
  }

  public deleteAllDocuments(): void {
    this.filesList.forEach(file => this.deleteAttachment(file));
  }
}
