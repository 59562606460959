import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { EMPTY, noop, Observable, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';


export const INPUT_CHIP_LIST_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputChipListComponent),
  multi: true
};


@Component({
  selector: 'prism-input-chip-list',
  templateUrl: './input-chip-list.component.html',
  styleUrls: ['./input-chip-list.component.scss'],
  providers: [ INPUT_CHIP_LIST_CONTROL_VALUE_ACCESSOR ]
})
export class InputChipListComponent implements ControlValueAccessor, OnInit {

  @Input() public disabled: boolean;
  @Input() public placeholder: boolean;
  @ViewChild(MultiSelectComponent) kendoMultiSelect: MultiSelectComponent;

  private filterSubject: Subject<string> = new Subject<string>();

  private filterObs: Observable<string> = this.filterSubject.asObservable();

  private innerValue: string[] = [];

  private lastFilter: string;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() { }

  ngOnInit(): void {
    this.filterObs
      .pipe(
        debounce(str => str !== '' ? EMPTY : timer(500))
      )
      .subscribe(str => {
        this.lastFilter = str;
        if (this.lastFilter.indexOf(' ') !== -1) {
          this.writeValue(this.innerValue.concat(this.lastFilter.trim()));
        }
      });
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    this.writeValue(value);
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.onChangeCallback(value);
    this.kendoMultiSelect?.clearFilter();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleComboFilterChange($event: any): void {
    this.filterSubject.next($event);
  }

  public blurHandler($event: any): void {
    if (this.lastFilter) {
      this.writeValue(this.innerValue.concat(this.lastFilter.trim()));
    }
  }

  public enterHandler($event: any): void {
    if (this.lastFilter) {
      this.writeValue(this.innerValue.concat(this.lastFilter.trim()));
    }
  }

  public preventOpen(event: any): void {
    event.preventDefault();
  }

}
