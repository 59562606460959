import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidatorService } from '../../../components/users/services/password-validator.service';
import { MyProfileService, UserProfileRequest } from '../../shared-services/my-profile/my-profile.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { UtilitiesService } from '../../shared-services/utilities/utilities.service';

@Component({
  selector: 'prism-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent {

  public changePasswordForm: FormGroup;
  public isActionEnabled = false;
  private redirectUrl: string;

  constructor(
    protected formBuilder: FormBuilder,
    private myProfileService: MyProfileService,
    private route: ActivatedRoute,
    private utilitiesService: UtilitiesService,
  ) {
    this.initPwdChangeForm();
    this.route.queryParams.pipe(take(1)).subscribe( params => {
      this.myProfileService.getMyProfile().pipe(take(1)).subscribe((userProfile) => {
          this.initPwdChangeView(userProfile.isPwdResetRequired, params.redirect);
      });
    });
  }

  private initPwdChangeView(isPwdResetRequired: boolean, rediredtUrl: string): void {
    if (!isPwdResetRequired) {
      this.redirectUrl = rediredtUrl;
      this.close();
    } else {
      this.isActionEnabled = true;
    }
  }

  private initPwdChangeForm(): void {
    this.changePasswordForm = this.formBuilder.group({
          newPassword: ['', [
            Validators.required,
            Validators.minLength(8),
            PasswordValidatorService.numeral,
            PasswordValidatorService.specialChar,
          ]
          ],
          confirmPassword: ['', Validators.required]
        },
        {
          validators: PasswordValidatorService.fieldsMatch(['newPassword', 'confirmPassword'], 'passwordMatch', ['newPassword'])
        });
  }

  submit(): void {
    if (this.changePasswordForm.valid) {
      const { newPassword, confirmPassword } = this.changePasswordForm.value;
      const myProfile: UserProfileRequest = {
        oldPwd: '',
        newPwd: newPassword,
        confirmNewPwd: confirmPassword
      };
      this.myProfileService
        .patchMyProfile(myProfile)
        .subscribe(() => {
          this.redirectUrl = '/logout';
          this.close();
        });
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  close(): void {
    this.utilitiesService.navigateToRoute(this.redirectUrl);
  }

  cancel(): void {
    this.redirectUrl = '/logout';
    this.close();
  }
}
