import { Injectable } from '@angular/core';
import { NotificationService as Notification } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private notifService: Notification) { }

  public showErrorNotification(errorMessage: string): void {
    this.notifService.show({
      content: errorMessage,
      animation: { type: 'fade', duration: 800 },
      type: { style: 'error', icon: true },
      position: { horizontal: 'center', vertical: 'bottom' },
      cssClass: 'error-notification'
    });
  }

  public showSuccessNotification(successMessage: string): void {
    this.notifService.show({
      content: successMessage,
      animation: { type: 'fade', duration: 800 },
      type: { style: 'success', icon: true },
      position: { horizontal: 'center', vertical: 'bottom' },
      cssClass: 'success-notification'
    });
  }
}
