import { Component, Inject, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, SESSION_INSTANCE } from '../../msal/constants';
import { MsalGuardConfiguration } from '../../msal/msal.guard.config';
import { SessionService } from '../../shared/shared-services/session/session.service';
import { MsalService } from '../../msal/msal.service';

@Component({
  selector: 'prism-logout',
  templateUrl: 'logout.component.html',
})
export class LogoutComponent implements OnInit {

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(SESSION_INSTANCE) private sessionService: SessionService,
    private authService: MsalService) { }

  ngOnInit(): void {
    this.logout();
  }

  logout(): void {
    if (this.authService.getAllAccounts().length > 0) {
      this.authService.logout({
        ...this.msalGuardConfig.authRequest,
        postLogoutRedirectUri: `${window.integra.auth.redirectUri}/home`,
      });
    } else {
      this.authService.loginRedirect({
        scopes: this.msalGuardConfig.scopes,
        ...this.msalGuardConfig.authRequest,
        redirectStartPage: `${window.integra.auth.redirectUri}/home`,
      });
    }
  }
}
