<div class="card-container">
  <div class="existent-container" *ngIf="type === 'existent'">
    <div kendoContextMenuTargetContainer #addresscardActionsTarget="kendoContextMenuTargetContainer"
      class="address-header d-flex justify-content-start" [ngStyle]="{
        'padding-top':
          (addressData.source === dataSources.MEMBER && selectable) ||
          addressData.source === dataSources.PAYER ||
          addressData.source === dataSources.LOB ||
          !isReadOnly
            ? '0px'
            : '0px'
      }">
      <div *ngIf="isMain" class="mt-3">
        <span class="header-type secondary"> Main </span>
      </div>
      <div *ngIf="addressData.source === dataSources.MEMBER && selectable">
        <input *ngIf="addressData.selected !== undefined" [checked]="addressData.selected"
          (click)="setSelectedAddress.emit(addressData)" class="mr-2" type="radio" kendoRadioButton />
        <span class="header-type primary" *ngIf="addressData.useAsShipping; else showRel">
          Default Delivery Address
        </span>
      </div>
      <div *ngIf="
          addressData.source === dataSources.PAYER ||
          addressData.source === dataSources.LOB
        ">
        <div class="header-type" [ngClass]="{
            primary: addressData.type === 'claims',
            secondary: addressData.type === 'office'
          }">
          <span class="capitalize">{{ addressData.type | mdmCodeToDesc }}</span>
        </div>
      </div>
      <div class="ml-auto" [kendoContextMenuTarget]="addressData" *ngIf="!isReadOnly">
        <span data-testid="address-card-elipsis" class="k-icon k-font-icon k-i-more-vertical"></span>
      </div>
    </div>
    <div class="address-header d-flex justify-content-start" *ngIf="
        addressData.useAsShipping && addressData.source === dataSources.MEMBER
      ">
      <div class="header-type">
        <span class="capitalize">{{
          addressData.relToIndv.charAt(0).toUpperCase() +
          addressData.relToIndv.slice(1).toLowerCase()
          }}</span>
      </div>
    </div>
    <div class="address-content mt-2">
      <div class="name" *ngIf="addressData.name !== null">
        {{ addressData.name }}
      </div>
      <div class="address-line">
        {{ addressData.addressLine1 }}
      </div>
      <div class="address-line">
        {{ addressData.addressLine2 }}
      </div>
      <div class="address-line">
        {{ addressData.addressLine3 }}
      </div>
      <div class="row based-on mt-1" *ngIf="addressData.source === dataSources.LOB" style="padding-left: 16px">
        Active Based On: {{ addressData.addrBasedOnTypeCode | mdmCodeToDesc }}
      </div>
      <div class="row effective-range mt-1" *ngIf="addressData.source === dataSources.LOB">
        <div class="col-6">
          <div>Effective From</div>
          <div>{{ addressData.effectiveFromDt }}</div>
        </div>
        <div class="col-6">
          <div>Effective To</div>
          <div>
            <span *ngIf="addressData.effectiveToDt">{{
              addressData.effectiveToDt
              }}</span>
            <span *ngIf="!addressData.effectiveToDt" class="none-content">No Date Selected</span>
          </div>
        </div>
      </div>
    </div>
    <kendo-contextmenu [target]="addresscardActionsTarget" (select)="onContextSelect($event)" [items]="contextItems"
      showOn="click"></kendo-contextmenu>
    <div class="set-default" *ngIf="
        !isReadOnly &&
        !addressData.useAsShipping &&
        addressData.source === dataSources.MEMBER
      ">
      <a class="action" (click)="setAsDefaultAddress()">Set as Default Delivery Address</a>
    </div>
  </div>
  <div class="add-container" *ngIf="type === 'add'">
    <div class="d-flex flex-column align-items-center">
      <span *ngIf="addressData && addressData['showAddressType'] && addressData.type"
        class="label">{{addressData.type}}</span>
      <div class="image-container">
        <img src="../../../../assets/images/map-image.png" alt="" />
      </div>
      <div class="btn-container">
        <prism-button class="mt-3" [icon]="'plus'" [type]="'primary'" (clicked)="addAction.emit()">
          Add Address
        </prism-button>
      </div>
    </div>
  </div>
</div>
<prism-modal [title]="'Delete Address'" [opened]="openDeleteModal" [actions]="deleteModalActions"
  (cancel)="closeDeleteModal()">
  <prism-delete-address *ngIf="addressData" [data]="addressData"></prism-delete-address>
</prism-modal>

<ng-template #showRel>
  <span class="header-type" *ngIf="addressData.relToIndv !== null">
    <span class="capitalize">{{
      addressData.relToIndv.charAt(0).toUpperCase() +
      addressData.relToIndv.slice(1).toLowerCase()
      }}</span>
  </span>
</ng-template>
