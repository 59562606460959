import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { SearchDataList } from '../../shared-interfaces';

@Injectable()
export class UtilitiesService {

  constructor(
    private router: Router
  ) { }

  getProp(object: unknown, keys, defaultVal?: any): any {
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
      return this.getProp(object, keys.slice(1), defaultVal);
    }
    return object === undefined ? defaultVal : object;
  }

  public navigateToRoute(url: string): void {
    window.location.href = url;
  }

  /**
   * This method takes in an array of strings
   * and compares it to the last segment of the url,
   * If the last segment is found in the array it returns the index of the element.
   * Otherwise, it returns -1
   * @param items an Array of strings to compare with
   * @returns returns the index of element that matches the last segment, otherwise return -1
   */
  public hasUrlSegment(items: string[]): number {
    const segments = this.router.url.split('/');
    const currentRoute = segments[segments.length - 1];

    if (!currentRoute) { return -1; }

    return items.findIndex((i) => i === currentRoute);
  }

  public getDateStringFromISOString(dateString: string): string {
    const dateFormat = new Date(dateString.split('T')[0]);
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset());
    return dateFormat.toString();
  }

  public fetchSingleSource(list: SearchDataList[], id: string, isNumeric?: boolean): SearchDataList {
    const result = list.find(
      (sourceEntry) => {
        if (isNumeric) {
          return sourceEntry.id === +id;
        } else {
          return sourceEntry.id === id;
        }
      }
    );
    return result;
  }
  
  public getTimeAMPMFromDateString(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);
    // Adjust to local time zone
    const localTime = new Date(dateTime.getTime() + (dateTime.getTimezoneOffset() * 60000));
    // Extract the hour and minute components
    const hours = localTime.getHours();
    const minutes = localTime.getMinutes();
    // Convert hours to 12-hour format and determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hour = hours % 12 === 0 ? 12 : hours % 12;
    // Construct the time string in the desired format
    return this.padZero(hour) + ':' + this.padZero(minutes) + ' ' + ampm;
  }
  private padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
