import { GridColumn, GridSortDescriptor, IntegraSearchConfigRow } from '../../../../../shared/shared-interfaces';
export interface FeeScheduleGridConfiguration {
  title?: string;
  configIdProperty: string;
  defaultSort: GridSortDescriptor[];
  sortableColumns: string[];
  gridColumns: GridColumn[];
  advSearchConfig: IntegraSearchConfigRow[];
  newUrl?: string;
  profileUrl?: string;
  enableClick?: boolean;
}

export interface FeeScheduleAdvancedSearchConfiguration {
  searchConfig: IntegraSearchConfigRow[];
  minChars: number;
  placeholder: string;
  defaultPropertySearch?: { key: string, property: string };
}

export interface FeeSchedHcpcModel {
  feeSchedHcpcId?: number;
  feeSchedSetId?: number;
  hcpcCode: string;
  chargeRate?: number;
  billRate?: number;
  comment?: string;
  modifier1?: string;
  modifier2?: string;
  appliesToCode?: string;
  primaryPercent?: number;
  secondaryPercent?: number;
}


export const commonMiscListColumns = [
  {
    field: 'chargeRate',
    title: 'Flat Rate',
    type: 'number',
  },
  {
    field: 'primaryPercent',
    title: 'Primary (%)',
    type: 'percentage',
  },
  {
    field: 'secondaryPercent',
    title: 'Secondary (%)',
    type: 'percentage'
  },
  {
    field: 'appliesToCode',
    title: 'Applies To',
    type: 'text'
  },
  {
    field: 'comment',
    title: 'Comment',
    type: 'text'
  }
] as GridColumn[];

export interface VerUpdateFormData {
  feeSchedVerId: number;
  feeSchedVerName: string;
  verStartDate: Date;
  verEndDate: Date;
}

export const FeeSchedVerContext = {
  HCPC: 'hcpcCode,modifiers',
  CATEGORY: 'hcpcCatgCode',
  EQUIPMENTTYPE: 'equipType',
  ORGANIZATION: 'providerOrgName'
};
