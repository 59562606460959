<kendo-combobox #combo
                [ngStyle]="{width: width + (width.indexOf('%') ?  '' : 'px')}"
                [data]="data"
                [filterable]="filterable"
                (valueChange)="handleSelection($event)"
                (filterChange)="handleFilter($event)"
                (click)="handleFocus()"
                (blur)="handleBlur()"
                [valueField]="valueField"
                [loading]="isLoading"
                [textField]="textField"
                [valuePrimitive]="valuePrimitive"
                [placeholder]="placeholder"
                [allowCustom]="allowCustom"
                [disabled]="disabled"
                [(ngModel)]="value"
                (opened)="expandDropdown()"
>
    <ng-template kendoComboBoxNoDataTemplate>
        <p *ngIf="!isLoading">NO DATA FOUND</p>
        <p *ngIf="isLoading">Loading...</p>
    </ng-template>
</kendo-combobox>
