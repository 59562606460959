import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FeatureFlagService } from '../../shared-services/feature-flag/feature-flag.service';
import { map } from 'rxjs/operators';

/**
 * It is very important to use with canActivate only! on NESTED routes. If used on top route,
 * most likely will prevent MSAL to finish initialization and will cause DEAD lock on feature flag resolver.
 */
export function featureFlagGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const flagName = route.data?.featureFlag ?? '';
  const router = inject(Router);
  if (!flagName) {
    router.navigate(['not-found']);
    return of(false);
  }

  return inject(FeatureFlagService).getFlag(flagName).pipe(map((value) => {
    if (!value) {
      router.navigate(['not-found']);
      return false;
    }
    else {
      return true;
    }
  }));
}