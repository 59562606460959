import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

export const PRISM_EDITOR_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditorComponent),
  multi: true,
};

@Component({
  selector: 'prism-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [PRISM_EDITOR_CONTROL_VALUE_ACCESSOR],
})
export class EditorComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

  private content: string;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() {}

  get value(): any {
    return this.content;
  }

  set value(value: any) {
    if (value !== this.content) {
      this.content = value;
      this.onChangeCallback(value);
    }
  }

  writeValue(value: any): void {
    if (typeof value === 'string' && value !== this.content) {
      this.content = value;
      this.onChangeCallback(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
