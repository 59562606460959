/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ListItemAttr { 
    listAttrId?: number;
    listAttrName: string;
    listAttrLabel: string;
    /**
     * I-integer, D-decimal, S-string, DT-date, TS-timestamp
     */
    listAttrDataType: ListItemAttr.ListAttrDataTypeEnum;
    isRequired?: boolean;
    /**
     * create-update-delete
     */
    intaction?: ListItemAttr.IntactionEnum;
}
export namespace ListItemAttr {
    export type ListAttrDataTypeEnum = 'I' | 'D' | 'S' | 'DT' | 'TS' | 'B';
    export const ListAttrDataTypeEnum = {
        I: 'I' as ListAttrDataTypeEnum,
        D: 'D' as ListAttrDataTypeEnum,
        S: 'S' as ListAttrDataTypeEnum,
        Dt: 'DT' as ListAttrDataTypeEnum,
        Ts: 'TS' as ListAttrDataTypeEnum,
        B: 'B' as ListAttrDataTypeEnum
    };
    export type IntactionEnum = 'create' | 'update' | 'delete';
    export const IntactionEnum = {
        Create: 'create' as IntactionEnum,
        Update: 'update' as IntactionEnum,
        Delete: 'delete' as IntactionEnum
    };
}


