/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FutureDOSRangeType } from './futureDOSRangeType';


/**
 * Represents an inclusive range during which the server will allow a future date of service to be selected.
 */
export interface FutureDOSRange { 
    readonly fromTS: string;
    fromType: FutureDOSRangeType;
    readonly toTS: string;
    toType: FutureDOSRangeType;
}
export namespace FutureDOSRange {
}


