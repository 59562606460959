import { Inject, Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { MSAL_INSTANCE } from './constants';
import {EventMessage, EventMessageUtils, InteractionStatus, IPublicClientApplication} from '@azure/msal-browser';
import {MsalService} from './msal.service';

@Injectable()
export class MsalBroadcastService {

  private msalSubject: Subject<EventMessage>;
  public msalSubject$: Observable<EventMessage>;
  private inProgress: BehaviorSubject<InteractionStatus>;
  public inProgress$: Observable<InteractionStatus>;

  constructor(
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    private authService: MsalService,
  ) {
    this.msalSubject = new Subject<EventMessage>();
    this.msalSubject$ = this.msalSubject.asObservable();

    this.inProgress = new BehaviorSubject<InteractionStatus>(InteractionStatus.Startup);
    this.inProgress$ = this.inProgress.asObservable();

    this.msalInstance.addEventCallback((message: EventMessage) => {
      this.msalSubject.next(message);
      const status = EventMessageUtils.getInteractionStatusFromEvent(message);
      if (status !== null) {
        this.authService.getLogger().verbose(`BroadcastService - ${message.eventType} results in setting inProgress to ${status}`);
        this.inProgress.next(status);
      }
    });
  }

}
