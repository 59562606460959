import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadCrumbItemI } from '../../shared-interfaces';
import { BreadcrumbsService } from '../../shared-services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'prism-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  public items: BreadCrumbItemI[] = [];

  private subscription: Subscription;

  constructor(
    private breadcrumbsService: BreadcrumbsService
  ) { }

  ngOnInit(): void {
    this.subscription = this.breadcrumbsService.items$.subscribe(items => {
      this.items = items;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.breadcrumbsService.reset();
  }

  public itemClickHandler(item): void {
    this.breadcrumbsService.selectItem(item);
  }
}
