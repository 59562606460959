import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthError, EventMessage, EventType } from '@azure/msal-browser';
import { interval, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, SESSION_INSTANCE } from './msal/constants';
import { MsalBroadcastService } from './msal/msal.broadcast.service';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalService } from './msal/msal.service';
import { SvgLoaderService } from './shared/shared-icons/svg/svg-loader.service';
import { PrintEventService } from './shared/shared-services/print-event/print-event.service';
import { SessionService } from './shared/shared-services/session/session.service';
import { AppComponentService } from './app.component.service';
import { SettingsService } from './shared/shared-services/settings/settings.service';
import { ModalAction } from './shared/shared-interfaces';
import { Router } from '@angular/router';
import { UtilitiesService } from './shared/shared-services/utilities/utilities.service';

@Component({
    /* eslint "@angular-eslint/component-selector" : "off" */
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public  loggedIn = false;
  private isIframe = false;
  private appVersion: string;
  public showPageRefreshWarning = false;
  public pageRefreshAction: ModalAction[] = [
    { text: 'Refresh', type: 'primary', callback: () => this.refreshPage() }
  ];
  private readonly destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(SESSION_INSTANCE) private sessionService: SessionService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private printEventService: PrintEventService,
    private svgLoaderService: SvgLoaderService,
    private appComponentService: AppComponentService,
    private settingsService: SettingsService,
    private route: Router,
    private utilitiesService: UtilitiesService,
  ) {
    this.svgLoaderService.init();
    this.appComponentService.getAppVersion().subscribe((version) => {
      this.appVersion = version;
      this.showPageRefreshWarning = false;
    });
    this.checkAndValidateAppVersion();
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          this.authService.getLogger().error(`failed to login: ${result.error}`);
        }
      });
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.destroying$.next(null);
    this.destroying$.complete();
  }

  checkAccount(): void {
    this.loggedIn = this.authService.getAllAccounts().length > 0; // this.authService.getActiveAccount() == nil at this point
  }

  @HostListener('window:afterprint', [])
  onWindowAfterPrint(): void {
    this.printEventService.printView.next(false);
  }

  @HostListener('window:beforeprint', [])
  onWindowBeforePrint(): void {
    this.printEventService.printView.next(true);
  }

  public checkAndValidateAppVersion(): void {
    interval(this.settingsService.getVersionRefreshInterval() * 1000).pipe(
        mergeMap(() => this.appComponentService.getAppVersion())
    ).subscribe((version) => {
      if (this.appVersion !== version) {
        this.showPageRefreshWarning = true;
        setTimeout(() => {
          this.refreshPage();
        }, 5000);
      }
    });
  }

  public refreshPage(): void {
    this.utilitiesService.navigateToRoute(this.route.url);
  }
}
