<kendo-dialog
  class="dialog-component"
  [ngClass]="blurBackground ? 'blur-background' : ''"
  *ngIf="opened"
  (close)="cancel.emit()"
  [minWidth]="250"
  [width]="width"
>
  <kendo-dialog-titlebar class="dialog-title">
    <kendo-icon 
      [class]="titleIconType ? titleIconType : ''" *ngIf="titleIcon"
      [name]="titleIcon"
    ></kendo-icon>
    {{title}}
  </kendo-dialog-titlebar>
  <ng-content></ng-content>
  <kendo-dialog-actions
    layout="normal"
    class="d-flex"
    [ngClass]="'justify-content-' + actionsAlign"
  >
    <prism-button
      *ngFor="let action of actions"
      [type]="!!action.type ? action.type : 'secondary'"
      [disabled]="!!action.disabled"
      [icon]="action.icon" (clicked)="action.callback()"
      class="prism-button"
      [nativeType]="action.nativeType"
    >
      {{action.text}}
    </prism-button>
  </kendo-dialog-actions>
</kendo-dialog>
