import { Inject, Injectable, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { WINDOW_OBJ } from 'src/app/shared/shared-injection-tokens/shared-injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class PrintEventService {

  public printView: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Optional() @Inject(WINDOW_OBJ) private windowObj: Window
  ) { }

  print(): void {
    // As @HostListener('window:afterprint') is called late
    // change printView manually is needed.
    this.printView.next(true);

    setTimeout(() => {
      this.windowObj?.print();
    }, 100);
  }

}
