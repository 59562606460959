/**
 * Access Control Management
 * Access Control Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRoles } from './userRoles';


export interface User { 
    userId?: number;
    firstName: string;
    lastName: string;
    userName: string;
    emailAddress: string;
    phoneNumber?: number | null;
    jobTitle: string;
    userStatusCode: string;
    readonly userTypeCode?: string;
    readonly roleDesc?: string;
    readonly modifiedTs?: string;
    readonly modifiedBy?: string;
    readonly lastLoginTs?: string | null;
    readonly createdTs?: string;
    readonly createdBy?: string;
    roles?: Array<UserRoles>;
}

