/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PayerPlanInfo } from './payerPlanInfo';


export interface PayerInfo { 
    payerFamId?: number;
    payerFamName?: string;
    status?: string;
    activeFromDt?: string;
    activeToDt?: string;
    payerPlans?: Array<PayerPlanInfo>;
}

