<kendo-multiselecttree
  [kendoMultiSelectTreeHierarchyBinding]="data"
  childrenField="items"
  textField="displayName"
  [placeholder]="placeholder"
  textField="displayName"
  valueField="id"
  [(ngModel)]="model"
>
  <ng-template kendoMultiSelectTreeNodeTemplate let-dataItem>
    <span [class.selected]="isItemSelected(dataItem)">{{dataItem.displayName}}</span>
  </ng-template>
</kendo-multiselecttree>