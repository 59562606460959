/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LookupItem } from './lookupItem';


export interface OrderLookupDetails { 
    purpose: OrderLookupDetails.PurposeEnum;
    altSysId?: string | null;
    mbrId?: number;
    payerFamId?: number;
    payerAcctId?: number | null;
    payerLobId?: number | null;
    authWinFromDt?: string | null;
    authWinToDt?: string | null;
    authCode?: string | null;
    items?: Array<LookupItem>;
    orderNum?: string | null;
}
export namespace OrderLookupDetails {
    export type PurposeEnum = 'order_load' | 'order_status_change';
    export const PurposeEnum = {
        Load: 'order_load' as PurposeEnum,
        StatusChange: 'order_status_change' as PurposeEnum
    };
}


