import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, FormControl, AbstractControl } from '@angular/forms';

export const PRISM_URL_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PrefixedTextboxComponent),
  multi: true
};

export const PRISM_URL_REQUIRED_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PrefixedTextboxComponent),
  multi: true
};

@Component({
  selector: 'prism-prefixed-textbox',
  templateUrl: './prefixed-textbox.component.html',
  styleUrls: ['./prefixed-textbox.component.scss'],
  providers: [PRISM_URL_CONTROL_VALUE_ACCESSOR, PRISM_URL_REQUIRED_VALIDATOR]
})
export class PrefixedTextboxComponent implements ControlValueAccessor, Validator {
  public invalid: boolean;
  @Input() required: boolean;
  @Input() prefixList: string[];
  @Input() prefixWidthStyle: string;
  @Input() selectedPrefix: string;

  private _truncatedValue: string;
  private innerValue: string;

  get truncatedValue(): string {
    return this._truncatedValue;
  }

  set truncatedValue(url: string) {
    this.writeValue(url);
  }

  onChangeCallback: (_: any) => void = () => { };
  onTouchedCallback: () => void = () => { };

  onSetPrefix(value: string) {
    this.innerValue = `${value}${this.truncatedValue}`;
    this.onChangeCallback(this.innerValue);
  }

  writeValue(value: string): void {
    const matchedPrefix = this.prefixList.find(protocol => value?.startsWith(protocol));
    if (matchedPrefix) {
      this.selectedPrefix = matchedPrefix;
      this._truncatedValue = value.substring(matchedPrefix.length);
      this.innerValue = value;
    } else {
      this._truncatedValue = value;
      this.innerValue = `${this.selectedPrefix}${value}`;
    }
    this.onChangeCallback(this.innerValue);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  validate(): ValidationErrors | null {
    if (this.required && (!this.truncatedValue || this.truncatedValue.trim() === '')) {
      this.invalid = true;
      return { required: true };
    }
    return null;
  }
}
