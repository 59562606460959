import { HttpContextToken } from '@angular/common/http';

export const DEFAULT_MSG_KEY = new HttpContextToken<string>(() => null);

export const NPI_LOOKUP = 'NPI_LOOKUP';
export const ORDER_UPDATE = 'ORDER_UPDATE';

export const defaultErrorMessages: { [key: string]: string } = {
  NPI_LOOKUP: 'NPI Registry not available, please try again.',
  ORDER_UPDATE: 'Order could not be saved. Please try again. If the issue persists, call Integra Partners.',
};
