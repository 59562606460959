<div class="search-container"
      [ngClass]="{
        'small': filterComboWidth === FilterWidthResult.Small,
        'medium': filterComboWidth === FilterWidthResult.Medium,
        'large': filterComboWidth === FilterWidthResult.Large
        }"
>
  <div class="input-container" #anchor>
    <kendo-icon class="left-icon" name="search"></kendo-icon>
    <input
      class="roboto-reg-gray integra-search w-100"
      kendoTextBox
      type="text"
      [disabled]="disabled"
      [readOnly]="readOnlyAnchor"
      [(ngModel)]="advancedSearchInput"
      (ngModelChange)="onAdvancedSearchChanged()"
      (click)="readOnlyAnchor ? togglePopup() : null"
      [placeholder]="placeHolder"
    />
    <button
      *ngIf="advancedEnabled"
      class="integra-search-dropdown"
      [disabled]="disabled"
      (click)="togglePopup()"
    >
      <span class="k-icon k-font-icon k-i-arrow-60-down"></span>
    </button>
  </div>
  <kendo-popup #popup *ngIf="showAdvanced" [anchor]="anchor" (anchorViewportLeave)="showAdvanced = false">
    <div class="content extra-wide" [ngClass]="{ 'wide': searchViewMode === 'adv-search-wide',
      'extra-wide': searchViewMode === 'adv-search-extra-wide'}">
      <span (click)="togglePopup(false)" *ngIf="closeButton" class="close k-icon k-font-icon k-icon-xs k-i-close"></span>
      <ng-template content ngFor let-row [ngForOf]="config" let-i="index">
        <div class="row mt-2">
          <ng-container *ngFor="let column of row.columns" [ngSwitch]="column.type">
            <div *ngIf="!column.hide" class="{{getColClass(row)}}" >
              <p *ngIf="column.label">
                <kendo-label class="label" text="{{ column.label }}"></kendo-label>
                <prism-tooltip *ngIf="column.info" [title]="column.info"></prism-tooltip>
              </p>
              <span *ngSwitchDefault>
                <kendo-textbox class="roboto-reg-gray fullWidth" *ngIf="!column.mask" [(ngModel)]="column.value"
                  name="{{ column.label }}" placeholder="{{ column.placeholder }}" [disabled]="column.disabled"
                  [minlength]="column.minLength"></kendo-textbox>
                <kendo-maskedtextbox class="roboto-reg-gray" *ngIf="column.mask" [(ngModel)]="column.value"
                  name="{{ column.label }}" [mask]="column.mask" [disabled]="column.disabled"></kendo-maskedtextbox>
              </span>
              <span *ngSwitchCase="'email'">
                <kendo-textbox class="roboto-reg-gray fullWidth" [(ngModel)]="column.value" name="{{ column.label }}"
                  paceholder="{{ column.placeholder }}" type="email" pattern="{{ patternForEmail }}"
                  [disabled]="column.disabled"></kendo-textbox>
              </span>
              <kendo-datepicker class="fullWidth" [navigation]="false" [(ngModel)]="column.value"
                [disabled]="column.disabled" *ngSwitchCase="'date'"></kendo-datepicker>
              <prism-combo-box *ngSwitchCase="'combobox'" [width]="'420'" [data]="
                  !column.pagingConfig
                    ? dataLoader.get(column.key).data
                    : dataLoader.get(column.key).pagingData.data
                " [placeholder]="column.placeholder" [isLoading]="
                  column.pagingConfig
                    ? dataLoader.get(column.key).isLoading
                    : false
                " [selectedValue]="dataLoader.get(column.key).selectedValue"
                [disabled]="dataLoader.get(column.key).disabled" [pageSize]="column.pagingConfig?.pageSize ?? 0"
                [paging]="!!column.pagingConfig" [filterable]="column.filterable" (searchSelectionChange)="
                  handleComboSelection($event, column.key)
                " (searchFilterChange)="
                  handleComboFilterChange($event, column.key)
                " (loadNextPage)="handleComboPageChange($event, column.key)"></prism-combo-box>
              <prism-lookup *ngSwitchCase="'lookup'" [viewMode]="'dropdown'" [listTypeName]="column.listTypeName"
                [listTypeCtx]="column.listTypeCtx" (valueChange)="handleLookupSelection($event, column.key)"
                [disabled]="column.disabled" class="fullWidth" [(ngModel)]="column.value"></prism-lookup>
              <prism-multiselect *ngSwitchCase="'multiselect'" [placeholder]="column.placeholder"
                [config]="column.config" [data]="column.data" [(ngModel)]="column.value"
                [filterable]="column.filterable">
              </prism-multiselect>

              <span *ngSwitchCase="'number'">
                <kendo-numerictextbox class="roboto-reg-gray fullWidth" min="0" *ngIf="!column.mask"
                  [(ngModel)]="column.value" name="{{ column.label }}" [disabled]="column.disabled"
                  placeholder="{{ column.placeholder }}"></kendo-numerictextbox>
              </span>
              <div *ngSwitchCase="'checkbox'" class="d-flex" [ngClass]="
                  column.direction === 'column' ? 'flex-column' : 'flex-row'
                ">
                <div *ngFor="let option of column.options; let optionIndex = index">
                  <input #checkbox type="checkbox" kendoCheckBox (change)="checkOption(option, column, $event)"
                    [disabled]="column.disabled" [attr.checked]="isChecked(option, column)" />
                  <kendo-label [for]="checkbox" class="k-checkbox-label"
                    text="{{ option.label }}"></kendo-label>&nbsp;&nbsp;
                </div>
              </div>
              <div [ngClass]="{ 'd-block': !!column.error }" class="invalid-feedback">
                {{ column.error }}
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <div class="content-actions d-flex justify-content-end mt-3">
        <prism-button class="mr-2" [icon]="'filter-clear'" [type]="'fifth'" (clicked)="clearFilters()">Clear Filters
        </prism-button>
        <prism-button [type]="'primary'" (clicked)="applyFilters()">Apply Filters
        </prism-button>
      </div>
    </div>
  </kendo-popup>
</div>
