<ng-container *ngIf="config">
  <ng-container [ngSwitch]="config?.type">

    <ng-container *ngSwitchCase="types.DATE">
      <kendo-datepicker
        [ngStyle]="{width: '100%'}"
        [disabled]="disabled"
        [(ngModel)]="value"></kendo-datepicker>
    </ng-container>

    <ng-container *ngSwitchCase="types.DATERANGE">
      <kendo-daterange>
        <div class="flex-fill">
          <label class="form-label">{{config.label}}</label>
          <kendo-dateinput class="w-100"
            kendoDateRangeStartInput
            [(ngModel)]="dateRangeStart"
            [disabled]="disabled"
            (valueChange)="handleDateRangeChange('start', $event)"
          ></kendo-dateinput>
        </div>
        <div class="flex-fill">
          <label class="form-label">{{config.endLabel}}</label>
          <kendo-dateinput class="w-100"
            kendoDateRangeEndInput
            [(ngModel)]="dateRangeEnd"
            [disabled]="disabled"
            (valueChange)="handleDateRangeChange('end', $event)"
          ></kendo-dateinput>
        </div>
      </kendo-daterange>
    </ng-container>

    <ng-container *ngSwitchCase="types.DATETIME">
      <kendo-datetimepicker
        [ngStyle]="{width: '100%'}"
        [disabled]="disabled"
        [(ngModel)]="value"></kendo-datetimepicker>
    </ng-container>

    <ng-container *ngSwitchCase="types.COMBOBOX_ID">
      <prism-combo-box #combo
        width="100%"
        [data]="data"
        [placeholder]="config.placeholder"
        [allowLoadMore]="allowLoadMore"
        valuePrimitive="true"
        [isLoading]="isLoading"
        [selectedValue]="value"
        [minRequiredChars]="minRequiredChars"
        [paging]="!!config.pagingConfig"
        [pageSize]="config.pagingConfig?.pageSize ?? 0"
        (searchSelectionChange)="handleComboSelection($event)"
        (searchFilterChange)="handleComboFilterChange($event)"
        (loadNextPage)="handleComboPageChange($event)"
        [disabled]="disabled"></prism-combo-box>
    </ng-container>

    <ng-container *ngSwitchCase="types.MULTISELECT_TYPEAHEAD">
      <prism-multiselect
        [config]="config"
        [data]="data"
        [(ngModel)]="multiselectValue"
        [filterable]="true"
        [placeholder]="config.placeholder"
        (filterChange)="handleComboFilterChange({filter: $event, isSelectFilter: false})"
        (ngModelChange)="handleMultiselectSelection($event)"
        [disabled]="disabled"
        ngDefaultControl
        [checkboxes]="true"
        >
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
          <span [ngClass]="{'selected': isItemSelected(dataItem)}">{{dataItem.displayName}}</span>
        </ng-template>
      </prism-multiselect>
    </ng-container>

    <ng-container *ngSwitchCase="types.MULTISELECT">
      <prism-lookup
        viewMode="multiselect"
        [listTypeName]="config.listTypeName"
        [(ngModel)]="multiselectValue"
        (ngModelChange)="handleMultiselectSelection($event)"
        [disabled]="disabled"
        [placeholder]="config.placeholder"
        [paging]="!!config.pagingConfig"
        [pageSize]="config.pagingConfig?.pageSize ?? 0"
        ngDefaultControl ></prism-lookup>
    </ng-container>

    <ng-container *ngSwitchCase="types.LOOKUP_DROPDOWN">
      <prism-lookup
        viewMode="dropdown"
        [listTypeName]="config.listTypeName"
        [(ngModel)]="value"
        [placeholder]="config.placeholder"
        [disabled]="disabled" ></prism-lookup>
    </ng-container>

    <ng-container *ngSwitchCase="types.ARRAY_STRING">
      <prism-form-textbox-array
        [(ngModel)]="value"
        ngDefaultControl
        [disabled]="disabled"></prism-form-textbox-array>
    </ng-container>

    <ng-container *ngSwitchCase="types.DROPDOWN">
      <prism-dropdown-list
        [data]="data"
        [pageSize]="25"
        (loadNextPage)="loadNextPage($event)"
        [(ngModel)]="value"
        [disabled]="disabled"
        ngDefaultControl
        ></prism-dropdown-list>
    </ng-container>

    <ng-container *ngSwitchCase="types.BOOLEAN">
      <ng-container *ngFor="let item of [true, false]">
        <input
          kendoRadioButton
          type="radio"
          [name]="config.name"
          [id]="config.name + (item ? 'true' : 'false')"
          [(ngModel)]="value"
          [value]="item"
          [disabled]="disabled"
        />
        <label
          class="k-radio-label mr-5"
          [for]="config.name + (item ? 'true' : 'false')"
        >{{ item ? 'true' : 'false' }}</label>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <kendo-textbox
        class="roboto-reg-gray"
        [ngStyle]="{width: '100%'}"
        [(ngModel)]="value"
        [name]="config.name"
        [placeholder]="config.placeholder"
        [disabled]="disabled"></kendo-textbox>
    </ng-container>


  </ng-container>
</ng-container>
