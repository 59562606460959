/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enum describing the date used to create the start or end of the future DOS range.
 */
export type FutureDOSRangeType = 'unknown' | 'exp_delivery' | 'today' | 'default' | 'auth_end' | 'next_stg';

export const FutureDOSRangeType = {
    Unknown: 'unknown' as FutureDOSRangeType,
    ExpDelivery: 'exp_delivery' as FutureDOSRangeType,
    Today: 'today' as FutureDOSRangeType,
    Default: 'default' as FutureDOSRangeType,
    AuthEnd: 'auth_end' as FutureDOSRangeType,
    NextStg: 'next_stg' as FutureDOSRangeType
};

