import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartComponent as KendoChartComponent } from '@progress/kendo-angular-charts';

@Component({
  selector: 'prism-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {

  @ViewChild(KendoChartComponent)
  chart: KendoChartComponent;

  @Input()
  data: any[];
  @Input()
  categoryField: string;
  @Input()
  field: string;
  @Input()
  legendVisible = false;
  @Input()
  itemLabelVisible = false;
  @Input()
  labelContent: (obj: any) => {} = this.defaultLabelContent;
  @Output()
  seriesClick: EventEmitter<any> = new EventEmitter<any>();

  public active: boolean;

  public defaultLabelContent(e: any): string {
    return e.category;
  }

  public seriesClickHandler($event: any): void {
    this.seriesClick.emit($event);

    this.active = !this.active;
    this.chart.toggleHighlight(this.active, (item) => item.category === $event.category);
  }

}
