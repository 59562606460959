<div class="delete-address-container">
  <div class="header">
    <p>You are about to delete the following address:</p>
  </div>
  <div class="body">
    <p>{{data.addressLine1}}</p>
    <p>{{data.addressLine2}}</p>
    <p>{{data.addressLine3}}</p>
  </div>
  <div class="footer">
    <p>Are you sure you want to delete? <span *ngIf="data.useAsShipping"> By doing this you should set a new shipping address.</span></p>
  </div>
</div>
