/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderProvider { 
    readonly orderProviderId?: number;
    readonly orderId?: number;
    providerOrgId: number;
    readonly providerAddrLine1?: string;
    readonly providerAddrLine2?: string;
    readonly providerCity?: string;
    readonly providerState?: string;
    readonly providerZip?: string;
    providerFacilityId: number;
    readonly providerOrgLegalName?: string;
    readonly providerOrgDBAName?: string;
    readonly providerFacilityName?: string;
    readonly fdosTs?: string;
    readonly assignedTs?: string;
    readonly acceptedTs?: string;
    readonly markedAsFdosCnt?: number;
    readonly providerPhoneNumber?: string | null;
    selectionMode: string;
}

