/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NPIv2 { 
    npiNum: string;
    enumType?: NPIv2.EnumTypeEnum;
    isActive: boolean;
    orgName?: string;
    physFirstName?: string;
    physLastName?: string;
    phoneNumber?: string;
    faxNumber?: string;
}
export namespace NPIv2 {
    export type EnumTypeEnum = 'NPI-1' | 'NPI-2';
    export const EnumTypeEnum = {
        _1: 'NPI-1' as EnumTypeEnum,
        _2: 'NPI-2' as EnumTypeEnum
    };
}


