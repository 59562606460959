<kendo-textbox [placeholder]="placeholder"
  style="width: 100%"
  #textbox
  ngDefaultControl
  [(ngModel)]="value"
  (valueChange)="writeValue($event)">
  <ng-template kendoTextBoxSuffixTemplate>
      <kendo-icon class="cursor-pointer" name="eye" (click)="toggle()"></kendo-icon>
  </ng-template>
</kendo-textbox>
