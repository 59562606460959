import {
  Component,
  DestroyRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual as _isEqual, sortBy as _sortBy } from 'lodash';
import { noop, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ComboboxFilterEvent, DropDownPageEvent, SearchDataList } from '../../shared-interfaces';
import { FormFieldConfig, FormFieldTypes } from './form-field.interface';
import { ComboBoxComponent } from '../combo-box/combo-box.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const FORM_FIELD_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormFieldComponent),
  multi: true
};

@Component({
  selector: 'prism-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  providers: [ FORM_FIELD_CONTROL_VALUE_ACCESSOR ]
})
export class FormFieldComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input()
  public config: FormFieldConfig;
  @Input()
  public disabled: boolean;
  @Output()
  public dataLoaded: EventEmitter<Array<SearchDataList>> = new EventEmitter();
  @ViewChild('combo')
  public comboBoxComponent: ComboBoxComponent;

  private destroyRef = inject(DestroyRef);

  public hasFirstDataLoad = false;
  public types = FormFieldTypes;
  public isLoading = false;
  public data: Array<SearchDataList> = [];
  public minRequiredChars = 3;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  private innerValue: string;
  public multiselectValue: any[] = [];
  public dateRangeStart: Date;
  public dateRangeEnd: Date;
  private useFilterStr = '';
  private subscriptions: Subscription[] = [];
  private isMultiselect: boolean;
  public allowLoadMore = true;

  constructor() {}

  get value(): any {
    if (this.config.type === FormFieldTypes.DATERANGE) {
      return {
        start: this.dateRangeStart,
        end: this.dateRangeEnd
      };
    }

    return this.innerValue;
  }

  set value(value: any) {
    this.writeValue(value);
    this.onChangeCallback(value);
  }

  writeValue(value: any): void {
    if (this.config.type === FormFieldTypes.DATERANGE) {
      this.dateRangeStart = value.start;
      this.dateRangeEnd = value.end;
    }

    if (this.config.type === FormFieldTypes.COMBOBOX_ID) {
      if (value) {
       value = value.toString();
      }
      // if we have a new value and it's not in the data set we
      // need to look it up so the combobox will display it
      const row = this.data?.find((current) => current.id === value);
      if (!row && value) {
        this.fetchSingleItem(value);
      }
    }

    if ([FormFieldTypes.MULTISELECT, FormFieldTypes.MULTISELECT_TYPEAHEAD].includes(this.config.type)) {
      this.updateMultiselectValue(value);
    }
    this.innerValue = value;
  }

  ngOnInit(): void {
    if (this.config?.typeaheadMinLength !== null && this.config?.typeaheadMinLength !== undefined) {
      this.minRequiredChars = this.config.typeaheadMinLength;
    }
    if (this.config?.obsData) {
      const subsData = this.config.obsData.subscribe(data => {
        this.data = data;
      });
      this.subscriptions.push(subsData);
    }
    // support hard coded data list
    if (this.config?.data) {
      this.data = this.config.data;
    }
    this.isMultiselect = this.config?.type === FormFieldTypes.MULTISELECT;
    if (this.config?.type === FormFieldTypes.DROPDOWN) {
      // perform callback to get list data
      this.executeCallback(this.useFilterStr);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  updateMultiselectValue(newValue): void {
    if (Array.isArray(newValue) && this.hasMultiselectChanged(newValue, this.multiselectValue)) {
      this.multiselectValue = newValue.map(item => {
        return { [this.config?.type === FormFieldTypes.MULTISELECT ? 'listDtlCode' : 'id']: item };
      });
    } else if (newValue === null) {
      this.multiselectValue = [];
    }
  }

  hasMultiselectChanged(innerValue: any, multiselectValue: any): boolean {
    const currentIds = Array.isArray(innerValue) ? innerValue : [];
    const selectedIds = Array.isArray(multiselectValue) ?
      multiselectValue.map(item => this.isMultiselect ? item.listDtlCode : item.id) : [];
    return !_isEqual(_sortBy(currentIds), _sortBy(selectedIds));
  }

  fetchSingleItem(id: string): void {
    if (this.config?.fetchSingleItem && !this.isLoading) {
      this.isLoading = true;
      this.config.fetchSingleItem(id)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe(response => {
          this.data = [response];
        });
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  resetFilter(): void {
    if (this.comboBoxComponent) {
      this.data = [];
      this.comboBoxComponent.resetFilter();
    }
  }

  public handleMultiselectSelection($event: any): void {
    if (this.hasMultiselectChanged(this.innerValue, $event)) {
        const value = $event?.map(item => {
          const property = this.isMultiselect ? 'listDtlCode' : 'id';
          if (item.hasOwnProperty(property)) {
            return item[property];
          }
          return item;
        });
        this.innerValue = value;
        this.onChangeCallback(value);
      }
  }

  public handleComboSelection($event: any): void {
    // 12/27/23 - type passed in for id can vary accross codebase.
    // so we have to resort to type coersion to detect same value.
    if (this.value == $event) { return; }
    this.innerValue = $event;
    this.onChangeCallback(this.innerValue);
  }

  public handleDateRangeChange(whichDate: string, $event: any): void {
    let newValue;
    if (whichDate === 'start') {
      newValue = {start: $event, end: this.dateRangeEnd};
    } else {
      newValue = {start: this.dateRangeStart, end: $event};
    }

    this.innerValue = newValue;
    this.onChangeCallback(newValue);
  }

  public handleComboFilterChange(event: ComboboxFilterEvent): void {
      this.executeCallback(event.filter);
  }

  public handleComboPageChange(pagingEvent: DropDownPageEvent): void {
    const { filter, pageSize, pageNumber, restartRowNumber } = pagingEvent;
    if (this.config.pagingConfig) {
      this.executePagingCallback(filter, pageSize, restartRowNumber, pageNumber);
    }
  }

  public executePagingCallback(filterString: string, pageSize: number, restartRowNumber: number, pageNum: number): void {
    this.isLoading = true;
    const pageInd = this.config.pagingConfig.usePageNumberInCallback ? pageNum : restartRowNumber;
    this.config.pagingConfig.onPageCallback(filterString, pageInd, pageSize)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(dataList => {
        if (this.useFilterStr !== filterString || this.data.length === 0) {
          this.useFilterStr = filterString;
          this.data = dataList;
        } else {
          const ids = new Set(this.data.map(v => v.id));
          this.data.push(...dataList.filter(current => {
            return !ids.has(current.id);
          }));
        }
        this.allowLoadMore = dataList.length >= pageSize;
      }).add(() => {
        this.dataLoadCompleted();
      });
  }

  public executeCallback(filterString: string): void {
    if (this.config.pagingConfig) {
      this.executePagingCallback(filterString, this.config.pagingConfig.pageSize, 0, 0);
      return;
    }
    if (this.config?.callback) {
      this.isLoading = true;
      this.config.callback(filterString).pipe(
          takeUntilDestroyed(this.destroyRef)
      ).subscribe((dataList) => {
        this.data = dataList;
      }).add(() => {
        this.dataLoadCompleted();
      });
    }
  }

  private dataLoadCompleted(): void {
    this.dataLoaded.emit(this.data);
    this.isLoading = false;
  }

  public isItemSelected(item: any): boolean {
    return Array.isArray(this.multiselectValue) ?
      this.multiselectValue.filter(val => val.id === item.id).length > 0 :
      this.innerValue === item;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public loadNextPage(event: DropDownPageEvent): void {
    // not implemented yet
  }

  public resetSelections(): void {
    this.data = [];
    this.executeCallback(this.useFilterStr);
  }
}
