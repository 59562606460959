import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prism-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss']
})
export class NotificationBannerComponent {

  @Input() open = true;
  @Input() icon = '';
  // use svgIcon when trying to set an icon from our prism icons registry
  @Input() svgIcon = '';
  @Input() closable = true;
  @Input() type = 'secondary';

  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() bannerClose: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  get closableClass(): string {
    return this.closable ? 'k-notification-closable' : '';
  }

  public close(): void {
    this.open = false;
    this.openChange.emit(this.open);
    this.bannerClose.emit();
  }

}
