import { Component, computed, Input, Signal, signal } from '@angular/core';
import { ModalAction } from 'src/app/shared/shared-interfaces/shared-interfaces';
import { CanDeactivateSignalService } from '../../shared-guard/can-deactivate/can-deactivate.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'prism-can-deactivate-dialog',
  templateUrl: './can-deactivate-dialog.component.html',
  styleUrls: ['./can-deactivate-dialog.component.scss']
})
export class CanDeactivateDialogComponent {

  @Input() title: string;
  @Input() titleIcon: string;
  @Input() titleIconType = 'primary';
  @Input() width = 505;

  private isDialogOpen = signal(false);
  protected showDialog: Signal<boolean>;

  set open(value: boolean) {
    this.isDialogOpen.set(value);
  }

  get isOpen(): boolean {
    return this.showDialog();
  }

  public actions: ModalAction[] = [
    { text: 'Cancel', callback: () => this.closeModal(false), type: 'fifth' },
    { text: 'Confirm', callback: () => this.closeModal(true), type: 'primary' },
  ];

  constructor(
    private canDeactivateSignalService: CanDeactivateSignalService,
    private router: Router
  ) {
    this.showDialog = computed(() => {
      if (this.isDialogOpen()) {
        return true;
      }

      return this.canDeactivateSignalService.open();
    });
  }

  closeModal(confirmed: boolean) {
    this.canDeactivateSignalService.confirmed.set(confirmed);
    this.canDeactivateSignalService.open.set(false);
    if (confirmed && this.canDeactivateSignalService.newPath()) {
      // if used as a generic dialog, no need to navigate away.
      // newPath should not be set to anything but an empty string in that case.
      this.router.navigate([this.canDeactivateSignalService.newPath()]);
      this.canDeactivateSignalService.newPath.set('');
    }
  }

}
