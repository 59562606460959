<div *ngIf="search" class="search-container">
  <input class="form-control" [(ngModel)]="searchValue" placeholder="Search" />
</div>
<kendo-grid class="prism-grid" #gridComponent *ngIf="data" [data]="data | searchPipe : [search, searchValue]"
  [sortable]="{ allowUnsort: sortable?.allowUnsort, mode: sortable?.mode }" [sort]="sort" [skip]="skip"
  (sortChange)="handleSortChange($event)" [rowClass]="getRowStyle" [height]="height" [loading]="loading"
  [selectable]="selectable" [selectedKeys]="selectedKeys" (selectionChange)="handleSelectionChange($event)"
  [pageSize]="pageSize" [pageable]="pageable" (pageChange)="loadMore($event)" (cellClick)="rowClickHandler($event)"
  [kendoGridSelectBy]="selectionKey">
  <ng-template *ngIf="noData" kendoGridNoRecordsTemplate>
    <div class="no-grid-content">
      <h2 class="message-header">{{ noData.title }}</h2>
      <p class="message-description roboto-reg-gray">
        {{ noData.description }}
      </p>
      <img [src]="noData.image" class="no-data-img" alt="No data found" />
    </div>
  </ng-template>
  <kendo-grid-checkbox-column *ngIf="selectable" [style]="{ width: '4%' }"
    [headerStyle]="{ width: '4%', 'background-color': '#F6F6F6' }"></kendo-grid-checkbox-column>
  <kendo-grid-column *ngFor="let column of columns; index as colIndex" [field]="column.field"
    [headerClass]="column.additionalInput?.headerClassName" [headerStyle]="getHeaderStyle(column.widthStyle)"
    [style]="getStyle(column.widthStyle)" [title]="column.title" [width]="column.width">
    <ng-template kendoGridHeaderTemplate>
      {{column.title}}
      <span *ngIf="!!sort && (sort[0]?.field === '' || sort[0]?.dir === undefined ||
      sort[0]?.field !== column.field) && this.sortableColumns.includes(column.field)">
        <span class="k-icon k-font-icon k-i-arrows-swap"></span>
      </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" [ngSwitch]="getFieldType(column, dataItem)">
      <ng-template #justText>
        <span [ngStyle]="{color: column.cellDataColor}">
          {{ getField(dataItem, column) }}
        </span>
      </ng-template>
      <span *ngSwitchCase="'boolean'">{{ getField(dataItem, column) ? 'YES' : 'NO' }}</span>
      <ng-container *ngSwitchCase="'text'">
        <span>
          <ng-container *ngIf="
              getField(dataItem, column) !== null &&
                getField(dataItem, column)?.tooltip !== undefined &&
                getField(dataItem, column)?.tooltip !== null;
              else justText
            ">
            <span *ngIf="
                getField(dataItem, column)?.tooltip !== null &&
                !getField(dataItem, column).tooltip
              ">
              {{
              getField(dataItem, column).value
              ? getField(dataItem, column).value
              : getField(dataItem, column)
              }}
            </span>
            <span *ngIf="
                dataItem[column.field].value && dataItem[column.field].tooltip
              " kendoTooltip position="top" title="{{ dataItem[column.field].tooltip }}">
              {{ getField(dataItem, column).value }}
            </span>
          </ng-container>
        </span>
      </ng-container>
      <ng-container *ngIf="getFieldType(column, dataItem) === 'form-control-readonly-text'">
        {{ dataItem.controls[column.field].value }}
      </ng-container>
      <span *ngSwitchCase="'percentage'">{{getField(dataItem, column) | number:'.2-2'}}<span
          *ngIf="dataItem[column.field]">%</span></span>
      <span *ngSwitchCase="'number'">{{getField(dataItem, column) | number:'.2-2'}}</span>
      <span *ngSwitchCase="'money'">{{getField(dataItem, column) | currency }}</span>
      <span *ngSwitchCase="'phone'">{{getField(dataItem, column) | phone}}</span>
      <span *ngSwitchCase="'date-time'">{{getField(dataItem, column) | date: 'MM/dd/yyyy h:mm a': null:
        dateLocalePipe}}</span>
      <span *ngSwitchCase="'date'">{{getField(dataItem, column) | date: 'MM/dd/yyyy': null: dateLocalePipe}}</span>
      <ng-container *ngSwitchCase="'dynamic-router-link'">
        <a *ngIf="column.getRoute(dataItem).length; else justText"
           class="prism-link"
           [routerLink]="column.getRoute(dataItem)"
           [queryParams]="column.getQueryParams ? column.getQueryParams(dataItem) : {}"
           >{{column.getDisplay(dataItem)}}</a>
      </ng-container>
      <a *ngSwitchCase="'link'" [href]="getField(dataItem, column)" class="prism-link" target="_blank" rel="noopener">{{
        column.additionalInput.displayText }}</a>
      <prism-pill-flag *ngSwitchCase="'pill-flag'" [active]="getField(dataItem, column)"></prism-pill-flag>
      <span *ngSwitchCase="'pill'">
        <prism-pill *ngIf="!getField(dataItem, column).value" [status]="dataItem[column.field]" class="d-flex"></prism-pill>
        <span *ngIf="getField(dataItem, column).value && dataItem[column.field].tooltip" kendoTooltip position="top"
          title="{{ getField(dataItem, column).tooltip }}">
          <prism-pill *ngIf="getField(dataItem, column).value" [status]="dataItem[column.field].value"
            class="d-flex"></prism-pill>
        </span>
      </span>
      <span *ngIf="getFieldType(column, dataItem) === 'date-time-picker'">
        <kendo-timepicker [value]="getField(dataItem, column)" [formControl]="dataItem.controls[column.field]"
          (valueChange)="column.additionalInput.callback($event, dataItem.controls)"></kendo-timepicker>
      </span>
      <span [class]="column.additionalInput.className" *ngIf="getFieldType(column, dataItem) === 'checkbox'">
        <prism-checkbox value="getField(dataItem, column)" [formControl]="dataItem.controls[column.field]"
          (selection)="column.additionalInput.callback($event, dataItem.controls)"></prism-checkbox>
      </span>
      <span *ngIf="getFieldType(column, dataItem) === 'conditional-button'">
        <prism-button *ngIf="column.additionalInput.condition === getField(dataItem, column)"
          [icon]="column.additionalInput.icon" [size]="'small'" [type]="column.additionalInput.type"
          (clicked)="column.additionalInput.callback(dataItem)">
          {{ column.additionalInput.text }}
        </prism-button>
      </span>
      <span *ngSwitchCase="'button'">
        <prism-button [icon]="column.additionalInput.icon" [size]="'small'" [class]="column.additionalInput.className"
          [type]="column.additionalInput.type" (clicked)="column.additionalInput.callback(dataItem)">
          {{ column.additionalInput.text }}
        </prism-button>
      </span>
      <span *ngSwitchCase="'label-icon'" class="d-flex justify-content-between">
        <span>{{ getContentFromDataItem(dataItem,column) }}</span>
        <prism-button *ngIf="column.additionalInput.callbackCondition(dataItem)" [icon]="column.additionalInput.icon"
          [size]="'small'" [type]="column.additionalInput.type"
          (clicked)="column.additionalInput.callback(dataItem)"></prism-button>
      </span>
      <span *ngSwitchCase="'icon'" class="d-flex justify-content-between">
        <span *ngIf="column.additionalInput.callbackCondition(dataItem)"
          class="k-icon k-font-icon k-i-{{column.additionalInput.icon}}"></span>
      </span>
      <span *ngSwitchCase="'mat-icon'" class="d-flex justify-content-between">
        <span *ngIf="column.additionalInput.callbackCondition(dataItem)">
          <mat-icon svgIcon="{{column.additionalInput.icon}}"></mat-icon>
        </span>
      </span>
      <span *ngSwitchCase="'icons-labels'">
        <ng-container *ngFor="let iconLabel of getField(dataItem, column)">
          <span *ngIf="!iconLabel.label" class="k-icon k-font-icon mr-1 iconlabel" [ngClass]="[iconLabel.iconClasses]"></span>
          <span *ngIf="iconLabel.label" class="k-icon k-font-icon iconlabel" [ngClass]="[iconLabel.iconClasses]"></span>
          <span *ngIf="iconLabel.label" class="iconLabel">{{
            iconLabel.label
            }}</span>
        </ng-container>
      </span>
      <span *ngSwitchCase="'html'" [innerHtml]="getField(dataItem, column) | safeHtml"></span>
      <span *ngSwitchCase="'lookup'" class="d-flex justify-content-between">
        <prism-lookup [class]="column.additionalInput?.className || 'col-8'" [clearButton]="false"
          [listTypeName]="column.additionalInput.listTypeName" [(ngModel)]="dataItem[column.field]"></prism-lookup>
        <prism-button *ngIf="column.additionalInput.callbackCondition(dataItem)" icon="check" type="submit"
          (click)="column.additionalInput.callback(dataItem)">Update</prism-button>
      </span>
      <span *ngSwitchCase="'form-control-lookup'" class="d-flex justify-content-between">
        <prism-lookup [class]="column.additionalInput?.className || 'col-8'"
                      [clearButton]="column.additionalInput?.showClearButton"
                      [formControl]="dataItem.controls[column.field]"
                      [listTypeName]="column.additionalInput.listTypeName"
                      [displayWithCode]="column.additionalInput?.displayWithCode"
                      [paging]="column.additionalInput?.lookupPaging"
                      [pageSize]="column.additionalInput?.lookupPageSize"
                      [filterable]="column.additionalInput?.filterable"
                      [cacheData]="column.additionalInput?.cacheData"
                      (valueDataChange)="column.additionalInput?.callback($event, dataItem, column.field)"
                      [excludeCodes]="column.additionalInput?.getExcludeCodes?.(rowIndex) || []">
        </prism-lookup>
        <prism-button *ngIf="column.additionalInput.callbackCondition(dataItem)" icon="check" type="submit"
          (click)="column.additionalInput.callback(dataItem)">Update</prism-button>
      </span>
      <span *ngIf="getFieldType(column, dataItem) === 'form-control-dropdown'" class="d-flex justify-content-between">
        <prism-dropdown-list [pageSize]="column.additionalInput?.pagingConfig?.pageSize"
          (loadNextPage)="loadNextOptionsPageForCell($event, rowIndex, colIndex)"
          [class]="column.additionalInput?.className || 'col-8'" [data]="getDropDownOptionsForCell(rowIndex, colIndex)"
          [formControl]="dataItem.controls[column.field]"></prism-dropdown-list>
      </span>
      <span *ngIf="getFieldType(column, dataItem) === 'form-control-input'" class="d-flex justify-content-between">
        <kendo-textbox [placeholder]="column.additionalInput?.placeholder" value="getField(dataItem, column)"
          [formControl]="dataItem.controls[column.field]" [disabled]="true">
        </kendo-textbox>
      </span>
      <span *ngSwitchCase="'info-positive-negative'" [ngClass]="{'text-danger': getField(dataItem, column) < 0 }">
        {{getField(dataItem, column)}}
      </span>

      <span *ngSwitchCase="'form-control-number'" class="d-flex justify-content-between">
        <kendo-numerictextbox [placeholder]="column.additionalInput?.placeholder"
          [formControl]="dataItem.controls[column.field]" [format]="column.additionalInput?.textBoxFormat ?? ''"[min]="column.additionalInput?.min"
          [spinners]="column.additionalInput?.showSpinners">
        </kendo-numerictextbox>
      </span>
      <span *ngSwitchCase="'form-control-html'" [innerHtml]="dataItem.controls[column.field]?.value | safeHtml"></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column [hidden]="rowActions.length === 0" *ngIf="rowActions" [class]="{ actionsHeader: true }"
    [headerStyle]="{ 'background-color': '#F6F6F6' }" [autoSize]="true" [width]="getDynamicWidth(rowActions)">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="{{ hideActions ? 'rowactions-hidden': 'rowactions'}}">
        <prism-button *ngFor="let action of rowActions"
                      class="mr-1 {{canShowAction(action, dataItem) ? '' : 'rowaction-hidden'}}"
                      [icon]="action.icon"
                      [iconImageUrl]="action.iconImageUrl"
                      [rounded]="action.rounded"
                      [disabled]="action.disabled ? !dataItem[action.additionalInput.column] : false"
                      [size]="action.size ?? 'small'"
                      [type]="action.type"
                      (clicked)="action.callback(rowIndex, dataItem)">
          {{ action.text }}
        </prism-button>
      </div>
    </ng-template>
  </kendo-grid-command-column>
  <ng-template *ngIf="showDtlRow" kendoGridDetailTemplate
               [kendoGridDetailTemplateShowIf]="canShowDtlRow.bind(this)"
               let-dataItem>
    <ng-container [ngTemplateOutlet]="dtlRowTemplate"
                  [ngTemplateOutletContext]="{ dataItem: dataItem }"
    ></ng-container>
  </ng-template>
  <ng-template kendoPagerTemplate>
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>
