<div class="row mb-3">
  <div class="col-7">
    <integra-search
      searchViewMode="adv-search-wide"
      placeHolder="Search by User, Field Name, Field Value, Field/Activity"
      [closeButton]="true"
      [searchConfig]="advSearchConfig"
      (applyFilterEvent)="handleAdvSearchChange($event)"></integra-search>
  </div>
</div>
<prism-grouped-grid
  [data]="logs"
  [style]="{'border': 'none'}"
  [columns]="columns"
  [gridPadding]="315"
></prism-grouped-grid>
