<ng-container *ngIf="open">
  <div class="k-widget k-notification button-notification" [ngClass]="[type, closableClass]">
    <div class="k-notification-wrap">
      <mat-icon *ngIf="svgIcon" class="k-icon k-font-icon p-icon" svgIcon="{{svgIcon}}"></mat-icon>
      <span *ngIf="icon" class="k-icon k-font-icon" [ngClass]="icon"></span>
      <div class="k-notification-content">
        <ng-content></ng-content>
      </div>
      <a *ngIf="closable" class="k-icon k-font-icon k-i-close" title="Close" (click)="close()"></a>
    </div>
  </div>
</ng-container>
