/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProvSummaryIDs } from './provSummaryIDs';
import { PayerSummaryIDs } from './payerSummaryIDs';


export interface SummaryReq { 
    viewMode: SummaryReq.ViewModeEnum;
    viewStartDate?: string;
    viewEndDate?: string;
    includeStatusCodes: Array<string>;
    listOfPayers: Array<PayerSummaryIDs>;
    listOfProviders: Array<ProvSummaryIDs>;
}
export namespace SummaryReq {
    export type ViewModeEnum = 'summary' | 'as-of-exp-dlvy';
    export const ViewModeEnum = {
        Summary: 'summary' as ViewModeEnum,
        AsOfExpDlvy: 'as-of-exp-dlvy' as ViewModeEnum
    };
}


