/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItemDetails } from './orderItemDetails';


export interface OrderDetailsResponse { 
    orderId: number;
    orderNum?: string;
    authWinFromDt?: string | null;
    authWinToDt?: string | null;
    authCode?: string | null;
    expOrderDeliveryTs?: string;
    orderStatusCode?: string;
    orderDiagCodes?: Array<string>;
    items?: Array<OrderItemDetails>;
}

