import { Component, Host, Input, Optional } from '@angular/core';
import { FormErrorsComponent } from '../form-errors/form-errors.component';

@Component({
  selector: 'prism-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

  @Input() public error: string;

  constructor(@Optional() @Host() public parent: FormErrorsComponent) { }

  get showError(): boolean {
    if (!this.parent?.control || !this.error) {
      return false;
    } else {
      return this.parent.control.hasError(this.error);
    }
  }

  get isTouched(): boolean {
    return !!this.parent?.control?.touched;
  }

  get isDirty(): boolean {
    return !!this.parent?.control?.dirty;
  }

  get isEmpty(): boolean {
    return !!this.parent?.control?.dirty;
  }

  get isEmptyInputValue(): boolean {
    return !this.parent?.control?.value || this.parent?.control?.value?.length === 0;
  }

}
