/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BillEntity { 
    billEntityId?: number;
    billEntityName: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    taxId: string;
    npi: string;
    /**
     * SSN, EIN
     */
    taxIdType?: BillEntity.TaxIdTypeEnum;
    phone?: number;
    fax?: number;
    isDefault: boolean;
    createdTS?: string;
    createdBy?: string;
    modifiedTS?: string;
    modifiedBy?: string;
}
export namespace BillEntity {
    export type TaxIdTypeEnum = 'SSN' | 'EIN';
    export const TaxIdTypeEnum = {
        Ssn: 'SSN' as TaxIdTypeEnum,
        Ein: 'EIN' as TaxIdTypeEnum
    };
}


