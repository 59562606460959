import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterRequest, GridColumn, IntegraSearchConfigRow } from '../../shared-interfaces';
import { UIActivityAction } from '../../shared-services/activity-log';
import { ActivityLogService } from '../../shared-services/activity-log/activity-log.service';
import { ChangeLogFilter, ChangeLogRequestConfigI, ChangeLogServiceI } from '../../shared-services/activity-log/change-log.interfaces';
import { NotificationService } from '../../shared-services/notification/notification.service';


@Component({
  selector: 'prism-activity-log-list',
  templateUrl: './activity-log-list.component.html',
  styleUrls: ['./activity-log-list.component.scss']
})
export class ActivityLogListComponent implements OnInit {

  protected _logs: Array<UIActivityAction> = [];

  public logs: Array<UIActivityAction> = [];

  /**
  * @deprecated implement getEntityName on ChangeLogServiceI
  */
  @Input()
  entity: string;

  @Input() service: ChangeLogServiceI;

  @Input() serviceConfig: ChangeLogRequestConfigI;

  public columns: GridColumn[];
  public totalCount = 0;
  public totalResponseCount = 0;
  public completeResponseList: string[] = [];
  public contextName: string;

  public advSearchConfig: IntegraSearchConfigRow[] = [
    {
      columns: [{
        type: 'string',
        label: 'Search by User, Field Name, Field Value, Activity (minimum 3 characters)',
        value: null,
        key: 'FieldNameValue',
        property: 'fieldNameValue',
        placeholder: 'User, Field Name, Field Value, Activity',
        minLength: 3
      }]
    }, {
      columns: [
        {
          type: 'multiselect',
          label: 'User Type',
          value: null,
          key: 'UserType',
          property: 'userType',
          placeholder: 'Select User Type',
          data: [
            {id: 'internal', displayName: 'Internal', displaySelected: 'Internal'},
            {id: 'payer', displayName: 'Payer', displaySelected: 'Payer'},
            {id: 'provider', displayName: 'Provider', displaySelected: 'Provider'},
          ],
          filterable: false
        },
        {
          type: 'multiselect',
          label: 'Action Type',
          value: null,
          key: 'Action Type',
          property: 'activityType',
          placeholder: 'Select Action Type',
          data: [
            { id: 'manual', displayName: 'Manual', displaySelected: 'Manual' },
            { id: 'integration', displayName: 'Integration', displaySelected: 'Integration' },
            { id: 'job', displayName: 'Job', displaySelected: 'Job' },
            { id: 'patch', displayName: 'Patch', displaySelected: 'Patch' },
            { id: 'event', displayName: 'Event', displaySelected: 'Event' }
          ],
          filterable: false
        }
      ]
    }
  ];

  constructor(
    protected activityLogService: ActivityLogService,
    protected notificationSvc: NotificationService,
  ) { }

  ngOnInit(): void {
    if (this.service) {
      this.initSubscriptions();
    }

    this.columns = this.columnsBuilder();
  }

  initSubscriptions(): void {
    let fetchLogsSub = this.activityLogService.fetchLogs(this.service, this.serviceConfig, this.entity, this.contextName);
    let fetchActivitySub = this.activityLogService.fetchActivity(this.service, this.serviceConfig);

    fetchLogsSub = fetchLogsSub.pipe(catchError(e => {
      this.notificationSvc.showErrorNotification(e);
      return of([]);
    }));

    fetchActivitySub = fetchActivitySub.pipe(catchError(e => {
      this.notificationSvc.showErrorNotification(e);
      return of([]);
    }));

    combineLatest([fetchLogsSub, fetchActivitySub])
      .pipe(map(([logs, activity]) => {
        return this.sortLogs(logs.concat(activity));
      }))
      .subscribe(logs => {
        this.logs = logs;
        this._logs = logs;
      });
  }
  public handleAdvSearchChange(filters: ApiFilterRequest[]): void {
    if (filters?.length === 0) {
      this.logs = this._logs;
    } else {
      const fldNameFilterVal = filters.find(x => x.property === 'fieldNameValue' || x.property === 'wildSearch')?.value;
      const filter: ChangeLogFilter = {
        fieldNameValue: fldNameFilterVal ? fldNameFilterVal.replace('%', '') : '',
        activityType: filters.find(x => x.property === 'activityType')?.value ?? [],
        userType: filters.find(x => x.property === 'userType')?.value ?? [],
        status: filters.find(x => x.property === 'status')?.value ?? [],
        orderStatus: filters.find(x => x.property === 'orderStatus')?.value ?? [],
      };

      this.logs = this.activityLogService.filterActivityChange(filter, this._logs);
    }
  }

  private sortLogs(logs: Array<UIActivityAction>): Array<UIActivityAction> {
    return logs.sort((logA, logB) => (logA.changeTS > logB.changeTS) ?
      -1 : ((logB.changeTS > logA.changeTS) ? 1 : 0));
  }

  protected columnsBuilder(): GridColumn[] {
      return [
        { field: 'changeTS', title: 'Date', type: 'date', widthStyle: {width: '10%'} },
        { field: 'changedBy', title: 'User Name/Type/Org', type: 'text', widthStyle: {width: '15%'} },
        { field: 'activity', title: 'Activity', type: 'text', widthStyle: {width: '15%'} },
        { field: 'rowNum', title: 'Attribute #', type: 'text', widthStyle: {width: '10%'} },
        { field: 'originalValue', title: 'Original Value', type: 'text', widthStyle: {width: '20%'} },
        { field: 'newValue', title: 'New Value', type: 'text', widthStyle: {width: '20%'} },
        { field: 'activityType', title: 'Activity Type', type: 'text', widthStyle: {width: '10%'} },
        { field: 'status', title: 'Status', type: 'pill', widthStyle: {width: '10%'}}
      ];
    }
}
