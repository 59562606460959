import { Observable } from 'rxjs';
import { PagingConfig, SearchDataList } from '../../shared-interfaces';

export enum FormFieldTypes {
  DATE = 'D',
  DATETIME = 'DT',
  DATERANGE = 'DR',
  ARRAY_STRING = 'AS',
  ID = 'I',
  COMBOBOX_ID = 'COMBOBOX_ID',
  DROPDOWN = 'DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  LOOKUP_DROPDOWN = 'LOOKUP_DROPDOWN',
  MULTISELECT_TYPEAHEAD = 'MULTISELECT_TYPEAHEAD',
  YES_NO = 'YN',
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER'
}

export interface FormFieldConfig {
  filterComponent?: string;
  label: string;
  name: string;
  type: FormFieldTypes;
  validators?: {
    required?: boolean;
    requiredIfValue?: {[key: string]: string[]};
  };
  listTypeName?: string;
  data?: SearchDataList[];
  rawData?: SearchDataList[];
  placeholder?: string;
  obsData?: Observable<SearchDataList[]>;
  typeaheadMinLength?: number;
  pagingConfig?: PagingConfig;
  endLabel?: string;
  endName?: string;
  onPageCallback?(searchString: string, restartRowNum: number, pageSize: number): Observable<Array<SearchDataList>>;
  callback?(searchString: string): Observable<Array<SearchDataList>>;
  fetchSingleItem?(entityId: string): Observable<SearchDataList>;
}
