import { Pipe, PipeTransform,Injectable } from '@angular/core';

@Pipe({
  name: 'nullWithDefault'
})

export class NullWithDefaultPipe implements PipeTransform {

  transform(value: any, defaultText: any = ''): any {
    if (typeof value === 'undefined' || value === null) {
      return defaultText;
    }
    return value;
  }

}
