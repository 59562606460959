/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListItemDtlAttr } from './listItemDtlAttr';
import { ListItemDtlCtx } from './listItemDtlCtx';


export interface ListItemDtl { 
    listDtlId?: number;
    listTypeId?: number;
    listDtlCode: string;
    listDtlDesc: string;
    listDtlStartDt: string;
    listDtlEndDt?: string | null;
    contexts?: Array<ListItemDtlCtx>;
    attributes?: Array<ListItemDtlAttr>;
}

