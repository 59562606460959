<div class="card-container" [ngStyle]="{ minHeight: height}">
  <div *ngIf="type === 'existent'">
    <div class="row contact-header" kendoContextMenuTargetContainer
      #contactcardActionsTarget="kendoContextMenuTargetContainer">
      <ng-container *ngTemplateOutlet="card_template"></ng-container>
      <div class="pointer col-2 justify-content-end" [kendoContextMenuTarget]="contactData" *ngIf="!isReadOnly">
        <span data-testid="contact-card-ellipsis" class="k-icon k-font-icon k-i-more-vertical"></span>
      </div>
    </div>
    <kendo-contextmenu [target]="contactcardActionsTarget" (select)="onContextSelect($event)" [items]="contextItems"
      showOn="click"></kendo-contextmenu>
  </div>
  <div class="add-container mt-5 row align-items-center" *ngIf="type === 'add'">
    <div class="col-6">
      <div>
        <img src="../../../../assets/images/contact.png" alt="" class="contact-image">
      </div>
    </div>
    <div class="col-6">
      <div class="btn-container">
        <prism-button class="add-contact" [icon]="'plus'" [type]="'primary'" (clicked)="addAction.emit()">
          Add Contact
        </prism-button>
      </div>
    </div>
  </div>
</div>
<prism-modal [title]="'Delete Contact'" [opened]="openDeleteModal" [actions]="deleteModalActions"
  (cancel)="closeDeleteModal()">
  <div *ngIf="!!contactData">
    <p>You are about to delete the following contact:</p><br />
    <ng-container *ngTemplateOutlet="card_template"></ng-container>
  </div>
  <p>
    <strong>Are you sure you want to delete?</strong>
  </p>
</prism-modal>

<ng-template #card_template>
  <div class="col-10" [ngClass]="{'default': contactData.source === 0 && contactData.isDefault}">
    <div class="header-type primary justify-content-between" *ngIf="!openDeleteModal && contactData.isDefault">
      <span class="capitalize">Default Contact</span>
    </div>
    <div *ngIf="contactData.relToIndv" class="contact-header d-flex justify-content-start">
      <div class="header-type secondary">
        <span class="capitalize">{{
          contactData.relToIndv.charAt(0).toUpperCase() +
          contactData.relToIndv.slice(1).toLowerCase()
          }}</span>
      </div>
    </div>

    <div
      [ngClass]="{'contact-type': contactData.source === 0, 'contact-type-pill': contactData.source === 1,
    'escalation': ['ESCALATION', 'PRIMARY'].includes(contactData.cntctTypeCode), 'ces': ['CES', 'CLAIMS'].includes(contactData.cntctTypeCode)}">
      {{getCntctDescription(contactData.cntctTypeCode, contactData.source === 0 ? 'CONTACT_INFO_TYPE' :
      'CONTACT_TYPE')}}
    </div>
    <div class="roboto-reg-gray mt-2" *ngIf="contactData.cntctName">
      {{ contactData.cntctName }}
    </div>
    <div class="contact-value" *ngIf="contactData.cntctValue">
      <input class="blank-input" [(ngModel)]="contactData.cntctValue"
        [mask]="contactData.cntctValue.startsWith('*')? null : getContactValMask(contactData.cntctTypeCode, contactData.cntctInfoTypes)"
        type="text" readonly="true">
    </div>
    <ng-container *ngIf="contactData.source === 0 && !openDeleteModal">
      <div class="set-default" *ngIf="!isReadOnly && 'true' === canUseAsMbrPrimary(contactData.cntctTypeCode)">
        <a class="action" (click)="setAsDefault()">Set as Default Contact</a>
      </div>
    </ng-container>
    <ng-container *ngIf="contactData.cntctDtls && contactData.cntctDtls.length > 0">
      <div class="mt-1 row">
        <div class="col-md-4" *ngFor="let dtl of contactData.cntctDtls; index as idx">
          <label class="roboto-med-gray mb-0">{{getCntctDescription(contactData.cntctDtls[idx].cntctDtlTypeCode,
            'CONTACT_INFO_TYPE')}}</label>
          <div class="label-value-wrapper">
            <label class="mt-0 roboto-reg-gray" *ngIf="contactData.cntctDtls[idx].cntctDtlTypeCode.includes('PHONE') ||
            contactData.cntctDtls[idx].cntctDtlTypeCode.includes('FAX'); else other">
              {{contactData.cntctDtls[idx].cntctDtlValue | phone}}
            </label>
            <ng-template #other>
              <label class="mt-0 roboto-reg-gray">{{contactData.cntctDtls[idx].cntctDtlValue}}</label>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>