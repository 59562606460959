import {Injectable, NgZone} from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import {LDFlagValue, LDUser, LDOptions, basicLogger} from 'launchdarkly-js-client-sdk';
import {BehaviorSubject, combineLatest, from, Observable, Subject} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';
import {FeatureFlag} from './feature-flag.type';
import {FeatureFlagNoOpService} from './feature-flag.no-op.service';

declare global {
  interface Window { integra: any; }
}

export interface FlagChange {
  flagKey: string;
  previous: any;
  current: any;
}

// we can remove this one later... it is temporary while we are deploying LaunchDarkly
const featureFlagFactory = (ngZone: NgZone): FeatureFlag => {
  if (window.integra.featureFlag.isEnabled && window.integra.featureFlag.relayUrl) {
    return new FeatureFlagService(ngZone);
  } else {
    return new FeatureFlagNoOpService();
  }
};

@Injectable({
  providedIn: 'root',
  useFactory: featureFlagFactory,
  deps: [NgZone],
})
export class FeatureFlagService implements FeatureFlag {

  client: LaunchDarkly.LDClient;
  onChangesSubject: Subject<FlagChange>;
  isIndentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isIndentSubject$ = this.isIndentSubject.asObservable();

  constructor(private ngZone: NgZone) {
    const user = { anonymous: true } as LaunchDarkly.LDUser;
    const options: LDOptions = {
      baseUrl: window.integra.featureFlag.relayUrl,
      streamUrl: window.integra.featureFlag.relayUrl,
      eventsUrl: window.integra.featureFlag.relayUrl,
      logger: basicLogger({ level: window.integra.featureFlag.logLevel }),
    };
    // We need to run launchDarkly outside the angulars "zone" so it doesn't trigger change
    // detection due to constant use of setTimeout.
    this.ngZone.runOutsideAngular(() => {
      this.client = LaunchDarkly.initialize(window.integra.featureFlag.clientId, user, options);
    })
  }

  // get flag value
  public getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
    // in order to get targeted flag - we need to wait when client is initialized and LD identification sequence is complete
    return combineLatest([from(this.client.waitUntilReady()), this.isIndentSubject$]).pipe(
      filter(([_, isIdentified]) => isIdentified === true),
      map(() => {
        return this.client.variation(flagKey, defaultValue);
      }),
      first()
    );
  }

  public getBooleanFlag(flagKey: string, defaultValue: boolean = false): Observable<boolean> {
    // in order to get targeted flag - we need to wait when client is initialized and LD identification sequence is complete
    return combineLatest([from(this.client.waitUntilReady()), this.isIndentSubject$]).pipe(
        filter(([_, isIdentified]) => isIdentified === true),
        map(() => {
          return !!this.client.variation(flagKey, defaultValue);
        }),
        first()
    );
  }

  // reset user session
  public resetSession(): void {
    this.client.identify({anonymous: true})
      .then(() => this.isIndentSubject.next(false))
      .catch(err => console.log(`failed to reset session`, err));
  }

  // initialize flag session
  public setSession(userId: string, familyName: string, giveName: string, email: string, userType: string, roles: string[]): void {
    const userName = `${giveName} ${familyName}`;
    this.client.identify({key: userId, name: userName, anonymous: false, email, custom: {
        roles,
        userType,
    }} as LDUser)
      .then(() => this.isIndentSubject.next(true))
      .catch(err => console.log(`failed to initialize session`, err));
  }

  // receive flag change events
  public onChanges(): Observable<FlagChange> {
    if (!this.onChangesSubject) {
      this.onChangesSubject = new Subject();
      this.client.on(`change`, (flags) =>
        Object.keys(flags).forEach((flagKey) => this.onChangesSubject.next({
          flagKey,
          current: flags[flagKey].current,
          previous: flags[flagKey].previous,
        }))
      );
    }

    return this.onChangesSubject;
  }

}
