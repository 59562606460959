/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PayerLobList { 
    payerLobId?: number;
    payerFamId?: number;
    payerPlanId?: number;
    payerAcctId?: number;
    payerAcctName?: string;
    payerLobName?: string;
    payerFamName?: string;
    payerPlanName?: string;
    orderReceiptMethodCode?: string;
    status?: string;
    benefitTypeCode?: string;
    ormEnabled?: boolean;
    clmEnabled?: boolean;
    activeFromDt?: string;
    activeToDt?: string;
}

