import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KendoModule } from './kendo/kendo.module';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { SharedServicesModule } from './shared-services/shared-services.module';
import { MaterialModule } from './material/material.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KendoModule,
    SharedComponentsModule,
    SharedServicesModule,
    MaterialModule
  ],
  providers: [],
  exports: [
    KendoModule,
    MaterialModule,
    SharedComponentsModule,
    SharedServicesModule,
  ]
})
export class SharedModule { }
