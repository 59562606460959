import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MdmService } from '../services/mdm.service';
import { CodeToDescAsyncPipe } from './code-to-desc-async/code-to-desc-async.pipe';
import { MdmCodeToDescPipe } from './mdm-code-to-desc/mdm-code-to-desc.pipe';

export const pipes = [
  MdmCodeToDescPipe,
  CodeToDescAsyncPipe
];

@NgModule({
  declarations: [
    ...pipes
  ],
  imports: [ CommonModule ],
  exports: [
    ...pipes
  ],
  providers: [ MdmService ]
})
export class MdmPipesModule { }
