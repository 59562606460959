import { Component } from '@angular/core';

@Component({
  selector: 'prism-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent {

  constructor() { }
}
