import { Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { LoaderService } from '../../shared-services/loader/loader.service';

@Component({
  selector: 'prism-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent implements OnInit, OnDestroy {

  private subs: Subscription;

  public loading = false;

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.subs = this.loaderService.loading$
      .pipe(
        debounce(loading => loading ? EMPTY : timer(500))
      )
      .subscribe(loading => {
        this.loading = loading;
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
