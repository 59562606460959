import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

interface ErrorMessages {
  min?: string;
  max?: string;
}

export const MIN_YEAR_MSG = "Please enter a four digit year.";

@Component({
  selector: 'prism-datepicker',
  templateUrl: './prism-datepicker.component.html',
  styleUrls: ['./prism-datepicker.component.scss'],
})
export class PrismDatepickerComponent{
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() allowSelectionOfPastDates: boolean;
  @Input() allowSelectionOfFutureDates: boolean;
  @Input() errMessages: ErrorMessages;
  @Input() customDisableDate: (date: Date) => boolean = (date:Date) => false;
  @Input()
  set minDate (val: Date) {
    if (!val) return;
    // value of kendo datepicker will have time set to
    // first second so we should make sure all minDate
    // passed in also match to avoid confusion
    this._minDate = new Date(val.setHours(0, 0, 0, 0));
  }

  get minDate() {
    if (!this.isRangeValid()) {
     return null;
    }
    return this._minDate;
  }

  @Input()
  set maxDate(val: Date) {
    if (!val) return;
    this._maxDate = new Date(val.setHours(23, 59, 59, 999));
  }

  get maxDate() {
    if (!this.isRangeValid()) {
      return null;
    }
    return this._maxDate;
  }

  // the kendo component breaks entirely and leaves an orphaned calendar popup
  // stuck on the page if the minDate passed in is greater than the maxDate.
  // so we will use this validator to ensure we never do that.
  private rangeIsValid = true;
  private isRangeValid(): boolean {
    if (this._minDate && this._maxDate && this._minDate > this._maxDate) {
      // only log the first time we set valid to false.
      if (this.rangeIsValid === true) {
        console.error("invalid range passed to datepicker");
      }
      this.rangeIsValid = false;
    } else {
      this.rangeIsValid = true;
    }

    return this.rangeIsValid;
  }

  private _minDate: Date;
  private _maxDate = this.defaultMax();


  get focusedDate() {
    // 3/24 the kendo component still has bugs when
    // the min->max window falls completely outside of
    // the current month. Setting focused date fixes the
    // rendering.
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const monthStart = new Date(year, month, 1);
    const monthEnd = new Date(year, month + 1, 0);

    if (this.minDate && this.minDate > monthEnd) {
      return this.minDate;
    }

    if (this.maxDate && this.maxDate < monthStart) {
      // default to minDate if there is one since it's nicer
      // to start with it at the beginning of the window
      return this.minDate || this.maxDate;
    }

    return
  }

  defaultMax(): Date {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setFullYear(today.getFullYear() + 5);
    return maxDate;
  }

  public hasError(type: string) {
    return this.formGroup.hasError(type, this.controlName);
  }

  get minErrorMsg() {
    if (this.formGroup.getError('minError', this.controlName)?.value?.getFullYear() < 1000) {
      return MIN_YEAR_MSG;
    }
    return this.errMessages?.min || `Dates before ${this.minDate.toLocaleDateString()} are not allowed.`
  }

  get maxErrorMsg() {
    return this.errMessages?.max || `Dates after ${this.maxDate.toLocaleDateString()} are not allowed.`
  }

  public disableFutureDates = (date: Date): boolean => {
    return date.getTime() >= new Date().getTime();
  }

  public disablePastDates = (date: Date): boolean => {
    return date.getTime() < new Date().getTime();
  }

  public disableFn = (date: Date): boolean => {
    if(this.allowSelectionOfPastDates){
      return this.disableFutureDates(date);
    }else if(this.allowSelectionOfFutureDates){
      return this.disablePastDates(date);
    }else {
      return this.customDisableDate(date);
    }
  }

}
