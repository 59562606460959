import { Component, ElementRef, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { ExpandEvent, SelectionChangeEvent } from '@progress/kendo-angular-treelist';
import { ModalAction, TreeGridColumn, TreeGridItem, TreeGridSelectableSettings, TreeGridSelections, } from '../../shared-interfaces';
import { Observable, of } from 'rxjs';
import { flatMap } from 'lodash';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'prism-tree-grid',
  templateUrl: './tree-grid.component.html',
  styleUrls: ['./tree-grid.component.scss'],
})
export class TreeGridComponent implements OnInit {
  @Input() data;
  @Input() idProperty: string;
  @Input() height: number;
  @Input() useParentHeight = false;
  @Input() gridPadding = 178.5;
  @Input() columns: TreeGridColumn[] = [];
  @Input() selectable: TreeGridSelectableSettings = {
    useCheckboxes: false,
    drag: false
  };
  @Input() selectedItems: TreeGridSelections = {};
  @Input() nameProperty: string;
  @Input() isLoading = false;
  @Input() useParentWidth = false;
  @Input() minSelectItemType: string;
  @Input() itemTypeNameMap: { [itemType: string]: string };
  @Input() loadChildren: (node: TreeGridItem) => Observable<TreeGridItem[]>;
  @Output() rowClick: EventEmitter<TreeGridItem> = new EventEmitter();
  @Output() selectionChange: EventEmitter<TreeGridSelections> =
    new EventEmitter();
  @Output() childrenLoaded: EventEmitter<{ [itemType: string]: boolean }> =
    new EventEmitter();

  public isSelected = this.isNodeSelected.bind(this);

  public get minLevelText(): string {
    if (!this.itemTypeNameMap || !this.minSelectItemType) {
      return '';
    }
    return this.itemTypeNameMap[this.minSelectItemType];
  }

  public get unselectableNodes(): TreeGridItem[] {
    const newUnselectables = this.flatData.filter((item) => {
      const alreadyWarnedForItemType =
        this.alreadyWarnedNodes[item.itemType] ?? [];
      const itemId = parseInt(item.id.toString());
      return !item.selectable && !alreadyWarnedForItemType.includes(itemId);
    });

    return newUnselectables;
  }
  public modalActions: ModalAction[] = [
    {
      text: 'Ok',
      type: 'primary',
      disabled: false,
      callback: () => this.closeModal(),
    },
  ];

  private alreadyWarnedNodes: { [itemType: string]: number[] } = {};

  private modalClosed = false;
  public get showModal(): boolean {
    if (this.modalClosed || this.selectable.readonly) {
      return false;
    }
    return this.unselectableNodes.length > 0;
  }

  private get flatData(): TreeGridItem[] {
    return flatMap(this.data, this.flatten.bind(this));
  }

  private currentChildrenLoading: { [itemType: string]: boolean } = {};

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    if (!this.height) {
      this.setDefaultHeight();
    }
  }

  public closeModal(): void {
    this.unselectableNodes.forEach((node) => {
      const id = parseInt(node.id.toString());
      if (!this.alreadyWarnedNodes[node.itemType]) {
        this.alreadyWarnedNodes[node.itemType] = [id];
      } else if (!this.alreadyWarnedNodes[node.itemType].includes(id)) {
        this.alreadyWarnedNodes[node.itemType].push(id);
      }
    });
    this.modalClosed = true;
  }

  public getItemTypeText(itemType: string): string {
    return this.itemTypeNameMap[itemType];
  }

  public handleRowClick(event: SelectionChangeEvent): void {
    if (this.selectable?.emitRowClickEvent) {
      this.rowClick.emit(event.items[0].dataItem);
      return;
    }

    if (this.selectable.readonly) {
      return;
    }

    let selectionChanged = false;
    this.modalClosed = false;
    for (const item of event.items) {
      const isItemSelected = this.isNodeSelected(item.dataItem);
      if (
        event.action === 'add' ||
        (event.action === 'select' && !isItemSelected)
      ) {
        selectionChanged =
          selectionChanged || this.addToSelection(item.dataItem);
      } else if (
        event.action === 'remove' ||
        (event.action === 'select' && isItemSelected)
      ) {
        selectionChanged =
          selectionChanged || this.removeFromSelection(item.dataItem);
      }
    }

    if (selectionChanged) {
      this.selectionChange.emit(this.selectedItems);
    }
  }

  public getColumnWidth(column: TreeGridColumn): string | number {
    if (column.widthStyle.widthIsPercent) {
      const currentElement = this.elementRef.nativeElement;
      let parentWidth = 0;
      if (this.useParentWidth) {
        parentWidth = currentElement.parentNode.getBoundingClientRect().width;
      } else if (currentElement.closest('.k-drawer-container')) {
        const drawerContainer = currentElement.closest('.k-drawer-container');
        const drawer = drawerContainer.getElementsByClassName('k-drawer')[0];
        parentWidth =
          drawerContainer.getBoundingClientRect().width -
          drawer.getBoundingClientRect().width;
      } else {
        parentWidth = currentElement
          .closest('.layout-container')
          .getBoundingClientRect().width;
      }

      if (this.selectable.multiple) {
        parentWidth = parentWidth - 55;
      }
      return (parentWidth * column.widthStyle.width) / 100;
    }

    return column.widthStyle.width;
  }

  private addToSelection(
    item: TreeGridItem,
    includeChildren: boolean = true
  ): boolean {
    if (this.selectedItems[item.itemType]?.includes(item.id)) {
      return false;
    }

    if (item.itemType !== this.minSelectItemType && !item.selectable) {
      return false;
    }

    if (!Object.keys(this.selectedItems).includes(item.itemType)) {
      this.selectedItems[item.itemType] = [item.id];
    } else {
      this.selectedItems[item.itemType].push(item.id);
    }

    if (item.children.length > 0 && includeChildren) {
      for (const child of item.children) {
        this.addToSelection(child);
      }
    }

    if (item.directParentId) {
      const parent = this.flatData.find(
        (node) =>
          node.itemType === item.itemType && node.id === item.directParentId
      );
      if (parent) {
        this.addToSelection(parent, false);
      }
    }

    return true;
  }

  private removeFromSelection(item: TreeGridItem): boolean {
    if (!this.selectedItems[item.itemType]?.includes(item.id)) {
      return false;
    }

    this.handleFetchChildren(item);
    const idx = this.selectedItems[item.itemType].indexOf(item.id);
    this.selectedItems[item.itemType].splice(idx, 1);
    if (item.hasChildren) {
      for (const child of item.children) {
        this.removeFromSelection(child);
      }
    }

    return true;
  }

  public hasChildren(node: TreeGridItem): boolean {
    return node.hasChildren;
  }

  public handleFetchChildren(
    treeGridItem: TreeGridItem
  ): Observable<TreeGridItem[]> {
    if (treeGridItem.childrenLoaded) {
      return of(treeGridItem.children);
    }

    if (!treeGridItem.hasChildren) {
      return of([]);
    }

    treeGridItem.loadingChildren = true;
    this.currentChildrenLoading[treeGridItem.itemType] = false;
    this.childrenLoaded.emit(this.currentChildrenLoading);

    return this.loadChildren(treeGridItem).pipe(
      tap((values) => {
        treeGridItem.children = values;
        treeGridItem.loadingChildren = false;
        treeGridItem.childrenLoaded = true;
        this.currentChildrenLoading[treeGridItem.itemType] = true;
        if (
          treeGridItem.children.length === 0 ||
          !treeGridItem.children[0].hasChildren
        ) {
          this.childrenLoaded.emit(this.currentChildrenLoading);
        }
      })
    );
  }

  private setDefaultHeight(): void {
    if (this.useParentHeight) {
      this.height = this.elementRef.nativeElement.parentNode.clientHeight;
    } else {
      const layout = this.elementRef.nativeElement.closest('.layout-container');
      if (layout) {
        this.height = layout.clientHeight - (this.gridPadding || 0);
      }
    }
  }

  private isNodeSelected(treeGridItem: TreeGridItem): boolean {
    if (
      Object.keys(this.selectedItems).length === 0 ||
      !Object.keys(this.selectedItems).includes(treeGridItem.itemType)
    ) {
      return false;
    }

    if (this.selectedItems[treeGridItem.itemType].includes(treeGridItem.id)) {
      return true;
    }

    if (
      treeGridItem.children.length &&
      treeGridItem.children.every((child) =>
        this.selectedItems[child.itemType].includes(child.id)
      )
    ) {
      return true;
    }
    // Is parent selected? If so, are other children specified as selected?
    return false;
  }

  private flatten(node: TreeGridItem) {
    if (node.children.length === 0) {
      return node;
    }

    return [node, ...flatMap(node.children, this.flatten.bind(this))];
  }

  /**
   * A function that determines whether a given item is expanded.
   */
  public isExpanded = (dataItem: any): boolean => {
    return dataItem.expanded;
  };

  expandRow($event: ExpandEvent) {
    $event.dataItem.expanded = true;
  }

  collapseRow($event: ExpandEvent) {
    $event.dataItem.expanded = false;
  }
}
