<div class="container-fluid login-container">
  <div class="modal-background"></div>
  <div class="row" *ngIf="isActionEnabled">
    <div class="col-4 offset-4">
      <div class="logo-block">
        <img src="https://infradevminio.z13.web.core.windows.net/logo.png" alt="logo">
      </div>
      <div class="card pwd-card">
        <div class="card-body">
          <div class="form-container pl-3">
            <div class="row">
              <div class="col-lg-12 col-md-12 change-password-wrapper p-4">
                <h5 class="section-header">Password Reset</h5>
                <div class="row pl-2">
                  <div class="col-md-12 mt-1">
                    <div class="align-self-center">
                      <h6 class="section-label">New Password Criteria</h6>
                      <div class="d-flex justify-content-center mt-1 mb-5">
                      <prism-errors class="prism-errors" [control]="changePasswordForm.controls.newPassword">
                        <prism-label-error class="red" error="minlength"> <span class="roboto-reg-gray"> At least 8 characters in length </span> </prism-label-error>
                        <prism-label-error class="roboto-reg-gray" error="numeral"> <span class="roboto-reg-gray"> At least one numeric input </span> </prism-label-error>
                        <prism-label-error class="roboto-reg-gray" error="specialChar"> <span class="roboto-reg-gray"> At least one special character (!, @, #, $, %, ^, &, * ) </span> </prism-label-error>
                        <prism-label-error class="roboto-reg-gray" error="passwordMatch"> <span class="roboto-reg-gray"> New Password and Confirm Password should match </span> </prism-label-error>
                      </prism-errors>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pl-2">
                  <div class="col-md-12">
                    <form *ngIf="changePasswordForm" novalidate [formGroup]="changePasswordForm">
                      <div class="form-row mb-4">
                        <div class="col-sm-12">
                          <label class="roboto-reg-gray">Password</label>
                          <prism-password-input formControlName="newPassword"
                                                placeholder="Type your New Password"></prism-password-input>
                        </div>
                      </div>
                      <div class="form-row mb-4">
                        <div class="col-sm-12">
                          <label class="roboto-reg-gray">Re-Enter Password</label>
                          <prism-password-input formControlName="confirmPassword"
                                                placeholder="Re-type your New Password"></prism-password-input>
                        </div>
                      </div>
                      <div class="mb-3">
                        <prism-button
                                [disabled]="changePasswordForm.invalid"
                                (click)="submit()">Change Password</prism-button>
                        <prism-button class="mt-4"
                                      [type]="'cancel'"
                                      (click)="cancel()">Cancel</prism-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <prism-page-footer></prism-page-footer>
</div>
