<kendo-multiselect
  [(ngModel)]="value"
  [autoClose]="false"
  [filterable]="true"
  [valuePrimitive]="true"
  [placeholder]="placeholder"
  (filterChange)="handleComboFilterChange($event)"
  (open)="preventOpen($event)"
  (blur)="blurHandler($event)"
  (keydown.enter)="enterHandler($event)"
  [disabled]="disabled"
  ngDefaultControl
  ></kendo-multiselect>