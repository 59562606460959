<router-outlet></router-outlet>

<prism-modal title="Warning"
             titleIcon="warning"
             titleIconType="danger"
             [opened]="showPageRefreshWarning"
             [actions]="pageRefreshAction"
             (cancel)="refreshPage()">
    <p class="dialog-text">New version of Prism Portal is available. The page will be refreshed in 5 seconds.</p>
</prism-modal>
