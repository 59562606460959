<input
  type="checkbox"
  #check
  kendoCheckBox
  [disabled]="isDisabled"
  [attr.checked]="value || null"
  [(ngModel)]="value"
  (change)="changeStatus($event)"
/>
<kendo-label
  class="k-checkbox-label"
  [for]="check"
  text="{{label}}"
></kendo-label>