import { Component, Host, OnInit, Optional } from '@angular/core';
import { FormErrorComponent } from '../form-error/form-error.component';
import { FormErrorsComponent } from '../form-errors/form-errors.component';

@Component({
  selector: 'prism-label-error',
  templateUrl: './form-label-error.component.html',
  styleUrls: ['./form-label-error.component.scss']
})
export class FormLabelErrorComponent extends FormErrorComponent {

}
