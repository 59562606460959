/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ShippedDtls { 
    /**
     * date/time when order actually was shipped
     */
    shippedTS: string;
    /**
     * Free text, required if carrier code provided, but not \"Store Personnel\"
     */
    trackingNumber?: string | null;
    /**
     * code from MDM
     */
    carrierCode: string;
    /**
     * description from MDM
     */
    readonly carrierDesc?: string;
    carrierName?: string | null;
    /**
     * code from MDM, forced to enter if current shippedTS > today
     */
    lateShippedReason?: string | null;
    /**
     * description from MDM, forced to enter if current shippedTS > today
     */
    readonly lateShippedReasonDesc?: string;
    isSigRequired: boolean;
    dlvMethodCode?: string | null;
}

