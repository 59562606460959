<div class="chart-wrapper">
  <kendo-chart [style.height]="'100%'" (seriesClick)="seriesClickHandler($event)" >
    <kendo-chart-series>
      <kendo-chart-series-item
        type="donut"
        [data]="data"
        [categoryField]="categoryField"
        [padding]="10"
        [field]="field"
        [autoFit]="true"
        [highlight]="{ opacity: .5 }"
        >
        <kendo-chart-series-item-labels
          [content]="labelContent"
          [position]="'below'"
          color="#000"
          background="none"
          *ngIf="itemLabelVisible"
          >
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="legendVisible"></kendo-chart-legend>
  </kendo-chart>
</div>
