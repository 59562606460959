import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsService } from './breadcrumbs/breadcrumbs.service';
import { SettingsService } from './settings/settings.service';
import { UtilitiesService } from './utilities/utilities.service';
import { DateHelperService } from './date-helper/date-helper.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    BreadcrumbsService,
    SettingsService,
    UtilitiesService,
    DateHelperService
  ]
})
export class SharedServicesModule { }
