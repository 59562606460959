<div class="form-row">
  <div class="col-4 d-flex" *ngFor="let control of formArray.controls; let i = index">
    <div class="flex-grow-1">
      <input kendoTextBox
        class="roboto-reg-gray w-100"
        placeholder="None"
        [formControl]="control">
    </div>
    <button *ngIf="i > 0"
      kendoButton
      (click)="remove(i)"
      [icon]="'close-circle'"
      [look]="'flat'"></button>
  </div>
  <div class="col-3 col-md-3">
    <prism-button
      [icon]="'plus'"
      [type]="'flat'"
      (clicked)="addNew()"
    >Add New</prism-button>
  </div>
</div>
