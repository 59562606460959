import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ElementRef, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { IntlModule, load } from '@progress/kendo-angular-intl';
import { NOTIFICATION_CONTAINER, NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadModule } from '@progress/kendo-angular-upload';
import calendar from 'cldr-data/main/es/ca-gregorian.json';
import currencies from 'cldr-data/main/es/currencies.json';
import dateFields from 'cldr-data/main/es/dateFields.json';
import numbers from 'cldr-data/main/es/numbers.json';
import timeZoneNames from 'cldr-data/main/es/timeZoneNames.json';
import currencyData from 'cldr-data/supplemental/currencyData.json';
import likelySubtags from 'cldr-data/supplemental/likelySubtags.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import { NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import {
  FeeScheduleCatgListConfigurationService
} from './components/fee-schedules/components/fee-sched-shared-components/services/fee-schedule-catg-list-configuration.service';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, SESSION_INSTANCE } from './msal/constants';
import { MsalBroadcastService } from './msal/msal.broadcast.service';
import { MsalGuard } from './msal/msal.guard';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalInterceptor } from './msal/msal.interceptor';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';
import { MsalRedirectComponent } from './msal/msal.redirect.component';
import { MsalService } from './msal/msal.service';
import { NotifyHttpInterceptorInterceptor } from './shared/interceptors/notify-http-interceptor.interceptor';
import { SubmitHttpInterceptor } from './shared/interceptors/submit-http.interceptor';
import { SharedAccessModuleModule } from './shared/shared-access-module/shared-access-module.module';
import { SharedComponentsModule } from './shared/shared-components/shared-components.module';
import { ProfileCheckGuard } from './shared/shared-guard/profile-check/profile-check-guard.service';
import { SharedIconsModule } from './shared/shared-icons/shared-icons.module';
import { DOCUMENT_OBJ, WINDOW_OBJ } from './shared/shared-injection-tokens/shared-injection-tokens';
import { SessionService } from './shared/shared-services/session/session.service';



load(
  likelySubtags,
  weekData,
  currencyData,
  numbers,
  currencies,
  calendar,
  dateFields,
  timeZoneNames
);

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
  console.log(message);
}

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: window.integra.auth.clientId,
      authority: window.integra.auth.authority,
      redirectUri: window.integra.auth.redirectUri,
      knownAuthorities: window.integra.auth.knownAuthorities,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  return {
    scopes: window.integra.auth.tokenScopes,
    interactionType: InteractionType.Redirect,
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    scopes: window.integra.auth.loginScopes,
    resetAuthority: window.integra.auth.resetAuthority,
    interactionType: InteractionType.Redirect
  };
}

function getLang(): string {
  if (navigator.languages !== undefined) {
    return navigator.languages[0];
  }
  else {
    return navigator.language;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    IntlModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ComponentsModule,
    NgxMaskModule.forRoot(),
    NotificationModule,
    TooltipModule,
    UploadModule,
    SharedAccessModuleModule,
    SharedComponentsModule,
    OverlayModule,
    SharedIconsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: SESSION_INSTANCE,
      useClass: SessionService,
      multi: false,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotifyHttpInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubmitHttpInterceptor,
      multi: true,
    },
    {
      provide: NOTIFICATION_CONTAINER,
      useFactory: () => {
        return { nativeElement: document.querySelector('.drawer-content') || document.querySelector('app-root') } as ElementRef;
      }
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: LOCALE_ID,
      useValue: getLang()
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ProfileCheckGuard,
    FeeScheduleCatgListConfigurationService,
    { provide: WINDOW_OBJ, useValue: window },
    { provide: DOCUMENT_OBJ, useValue: window.document }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
