import { Component, Input } from '@angular/core';
import { LoaderSize, LoaderType } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'prism-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  @Input() type: LoaderType = 'infinite-spinner';
  @Input() size: LoaderSize = 'medium';
  @Input() theme: string;

  constructor() { }


}
