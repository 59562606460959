/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListDtlAttrWithName } from './listDtlAttrWithName';


export interface CodeLookupDtl { 
    listDtlDesc?: string;
    listDtlStartDt?: string;
    listDtlEndDt?: string | null;
    listDtlAttrs?: Array<ListDtlAttrWithName>;
    active?: boolean;
}

