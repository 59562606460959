/**
 * Access Control Management
 * Access Control Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserProfileRequest { 
    isTermsAccepted?: boolean | null;
    oldPwd?: string | null;
    newPwd?: string | null;
    confirmNewPwd?: string | null;
}

