import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GridColumn, GridPageChangeEvent } from '../../shared-interfaces';
import { PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
import { GroupDescriptor, process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'prism-grouped-grid',
  templateUrl: './grouped-grid.component.html',
  styleUrls: ['./grouped-grid.component.scss']
})
export class GroupedGridComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() columns: GridColumn[];

  @Input() useParentHeight = false;
  @Input() height: number;
  @Input() gridPadding = 275;
  @Input() pageable = false;
  @Input() pageSize: number;
  @Input() skip = 0;
  @Input() pageSizes: PageSizeItem[] = [
    {text: '25', value: 25},
    {text: '50', value: 50},
    {text: '100', value: 100}
  ];
  @Output() pageChange: EventEmitter<GridPageChangeEvent> = new EventEmitter();

  public gridView: any;
  public groups: GroupDescriptor[] = [];
  public state: State = {
    group: [] as GroupDescriptor[]
  } as State;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.getDefaultGridHeight();
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.loadGridData();
  }

  // the dataStateChange to manipulate(group/sort) the data in a single event
  public dataStateChange(state: State) : void {
    this.state = state;
    this.loadGridData();
  }

  // the process() method of the Data Query package is to apply the state (multiple descriptors).
  private loadGridData(): void {
    if (this.pageable) {
      const processedData = process(this.data.data, this.state)
      this.gridView.data = processedData.data
      this.gridView.total = this.data.take
    } else {
      this.gridView = process(this.data, this.state)
    }
  }

  getHeaderStyle(columnStyle: {[key: string]: string}): {[key: string]: string} {
    return {
      ...columnStyle,
      'background-color': '#F6F6F6',
      'text-transform': 'uppercase'
    }
  }

  getStyle(columnStyle: {[key: string]: string}): {[key: string]: string} {
    if (columnStyle?.bold) {
      if (columnStyle.bold === 'true') {
        columnStyle = {
          ...columnStyle,
          'font-weight': '700'
        }
        delete columnStyle.bold;
      }
    }
    return columnStyle;
  }

  private getDefaultGridHeight(): void {
    let layout;
    if (this.useParentHeight) {
      layout = this.elementRef.nativeElement.parentNode;
      this.height = (layout.clientHeight);
    } else {
      layout = this.elementRef.nativeElement.closest('.layout-container');
      if (layout) {
        this.height = (layout.clientHeight - this.gridPadding);
      }
    }
  }

  loadMore(event: PageChangeEvent): void {
    this.pageSize = event.take;
    this.pageChange.emit({skip: event.skip, take: event.take});
  }

  // look for data changes and apply the stateChange to make sure we have grouping and sorting
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.gridView = { ...changes.data.currentValue };
      this.loadGridData();
    }
  }
}
