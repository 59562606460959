import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'prism-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillComponent {
  statusClass: string;
  public statusDisplay = false;

  private _status: string;

  @Input()
  set status(status: string) {
    this._status = status;
    this.statusDisplay = status !== '';
    this.statusClass = this.statusDisplay
      ? status.toLowerCase().trim().replace(/ /g, '_')
      : '';
  }

  get status(): string {
    return this._status;
  }
}
