import { Component, Input } from '@angular/core';
import { IndvAddressesShared } from '../../shared-interfaces/shared-interfaces';

@Component({
  selector: 'prism-delete-address',
  templateUrl: './delete-address.component.html',
  styleUrls: ['./delete-address.component.scss']
})
export class DeleteAddressComponent {
  @Input() data: IndvAddressesShared;
  constructor() { }

}
