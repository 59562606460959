import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBoxComponent as ComboBox } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject, noop } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ComboboxFilterEvent, DropDownPageEvent } from '../../shared-interfaces';
import { DropDownPagingUtil } from '../dropdown-paging-utils';


export const COMBOBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ComboBoxComponent),
  multi: true
};

@Component({
  selector: 'prism-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
  providers: [COMBOBOX_CONTROL_VALUE_ACCESSOR]
})
export class ComboBoxComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
  @Input() data: Array<any> = [];
  @Input() placeholder = 'Type to search';
  @Input() selectedValue: any;
  @Input() valuePrimitive = false;
  @Input() minRequiredChars = 0;
  @Input() valueField = 'id';
  @Input() textField = 'displayName';
  @Input() searchField = 'searchName';
  @Input() filterable = true;
  @Input() isLoading: boolean;
  @Input() allowCustom = false;
  @Input() width = '';
  @Input() disabled: boolean;
  @Input() paging = false;
  @Input() pageSize = 0;

  @Input() set allowLoadMore(value: boolean) {
    if (!!this.pagingUtil) {
      this.pagingUtil.allowLoadMore = value;
    }
  }

  @Output() searchFilterChange: EventEmitter<ComboboxFilterEvent> = new EventEmitter<ComboboxFilterEvent>();
  @Output() searchSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadNextPage: EventEmitter<DropDownPageEvent> = new EventEmitter<DropDownPageEvent>();
  @ViewChild('combo') comboBoxComponent: ComboBox;

  filterStrSubject: BehaviorSubject<{filter: string, isSelectFilter: boolean, isfilterChange?: boolean}> = new BehaviorSubject({filter: '', isSelectFilter: false});
  innerValue;
  filterStr = '';
  pagingUtil = new DropDownPagingUtil();
  onTouchedCallback: () => void = noop;
  onChangeCallback: (_: any) => void = noop;

  get value(): any {
    return this.innerValue;
  }

  set value(obj: any) {
    this.writeValue(obj);
  }

  writeValue(obj: any): void {
    if (this.innerValue !== obj) {
      this.innerValue = obj;
      this.onChangeCallback(obj);
      this.searchSelectionChange.emit(obj);
      if (obj?.searchName) {
        this.filterStrSubject.next({filter: obj.searchName, isSelectFilter: true});
      }
      // if obj is falsy we should make sure the filter string is empty too
      else if (!obj) {
        this.filterStrSubject.next({filter: '', isSelectFilter: false});
      }
    }
  }

  ngOnInit(): void {
    if (this.paging) {
      this.pagingUtil.pageSize = this.pageSize;
      this.pagingUtil.onScrollCallback = this.handleNextPage.bind(this);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValue) {
      this.writeValue(changes.selectedValue.currentValue);
    }

    if (changes.data) {
      this.data = changes.data.currentValue || [];
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  ngAfterViewInit(): void {
    this.filterStrSubject.asObservable().pipe(
      // if we have a value don't let the child kendo component
      // cause the filter to get cleared so the user can still
      // scroll through the results of the last filter.
      filter(filter => {
        return (filter.isfilterChange && filter.filter === '' && this.value && this.data.length) ? false : true;
      }),
      distinctUntilChanged((x, y) => x.filter === y.filter),
      debounceTime(1000),
      filter(filter => filter.filter.length >= this.minRequiredChars || filter.filter === ''),
    ).subscribe((filter) => {
      this.filterStr = filter.filter;
      this.pagingUtil.resetPageNumber();
      this.searchFilterChange.emit({
        filter: filter.filter,
        isSelectFilter: filter.isSelectFilter
      });
    });
  }

  public expandDropdown(): void {
    if (this.paging) {
      this.pagingUtil.addScrollListener();
    }

    if (this.data.length === 0 && this.filterStr === '') {
      this.handleNextPage();
    }
  }

  public handleNextPage(): void {
    this.loadNextPage.emit({
      filter: this.filterStr,
      pageNumber: this.pagingUtil.pageNumber,
      restartRowNumber: this.pagingUtil.restartRowNumber,
      pageSize: this.pagingUtil.pageSize
    });
  }

  public resetFilter(): void {
    this.writeValue(undefined);
  }

  public handleSelection($event: any): void {
    this.writeValue($event);
  }

  public toggle(isOpen: boolean): void {
    this.comboBoxComponent?.toggle(isOpen);
  }

  public handleFilter($event): void {
    // send this during the next tick in case it is caused by a selection
    // being made. For that case we want to let the handleSeletion callback
    // run first.
    setTimeout(
      () => this.filterStrSubject.next({
        filter: $event,
        isSelectFilter: false,
        isfilterChange: true,
      }),
      0);
  }

  public handleFocus(): void {
    this.toggle(true);
  }

  public handleBlur(): void {
    this.toggle(false);
  }
}
