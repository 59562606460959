<div class="row">
  <h5 class="d-flex align-items-center roboto-reg-gray col-sm-3">
    <prism-button type="button" icon="menu" (clicked)="onMenuButtonClick()" type="secondary"></prism-button>
    <span>{{title}}</span>
  </h5>
  <ng-container [ngTemplateOutlet]="additionalHeaderContent?.templateRef"></ng-container>
  <kendo-splitter [orientation]="orientation" class="splitter-container">
    <kendo-splitter-pane [class]="drawerContent?.class"
                         [collapsed]="!expanded"
                         [resizable]="drawerContent?.resizable"
                         [collapsible]="drawerContent?.collapsible"
                         [size]="drawerContent?.size">
      <ng-container [ngTemplateOutlet]="drawerContent?.templateRef"></ng-container>
    </kendo-splitter-pane>
    <kendo-splitter-pane>
      <ng-template [ngTemplateOutlet]="mainContent?.templateRef"></ng-template>
    </kendo-splitter-pane>
  </kendo-splitter>
</div>
