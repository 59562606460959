/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Activity { 
    readonly id: string;
    readonly domainName: string;
    readonly domainKey: number;
    actionMsg: string;
    actionMsgNum: number;
    actionType: string;
    actionStatus: string;
    actionMeta: object;
    actionTS: string;
    actionBy: string;
    readonly receivedTS: string;
}

