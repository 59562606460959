<div class="pwd-footer container-fluid">
    <div class="row ">
        <div class="col-6 offset-3">
            <div class=" d-flex justify-content-around footer-texts ">
                <div class="copyright ">
                    <span>COPYRIGHT © 2021 -</span>
                    <span id="copyright-end-date">
                <script>document.getElementById('copyright-end-date').appendChild(document.createTextNode(new Date().getFullYear()))</script>
            </span>
                    <span style="padding-right: 25px">INTEGRA PARTNERS LLC</span>
                    <span style="padding-right: 25px;">
								<a target="_blank"
                   href="https://infradevminio.z13.web.core.windows.net/prismPrivacyPolicy.html"
                   rel="noopener">Privacy Policy</a>
							</span>
                    <span style="padding-right: 25px;">
								<a target="_blank"
                   href="https://infradevminio.z13.web.core.windows.net/prismTerms.html"
                   rel="noopener">Terms & Conditions</a>
							</span>
                </div>
            </div>
        </div>
    </div>
</div>
