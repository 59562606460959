/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Transition { 
    transitionId?: number;
    ownerId?: number | null;
    readonly ownerName?: string;
    transitionDesc: string;
    statusCode: string;
    readonly isActive?: boolean;
    startDate: string;
    endDate?: string;
    readonly createdTs?: string;
    readonly createdBy?: string;
    readonly modifiedTs?: string;
    readonly modifiedBy?: string;
}

