/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BusinessHrs { 
    day?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    isByAppointment?: boolean;
    isClose?: boolean;
    isOpen24Hours?: boolean;
}

