import { Inject, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, UrlTree } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG } from '../../../msal/constants';
import { MsalGuardConfiguration } from '../../../msal/msal.guard.config';
import { MsalService } from '../../../msal/msal.service';
import { MyProfileService } from '../../shared-services/my-profile/my-profile.service';
import { UtilitiesService } from '../../shared-services/utilities/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileCheckGuard {

  public open: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isProfileChecked = false; // make sure we only check profile once

  private route;
  constructor(
    private myProfileService: MyProfileService,
    private router: Router,
    private utilitiesService: UtilitiesService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
  ) { }

  public canActivateChild = (route: ActivatedRouteSnapshot)
    : Promise<boolean | UrlTree> => {
    this.route = route;
    return new Promise((resolve => {
      if (this.route.routeConfig.path.includes('#state') || this.isProfileChecked) {
        return resolve(true);
      }
      this.myProfileService.getMyProfile()
        .pipe(take(1))
        .subscribe((userProfile) => {
          if (userProfile.isPwdResetRequired === true) {
            if (userProfile.userType === 'PAYER') {
              this.router.navigate(['/auth/changepwd'], { queryParams: { redirect: this.getResolvedUrl(route) } });
            } else if (userProfile.userType === 'PROVIDER') {
              this.utilitiesService.navigateToRoute(window.integra.dori.resetPwdUrl);
            } else {
              this.router.navigate([`/auth-error/passwordReset`]);
            }
            resolve(false);
          } else if (userProfile.isTermsAccepted === false) {
            this.router.navigate(['/auth/tc'], { queryParams: { redirect: this.getResolvedUrl(route) } });
            resolve(false);
          } else {
            this.isProfileChecked = true;
            resolve(true);
          }
        });
    }));
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    let url = route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
    const queryParam = route.queryParamMap;
    if (queryParam.keys.length > 0) {
      url += '?' + queryParam.keys.map(key => queryParam.getAll(key).map(value => key + '=' + value).join('&')).join('&');
    }

    return url;
  }

  logout(route: string): void {
    this.authService.logout({
      ...this.msalGuardConfig.authRequest,
      postLogoutRedirectUri: `${window.integra.auth.redirectUri}/${route}`,
    });
  }
}

export const profileCheckGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot) => {
  return inject(ProfileCheckGuard).canActivateChild(route);
}
