import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {AccessControlService} from '../../services/access-control.service';
import {AccessInfo} from '../../openapi';

@Directive({
  selector: '[prismMenuAccess]'
})
export class MenuAccessDirective implements OnInit {
  @Input() widgetId: string;
  @Input() origin: string;
  private accessToWidget: Array<AccessInfo.ActionsEnum>;

  constructor(private accessControlService: AccessControlService,
              private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.accessToWidget = this.accessControlService.getAccessForWidget(this.widgetId, this.origin);
    this.enforceAccess();
  }

  private enforceAccess = (): void => {
    if (!this.accessToWidget.includes(AccessInfo.ActionsEnum.Visible)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'menu-item-hidden');
    }
  }

}
