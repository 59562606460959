import { Injectable } from '@angular/core';
import { RoleItem, RoleItemList } from 'src/app/shared/shared-access-module/openapi';
import { LookUpListItemDtl } from '../../../shared/shared-interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserRolesUtilitiesService {

  constructor() { }

  static roleToLookupItem(role: RoleItemList): LookUpListItemDtl {
    return {
      listDtlCode: role.roleName,
      listDtlDesc: role.roleDesc,
      listDtlStartDt: null,
      isReadOnly: role.isReadOnlyRole
    } as LookUpListItemDtl;
  }


  static mapRolesToLookupItems(roles: RoleItemList[]): LookUpListItemDtl[] {
    return roles.map(UserRolesUtilitiesService.roleToLookupItem);
  }
  static mapFilteredRolesToLookupItems(roles: RoleItemList[]): LookUpListItemDtl[] {
    return roles.map(UserRolesUtilitiesService.roleToLookupItem).filter(role => role.listDtlCode !== 'RRU');
  }
  static lookupItemToRole(item: LookUpListItemDtl): RoleItemList {
    return {
      roleName: item.listDtlCode
    } as RoleItem;
  }

  static mapLookupItemsToRoles(items: LookUpListItemDtl[]): RoleItemList[] {
    return items.map(UserRolesUtilitiesService.lookupItemToRole);
  }

  static rolesDiff(oldRoles: RoleItem[], newRoles: RoleItem[]): RoleItem[] {
    return !Array.isArray(newRoles) ? [] :
      (Array.isArray(oldRoles) ?
        newRoles.filter(role => !oldRoles.some(oldRole => oldRole.roleName === role.roleName)) :
        []
      );
  }
}
