import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuAccessDirective} from './directives/menu/menu-access.directive';
import {SimpleAccessDirective} from './directives/simple/simple-access.directive';
import {AccessControlService} from './services/access-control.service';


@NgModule({
  declarations: [MenuAccessDirective, SimpleAccessDirective],
  imports: [
    CommonModule
  ],
  exports: [
    MenuAccessDirective,
    SimpleAccessDirective,
  ],
})
export class SharedAccessModuleModule {
  static forRoot(): ModuleWithProviders<SharedAccessModuleModule> {
    return {
      ngModule: SharedAccessModuleModule,
      providers: [
        AccessControlService
      ]
    };
  }

}
