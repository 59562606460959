import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CodeSelection, GridColumn, PagingConfig } from '../../shared-interfaces';

@Component({
  selector: 'prism-grid-with-multiselect-search',
  templateUrl: './grid-with-multiselect-search.component.html',
  styleUrls: ['./grid-with-multiselect-search.component.scss']
})
export class GridWithMultiselectSearchComponent implements OnChanges, OnInit {
  @Input()
  public searchPlaceHolderText = 'Search by code or description';

  public searchList: CodeSelection[] = [];
  @Input() public selectedCodeList: CodeSelection[] = [];
  @Input() pagingConfig: PagingConfig;
  @Output() public searchStringChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public selectedListUpdated: EventEmitter<CodeSelection[]> = new EventEmitter<CodeSelection[]>();
  @Output() public checkBoxSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set dispCheckBox(checkBoxDtls: any) {
    if (checkBoxDtls !== this.dispCheckBoxValue) {
      this.dispCheckBoxValue = checkBoxDtls;
      this.isHeaderCheckboxChecked = checkBoxDtls ? checkBoxDtls.value : false;
    }
  }

  get dispCheckBox(): any {
    return this.dispCheckBoxValue;
  }

  @Input()
  public searchColumns: GridColumn[] = [
    { field: 'code', title: 'Code', widthStyle: { width: '20%', bold: 'true' }, type: 'text' },
    { field: 'description', title: 'Description', widthStyle: { width: '65%' }, type: 'text' }
  ];

  @Input()
  public displayColumns = [
    {
      title: 'CODE',
      field: 'code',
      width: 150,
      style: { 'font-weight': '700' },
      headerStyle: { 'background-color': '#F6F6F6' }
    },
    {
      title: 'DESCRIPTION',
      field: 'description',
      headerStyle: { 'background-color': '#F6F6F6' }
    }
  ];

  @Input()
  public disabled = false;
  public disableRemoveAll = false;

  private filterStr = '';

  public searchRowActions: any[] = [{
    icon: 'plus',
    type: 'primary',
    callback: (event) => this.addItems(event)
  }];

  public isHeaderCheckboxChecked = false;
  public dispCheckBoxValue: any;

  private pageNumber = 0;

  private get pageIndicator(): number {
    return this.pageNumber * (this.pagingConfig.usePageNumberInCallback ? 1 : this.pagingConfig.pageSize);
  }

  private allowLoadMore = true;

  constructor() {
    this.setHeaderCheckBoxValue();
  }

  nextPage(): void {
    if (!this.allowLoadMore) {
      return;
    }

    this.pageNumber++;
    this.executePagingCallback(this.filterStr);
  }

  private executePagingCallback(filter: string = ''): void {
    this.pagingConfig.onPageCallback(filter,
      this.pageIndicator,
      this.pagingConfig.pageSize
    ).subscribe(data => {
      if (filter !== this.filterStr || this.searchList.length === 0) {
        this.searchList = data;
        this.filterStr = filter;
      } else if (data.length > 0 && this.searchList[0].code !== data[0].code) {
        this.searchList.push(...data);
      }
      this.allowLoadMore = data?.length > 0;
    })
  }

  ngOnInit(): void {
    if (this.pagingConfig) {
      this.executePagingCallback('');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.evaluateState();
  }

  evaluateState(): void {
    this.disableRemoveAll = this.selectedCodeList?.length === 0;
  }

  public addItems(event: CodeSelection): void {
    for (const item of this.selectedCodeList) {
      if (item.code === event.code) {
        return;
      }
    }
    this.selectedCodeList.forEach(code => {
    });

    this.selectedCodeList.push(event);
    this.update();
  }

  searchChanged($event: string): void {
    this.pageNumber = 0;
    this.executePagingCallback($event);
  }

  removeItems($event: any): void {
    this.selectedCodeList.splice($event, 1);
    this.update();
  }

  removeAllItems(): void {
    this.selectedCodeList = [];
    this.update();
  }

  private update(): void {
    this.evaluateState();
    this.selectedListUpdated.emit(this.selectedCodeList);
  }

  public onHeaderCheckboxChange(event): void {
    this.checkBoxSelected.emit(event.target.checked);
  }

  private setHeaderCheckBoxValue(): void {
    this.isHeaderCheckboxChecked = this.dispCheckBoxValue ? this.dispCheckBoxValue.value : false;
  }
}
