<ng-container [formGroup]="formGroup">
  <kendo-datepicker class="fullWidth"
                    [navigation]="false"
                    [min]="minDate"
                    [max]="maxDate"
                    [focusedDate]="focusedDate"
                    placeholder="mm/dd/yyyy"
                    [formControlName]="controlName"
                    [disabledDates]="disableFn"
                    ></kendo-datepicker>
  <div [ngClass]="{'d-block': (formGroup.get(controlName).dirty || formGroup.get(controlName).touched )}"
       class="col-12 invalid-feedback">
    <span *ngIf="hasError('required')">Required field is missing.</span>
    <span *ngIf="hasError('minError')">{{minErrorMsg}}</span>
    <span *ngIf="hasError('maxError')">{{maxErrorMsg}}</span>
  </div>
</ng-container>
