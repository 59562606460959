import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalAction } from 'src/app/shared/shared-interfaces/shared-interfaces';

@Component({
  selector: 'prism-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() titleIconType = '';
  @Input() blurBackground = false;
  @Input() opened: boolean;
  @Input() width = 505;
  @Input() actions: ModalAction[];
  @Input() actionsAlign: 'end' | 'between' | 'start' = 'end';
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  constructor() { }

}
