import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutsideClickDirective } from './outside-click/outside-click.directive';
import { DrawerHeaderContentDirective } from './drawer-header-content/drawer-header-content.directive';
import { DrawerAsideContentDirective } from './drawer-aside-content/drawer-aside-content.directive';
import { DrawerMainContentDirective } from './drawer-main-content/drawer-main-content.directive';

@NgModule({
  declarations: [
    OutsideClickDirective,
    DrawerHeaderContentDirective,
    DrawerAsideContentDirective,
    DrawerMainContentDirective,
  ],
  imports: [CommonModule],
  exports: [
    OutsideClickDirective,
    DrawerHeaderContentDirective,
    DrawerAsideContentDirective,
    DrawerMainContentDirective,
  ],
})
export class SharedDirectivesModule {}
