/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItemModifier } from './orderItemModifier';
import { OrderItemHcpc } from './orderItemHcpc';


export interface OrderItem { 
    orderItemId?: number;
    orderId?: number;
    altLineId?: string | null;
    lineNum: number;
    productId?: number | null;
    productQty: number;
    readonly productDesc?: string;
    productHcpcCode?: string | null;
    orderItemComment: string;
    icd10Code?: string | null;
    schedFreqCode?: string | null;
    authCode?: string | null;
    authWinFromDt?: string | null;
    authWinToDt?: string | null;
    modifiers?: Array<OrderItemModifier>;
    hcpcs?: Array<OrderItemHcpc>;
    transPrevSvcDt?: string | null;
    transPrevSvcProvider?: string | null;
    readonly hcpcTransInd?: string | null;
    transPriority?: number | null;
    legacyAuthCode?: string | null;
}

