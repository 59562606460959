import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe'
})
export class SearchPipe implements PipeTransform {

  transform(data: any[], args: string[]): any[] {
    if (!args[0]) {
      return data;
    }
    const filterWord = args[1].toLowerCase();
    const filtered = data.filter((item) => {
      const y = item[args[0]].toLowerCase();
      return y.includes(filterWord);
    });
    return filtered.length > 0 ? filtered : data;
  }

}
