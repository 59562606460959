import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  /* eslint "@angular-eslint/directive-selector" : "off" */
  selector: '[outsideClick]'
})
export class OutsideClickDirective {

  @Output()
  outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.outsideClick.emit(event);
    }
  }
}
