import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessResolveRequest } from 'src/app/shared/shared-interfaces/shared-interfaces';
import { SettingsService } from 'src/app/shared/shared-services/settings/settings.service';
import { AccessInfo, AccessManagementService } from '../openapi';

export interface PermissibleItem {
  widgetId?: string;
  actions?: Array<AccessInfo.ActionsEnum>;
}

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  private widgets: Map<string, Map<string, Array<AccessInfo.ActionsEnum>>> =
    new Map<string, Map<string, Array<AccessInfo.ActionsEnum>>>();
  private routes: Map<string, Array<AccessInfo.ActionsEnum>> = new Map<string, Array<AccessInfo.ActionsEnum>>();
  private defaultAccess: Array<AccessInfo.ActionsEnum> = []; // default, deny-access
  private accessMap: Map<string, Array<string>> = new Map<string, Array<string>>();

  constructor(
    private settingsService: SettingsService,
    private accessManagementService: AccessManagementService
  ) {
    this.accessManagementService.configuration.basePath = `${this.settingsService.basePath}/ss/acm`;
    this.accessManagementService.configuration.withCredentials = true;
    this.accessMap.set('widgets', []);
    this.accessMap.set('routes', []);
  }

  public loadAccess = (accessRequest: AccessResolveRequest): Observable<boolean> => {
    const accessObs = [];
    if (accessRequest.loadRoutes) {
      accessObs.push(this.getAccessToRoutes(accessRequest.originService));
    }
    if (accessRequest.loadWidgets) {
      accessObs.push(this.getAccessToWidgets(accessRequest.originService));
    }
    return forkJoin(accessObs).pipe(
      map(() => true)
    );
  }

  private getAccessToWidgets = (serviceName: string): Observable<boolean> => {
    if (this.accessMap.get('widgets').includes(serviceName)) {
      return of(true);
    } else {
      this.accessMap.get('widgets').push(serviceName);
      return this.getAccessFromService(serviceName, 'widgets');
    }
  }

  private getAccessToRoutes = (serviceName: string): Observable<boolean> => {
    if (this.accessMap.get('routes').includes(serviceName)) {
      return of(true);
    } else {
      this.accessMap.get('routes').push(serviceName);
      return this.getAccessFromService(serviceName, 'routes');
    }
  }

  private getAccessFromService = (serviceName: string, destination: string): Observable<boolean> => {
    return this.accessManagementService.getAccessDtls(serviceName, destination).pipe(
      map((listOfAccess) => {
        if (!listOfAccess || listOfAccess.length === 0) {
          return false;
        } else {
          if (destination === 'routes') {
          listOfAccess.forEach(routes => {
            this.routes.set(routes.route, routes.actions);
          });
        } else if (destination === 'widgets') {
          const accessMap: Map<string, Array<AccessInfo.ActionsEnum>> = new Map();
          listOfAccess.forEach(widget => {
            accessMap.set(widget.widget, widget.actions);
          });
          this.widgets.set(serviceName, accessMap);
        }
        return true;
      }})
    );
  }

  public getWidgetsAndRoutes = (serviceName: string, accessType: string,
                                statusCode: string = null, sysStatusCode: string = null): Observable<Array<AccessInfo>> => {
    const accessReqParams: any = {};
    if (statusCode !== null) {
      accessReqParams.status = statusCode;
    }
    if (sysStatusCode) {
      accessReqParams.sysStatus = sysStatusCode;
    }
    return this.accessManagementService.getAccessWithAttrDtls(serviceName, accessType, accessReqParams);
  }

  public getAccessForWidget = (widgetId: string, origin: string): Array<AccessInfo.ActionsEnum> => {
    if (this.widgets.has(origin) && this.widgets.get(origin).has(widgetId)) {
      return this.widgets.get(origin).get(widgetId);
    } else {
      return this.defaultAccess;
    }
  }

  public addPermission = (items: Array<PermissibleItem>, isRoute: boolean, origin?: string): void => {
    items.forEach(item => {
      if (item.widgetId !== '') {
        item.actions = isRoute ? this.getAccessForRoute(item.widgetId) : this.getAccessForWidget(item.widgetId, origin);
      }
    });
  } 

  public getAccessForRoute = (route: string): Array<AccessInfo.ActionsEnum> => {
    if (this.routes.has(route)) {
      return this.routes.get(route);
    } else {
      return this.defaultAccess;
    }
  }
}
