<ng-container *ngIf="showError; else checkIcon">
  <span class="red">
    <span class="k-icon k-font-icon k-i-close-circle"></span>
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </span>
</ng-container>

<ng-template #checkIcon>
  <span [ngClass]="{'black': isEmptyInputValue, 'green': !isEmptyInputValue && !showError}">
    <span class="k-icon k-font-icon k-i-check-circle"></span>
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </span>
</ng-template>

<ng-template #label>
  <ng-content></ng-content>
</ng-template>