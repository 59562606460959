import {
  HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SubmitService } from '../shared-services/submit/submit.service';

@Injectable()
export class SubmitHttpInterceptor implements HttpInterceptor {

  protected count = 0;

  constructor(
    private submitService: SubmitService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.shouldIntercept(request) ? next.handle(request).pipe(
      finalize(() => this.decrementCount()),
      tap((value) => {
        if (value.type === HttpEventType.Sent) {
          this.incrementCount();
        }
      })
    ) : next.handle(request);
  }

  protected shouldIntercept(request: HttpRequest<unknown>): boolean {
    const method = request.method.toLowerCase();
    return method === 'put' || method === 'post' || method === 'delete';
  }

  protected incrementCount(): void {
    this.count++;
    this.submitService.toggleBusy(true);
  }

  protected decrementCount(): void {
    this.count = this.count === 0 ? 0 : this.count - 1;
    this.submitService.toggleBusy(this.count > 0);
  }
}
