<kendo-multiselect
  [data]="filteredData"
  [(ngModel)]="model"
  textField="displaySelected"
  valueField="id"
  [autoClose]="false"
  [filterable]="filterable"
  [placeholder]="placeholder"
  (open)="onMultiSelectClick()"
  (filterChange)="handleComboFilterChange($event)"
  [disabled]="disabled"
  (valueChange)="valueHasChanged()"
  (opened)="expandDropdown()"
  ngDefaultControl
  [checkboxes]="true"
  >
  <ng-template kendoMultiSelectItemTemplate let-dataItem>
    <span [ngClass]="{'selected': isItemSelected(dataItem)}">{{dataItem.displayName}}</span>
  </ng-template>
</kendo-multiselect>
