/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Action } from '../model/action';
// @ts-ignore
import { BulkAddressImport } from '../model/bulkAddressImport';
// @ts-ignore
import { Change } from '../model/change';
// @ts-ignore
import { Errors } from '../model/errors';
// @ts-ignore
import { PayerLob } from '../model/payerLob';
// @ts-ignore
import { PayerLobDetails } from '../model/payerLobDetails';
// @ts-ignore
import { PayerLobList } from '../model/payerLobList';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PayerLOBService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create new payer LOB
     * @param payerFamId Payer family id to add new LOB
     * @param payerPlanId Payer Plan Id
     * @param payerAcctId Payer account id to add new LOB
     * @param body Payer LOB object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, body: PayerLob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PayerLob>;
    public createPayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, body: PayerLob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PayerLob>>;
    public createPayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, body: PayerLob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PayerLob>>;
    public createPayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, body: PayerLob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (payerFamId === null || payerFamId === undefined) {
            throw new Error('Required parameter payerFamId was null or undefined when calling createPayerLob.');
        }
        if (payerPlanId === null || payerPlanId === undefined) {
            throw new Error('Required parameter payerPlanId was null or undefined when calling createPayerLob.');
        }
        if (payerAcctId === null || payerAcctId === undefined) {
            throw new Error('Required parameter payerAcctId was null or undefined when calling createPayerLob.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createPayerLob.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/families/${this.configuration.encodeParam({name: "payerFamId", value: payerFamId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/plans/${this.configuration.encodeParam({name: "payerPlanId", value: payerPlanId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/accounts/${this.configuration.encodeParam({name: "payerAcctId", value: payerAcctId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lobs`;
        return this.httpClient.request<PayerLob>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Payer LOBs Direct
     * @param sort 
     * @param filter 
     * @param restartRowId 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPayerLobs(sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<PayerLobList>>;
    public getAllPayerLobs(sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<PayerLobList>>>;
    public getAllPayerLobs(sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<PayerLobList>>>;
    public getAllPayerLobs(sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;
        if (sort !== undefined && sort !== null) {
            localVarHeaders = localVarHeaders.set('Sort', String(sort));
        }
        if (filter !== undefined && filter !== null) {
            localVarHeaders = localVarHeaders.set('Filter', String(filter));
        }
        if (restartRowId !== undefined && restartRowId !== null) {
            localVarHeaders = localVarHeaders.set('Restart-Row-Id', String(restartRowId));
        }
        if (pageSize !== undefined && pageSize !== null) {
            localVarHeaders = localVarHeaders.set('Page-Size', String(pageSize));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/lobs`;
        return this.httpClient.request<Array<PayerLobList>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Payer LOB activity
     * @param payerFamId Payer Family Id
     * @param payerPlanId Payer Plan Id
     * @param payerAcctId Payer Account Id
     * @param payerLobId Payer Lob Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayerLobActivityByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Action>>;
    public getPayerLobActivityByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Action>>>;
    public getPayerLobActivityByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Action>>>;
    public getPayerLobActivityByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (payerFamId === null || payerFamId === undefined) {
            throw new Error('Required parameter payerFamId was null or undefined when calling getPayerLobActivityByID.');
        }
        if (payerPlanId === null || payerPlanId === undefined) {
            throw new Error('Required parameter payerPlanId was null or undefined when calling getPayerLobActivityByID.');
        }
        if (payerAcctId === null || payerAcctId === undefined) {
            throw new Error('Required parameter payerAcctId was null or undefined when calling getPayerLobActivityByID.');
        }
        if (payerLobId === null || payerLobId === undefined) {
            throw new Error('Required parameter payerLobId was null or undefined when calling getPayerLobActivityByID.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/families/${this.configuration.encodeParam({name: "payerFamId", value: payerFamId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/plans/${this.configuration.encodeParam({name: "payerPlanId", value: payerPlanId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/accounts/${this.configuration.encodeParam({name: "payerAcctId", value: payerAcctId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lobs/${this.configuration.encodeParam({name: "payerLobId", value: payerLobId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activity`;
        return this.httpClient.request<Array<Action>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Payer LOB information
     * @param payerFamId Payer Family Id
     * @param payerPlanId Payer Plan Id
     * @param payerAcctId Payer Account Id
     * @param payerLobId Payer Lob Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayerLobByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PayerLobDetails>;
    public getPayerLobByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PayerLobDetails>>;
    public getPayerLobByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PayerLobDetails>>;
    public getPayerLobByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (payerFamId === null || payerFamId === undefined) {
            throw new Error('Required parameter payerFamId was null or undefined when calling getPayerLobByID.');
        }
        if (payerPlanId === null || payerPlanId === undefined) {
            throw new Error('Required parameter payerPlanId was null or undefined when calling getPayerLobByID.');
        }
        if (payerAcctId === null || payerAcctId === undefined) {
            throw new Error('Required parameter payerAcctId was null or undefined when calling getPayerLobByID.');
        }
        if (payerLobId === null || payerLobId === undefined) {
            throw new Error('Required parameter payerLobId was null or undefined when calling getPayerLobByID.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/families/${this.configuration.encodeParam({name: "payerFamId", value: payerFamId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/plans/${this.configuration.encodeParam({name: "payerPlanId", value: payerPlanId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/accounts/${this.configuration.encodeParam({name: "payerAcctId", value: payerAcctId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lobs/${this.configuration.encodeParam({name: "payerLobId", value: payerLobId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<PayerLobDetails>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Payer LOB information
     * @param payerLobId Payer Lob Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayerLobByLobID(payerLobId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PayerLobDetails>;
    public getPayerLobByLobID(payerLobId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PayerLobDetails>>;
    public getPayerLobByLobID(payerLobId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PayerLobDetails>>;
    public getPayerLobByLobID(payerLobId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (payerLobId === null || payerLobId === undefined) {
            throw new Error('Required parameter payerLobId was null or undefined when calling getPayerLobByLobID.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/lobs/${this.configuration.encodeParam({name: "payerLobId", value: payerLobId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<PayerLobDetails>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Payer LOB changes
     * @param payerFamId Payer Family Id
     * @param payerPlanId Payer Plan Id
     * @param payerAcctId Payer Account Id
     * @param payerLobId Payer Lob Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayerLobChangesByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Change>>;
    public getPayerLobChangesByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Change>>>;
    public getPayerLobChangesByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Change>>>;
    public getPayerLobChangesByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (payerFamId === null || payerFamId === undefined) {
            throw new Error('Required parameter payerFamId was null or undefined when calling getPayerLobChangesByID.');
        }
        if (payerPlanId === null || payerPlanId === undefined) {
            throw new Error('Required parameter payerPlanId was null or undefined when calling getPayerLobChangesByID.');
        }
        if (payerAcctId === null || payerAcctId === undefined) {
            throw new Error('Required parameter payerAcctId was null or undefined when calling getPayerLobChangesByID.');
        }
        if (payerLobId === null || payerLobId === undefined) {
            throw new Error('Required parameter payerLobId was null or undefined when calling getPayerLobChangesByID.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/families/${this.configuration.encodeParam({name: "payerFamId", value: payerFamId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/plans/${this.configuration.encodeParam({name: "payerPlanId", value: payerPlanId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/accounts/${this.configuration.encodeParam({name: "payerAcctId", value: payerAcctId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lobs/${this.configuration.encodeParam({name: "payerLobId", value: payerLobId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/changes`;
        return this.httpClient.request<Array<Change>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Payer LOBs
     * @param payerFamId Payer family id
     * @param payerPlanId Payer Plan Id
     * @param payerAcctId Payer account id
     * @param sort 
     * @param filter 
     * @param restartRowId 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayerLobs(payerFamId: number, payerPlanId: number, payerAcctId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<PayerLobList>>;
    public getPayerLobs(payerFamId: number, payerPlanId: number, payerAcctId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<PayerLobList>>>;
    public getPayerLobs(payerFamId: number, payerPlanId: number, payerAcctId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<PayerLobList>>>;
    public getPayerLobs(payerFamId: number, payerPlanId: number, payerAcctId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (payerFamId === null || payerFamId === undefined) {
            throw new Error('Required parameter payerFamId was null or undefined when calling getPayerLobs.');
        }
        if (payerPlanId === null || payerPlanId === undefined) {
            throw new Error('Required parameter payerPlanId was null or undefined when calling getPayerLobs.');
        }
        if (payerAcctId === null || payerAcctId === undefined) {
            throw new Error('Required parameter payerAcctId was null or undefined when calling getPayerLobs.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (sort !== undefined && sort !== null) {
            localVarHeaders = localVarHeaders.set('Sort', String(sort));
        }
        if (filter !== undefined && filter !== null) {
            localVarHeaders = localVarHeaders.set('Filter', String(filter));
        }
        if (restartRowId !== undefined && restartRowId !== null) {
            localVarHeaders = localVarHeaders.set('Restart-Row-Id', String(restartRowId));
        }
        if (pageSize !== undefined && pageSize !== null) {
            localVarHeaders = localVarHeaders.set('Page-Size', String(pageSize));
        }

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/families/${this.configuration.encodeParam({name: "payerFamId", value: payerFamId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/plans/${this.configuration.encodeParam({name: "payerPlanId", value: payerPlanId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/accounts/${this.configuration.encodeParam({name: "payerAcctId", value: payerAcctId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lobs`;
        return this.httpClient.request<Array<PayerLobList>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import address changes
     * @param fileToUpload 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAddressChanges(fileToUpload?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BulkAddressImport>;
    public importAddressChanges(fileToUpload?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BulkAddressImport>>;
    public importAddressChanges(fileToUpload?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BulkAddressImport>>;
    public importAddressChanges(fileToUpload?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (fileToUpload !== undefined) {
            localVarFormParams = localVarFormParams.append('fileToUpload', <any>fileToUpload) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/lobs/import-addresses`;
        return this.httpClient.request<BulkAddressImport>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update payer LOB
     * @param payerFamId Payer Family Id
     * @param payerPlanId Payer Plan Id
     * @param payerAcctId Payer Account Id
     * @param payerLobId Payer LOB Id
     * @param payerLob Payer LOB object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, payerLob: PayerLob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PayerLob>;
    public updatePayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, payerLob: PayerLob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PayerLob>>;
    public updatePayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, payerLob: PayerLob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PayerLob>>;
    public updatePayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, payerLob: PayerLob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (payerFamId === null || payerFamId === undefined) {
            throw new Error('Required parameter payerFamId was null or undefined when calling updatePayerLob.');
        }
        if (payerPlanId === null || payerPlanId === undefined) {
            throw new Error('Required parameter payerPlanId was null or undefined when calling updatePayerLob.');
        }
        if (payerAcctId === null || payerAcctId === undefined) {
            throw new Error('Required parameter payerAcctId was null or undefined when calling updatePayerLob.');
        }
        if (payerLobId === null || payerLobId === undefined) {
            throw new Error('Required parameter payerLobId was null or undefined when calling updatePayerLob.');
        }
        if (payerLob === null || payerLob === undefined) {
            throw new Error('Required parameter payerLob was null or undefined when calling updatePayerLob.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/payers/families/${this.configuration.encodeParam({name: "payerFamId", value: payerFamId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/plans/${this.configuration.encodeParam({name: "payerPlanId", value: payerPlanId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/accounts/${this.configuration.encodeParam({name: "payerAcctId", value: payerAcctId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lobs/${this.configuration.encodeParam({name: "payerLobId", value: payerLobId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<PayerLob>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: payerLob,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
