<kendo-dialog
  class="modal-loader-component"
  *ngIf="loading"
  [minWidth]="250"
>
<prism-spinner size="large" [style.height]="'300px'"></prism-spinner>

</kendo-dialog>


