import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KendoModule } from '../shared/kendo/kendo.module';
import { SharedAccessModuleModule } from '../shared/shared-access-module/shared-access-module.module';
import { SharedComponentsModule } from '../shared/shared-components/shared-components.module';
import { SharedDirectivesModule } from '../shared/shared-directives/shared-directives.module';
import { SharedPipesModule } from '../shared/shared-pipes/shared-pipes.module';
import { SharedServicesModule } from '../shared/shared-services/shared-services.module';
import { LayoutComponent } from './layout.component';
import { MenuService } from './services/menu/menu.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [LayoutComponent],
    imports: [
        CommonModule,
        KendoModule,
        RouterModule,
        SharedServicesModule,
        SharedAccessModuleModule,
        SharedComponentsModule,
        SharedDirectivesModule,
        SharedPipesModule,
        SharedModule
    ],
  exports: [
    LayoutComponent,
    RouterModule
  ],
  providers: [
    MenuService
  ]
})
export class LayoutTemplateModule { }
