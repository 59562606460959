import { Component, Input } from '@angular/core';

@Component({
  selector: 'prism-pill-flag',
  templateUrl: './pill-flag.component.html',
  styleUrls: ['./pill-flag.component.scss']
})
export class PillFlagComponent {
  @Input() active: boolean;
  @Input() activeText : string = 'Active'
  @Input() inactiveText : string = 'InActive'
  constructor() { }

}
