import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { GridColumn } from '../../shared-interfaces';
import { ItemDisabledFn } from '@progress/kendo-angular-dropdowns';

export const MULTI_COLUMN_COMBOBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiColumnComboboxComponent),
  multi: true
};


@Component({
  selector: 'prism-multicolumncombobox',
  templateUrl: './multicolumncombobox.component.html',
  styleUrls: ['./multicolumncombobox.component.scss'],
  providers: [MULTI_COLUMN_COMBOBOX_CONTROL_VALUE_ACCESSOR]
})
export class MultiColumnComboboxComponent implements ControlValueAccessor {

  @Input() data: any[];

  @Input() textField = 'displayName';

  @Input() valueField = 'id';

  @Input() columns: GridColumn[] = [];

  @Input() placeholder = '';

  @Input() filterable = false;

  @Input() width = 600;

  @Input() rowActions: any[] = [];

  @Input() valuePrimitive = false;

  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() loadNextPage: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectionChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public disabled = false;

  @Input()
  public disableFn: ItemDisabledFn = this.itemDisabled.bind(this);

  public innerValue: any;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() { }

  get value(): any {
    return this.innerValue;
  }

  set value(obj: any) {
    this.writeValue(obj);
    this.onChangeCallback(obj);
  }

  public writeValue(obj: any): void {
    this.innerValue = obj;
    this.handleSearchChanged(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleSearchChanged($event: any): void {
    this.filterChange.emit($event);
  }

  public preventClose($event): void {
    $event.preventDefault();
  }

  public expandDropdown(): void {
    this.addScrollListener();
  }

  public selectionChange($event): void {
    if (this.rowActions?.length === 0) {
      this.selectionChangeEvent.emit($event);
    }
  }

  public itemDisabled(args: any): boolean {
    return false;
  }

  private addScrollListener(): void {
    setTimeout(() => {
      const elements = Array.from(document.querySelectorAll('.k-table-scroller'));
      elements.forEach(e => {
        e.addEventListener('scroll', () => {
          const listItems = e.querySelectorAll('li');
          const el = listItems[listItems.length - 1];
          const rect     = el.getBoundingClientRect();
          const vWidth   = window.innerWidth || document.documentElement.clientWidth;
          const vHeight  = window.innerHeight || document.documentElement.clientHeight;
          if ((rect.right >= 0 || rect.bottom >= 0 || rect.left <= vWidth || rect.top <= vHeight) &&
          (el.contains(document.elementFromPoint(rect.left,  rect.top)) ||
          el.contains(document.elementFromPoint(rect.right, rect.top)) ||
          el.contains(document.elementFromPoint(rect.right, rect.bottom)) ||
          el.contains(document.elementFromPoint(rect.left,  rect.bottom)))) {
            // not all elements in the list are selectable values
            this.loadNextPage.emit(listItems.length);
          }
        });
      });
    }, 0);
  }
}
