<div class="container-fluid login-container">
  <div class="modal-background"></div>
  <div class="row" *ngIf="isActionEnabled">
    <div class="col-6 offset-3">
      <div class="logo-block">
        <img src="https://infradevminio.z13.web.core.windows.net/logo.png" alt="logo">
      </div>
      <div class="card terms-card">
        <div class="card-body">
          <kendo-tabstrip class="tab-content">
            <kendo-tabstrip-tab [selected]="true">
              <ng-template kendoTabTitle>
                <label class="tab-title">Terms and Conditions</label>
              </ng-template>
              <ng-template kendoTabContent>
                <div [innerHTML]="terms | safeHtml"></div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab>
              <ng-template kendoTabTitle>
                <label class="tab-title">Privacy Policy</label>
              </ng-template>
              <ng-template kendoTabContent>
                <div [innerHTML]="privacy | safeHtml"></div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
          <div class="card-footer">
            <div class="terms-button">
              <prism-button type="fifth" (clicked)="disagree()" class="disagree-btn">
                I Disagree
              </prism-button>
            </div>
            <div class="terms-button">
              <prism-button type="primary" (clicked)="confirm()">
                I Agree
              </prism-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <prism-page-footer></prism-page-footer>
</div>
