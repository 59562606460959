import { Pipe, PipeTransform } from '@angular/core';
import statusMap from './../data/status.json';

@Pipe({
  name: 'codeToDesc'
})
export class CodeDescPipe implements PipeTransform {

  transform(value: string, statusType?: string, test?: string): string {

    let dispValue = statusMap[value];
    switch (statusType) {
      case 'authStatus':
        dispValue = !dispValue ? 'Failed to Login to PRISM' : dispValue;
        return `${dispValue} (${value})`;
      case 'dispStatus':
      default:
        return dispValue;
    }
  }
}
