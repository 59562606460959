import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AccessInfo } from '../../shared-access-module/openapi';
import { SectionHeaderAction, Stepper } from '../../shared-interfaces/shared-interfaces';
import { PrintEventService } from '../../shared-services/print-event/print-event.service';
import { LeftSideSectionDirective } from './left-side-section.directive';

@Component({
  selector: 'prism-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent {
  @ContentChild(LeftSideSectionDirective, {read: TemplateRef}) leftSideTemplate;
  @Input() title: string;
  @Input() pillText: string | null;
  @Input() actions: SectionHeaderAction[];
  @Input() checkAccessFn: (widget: any) => Array<AccessInfo.ActionsEnum>;
  @Input() breadcrumbs: string;
  @Input() backAction: () => void;
  @Input() backActionIcon = 'arrow-chevron-left';
  @Input() stepper: Stepper;
  @Input() showPrint = false;
  @Input() showBreadCrumbs = true;
  @Input() enableBreadCrumbs = true;
  @Output() stepChange: EventEmitter<number> = new EventEmitter();

  constructor(private printEventService: PrintEventService) { }

  print(): void {
    this.printEventService.print();
  }

}
