import { Injectable } from '@angular/core';
import {SettingsService} from '../settings/settings.service';
import {UsersService as UsersApiService, UserProfile} from '../../shared-access-module/openapi';
import {Observable} from 'rxjs';

export interface UserProfileRequest {
  isTermsAccepted?: boolean | null;
  oldPwd?: string | null;
  newPwd?: string | null;
  confirmNewPwd?: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {

  constructor(
    private settingsService: SettingsService,
    private usersService: UsersApiService,
    ) {
    this.usersService.configuration.basePath = `${this.settingsService.basePath}/ss/acm`;
    this.usersService.configuration.withCredentials = true;
  }

  public getMyProfile(): Observable<UserProfile> {
    return this.usersService.getMyProfile();
  }

  public patchMyProfile(userProfile: UserProfileRequest): Observable<UserProfile> {
    return this.usersService.patchMyProfile(userProfile);
  }
}
