<div class="page-content">
  <span class="header-content" *ngIf="!userType || !routedFrom">
    <kendo-icon [name]="'warning'" [size]="'large'"></kendo-icon>
    Not Authorized
  </span>
  <span class="header-content reroute-header" *ngIf="userType || routedFrom">
    <kendo-icon [name]="'info'" [size]="'medium'"></kendo-icon>
    Access not Authorized
  </span>
  <div class="body-content" *ngIf="!userType || !routedFrom">
    <div>
      You're not authorized to view the requested page.
    </div>
    <div>
      You can click back button or <a [href]="'/home'"> click here</a> to go to our Home Page.
    </div>
  </div>
  <div class="body-content">
    <div *ngIf="userType == 'INTEGRA' && routedFrom == 'reports'">
      Please contact your lead for access.
    </div>
    <div *ngIf="userType == 'PROVIDER' && routedFrom == 'reports'">
      You do not have permission to access the requested page.
    </div>
    <div *ngIf="userType == 'PAYER' && routedFrom == 'reports'">
      Please contact your Integra Account Manager for access.
    </div>
  </div>
  <div *ngIf="userType || routedFrom" class="mt-20">
    <img src="./assets/images/access_unauthorized.png" class="access-unauthorized" alt="access not authorized">
  </div>
</div>
