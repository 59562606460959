export const datePipeLocale = 'en-US';
export const gridPageDefaultSize = 20;
export const onlyAlphaPattern = /^[a-zA-Z ]+$/;
export const onlyAlphaNumPattern = /^[a-zA-Z0-9]+$/;
export const onlyAlphaNumSpacePattern = /^[a-zA-Z0-9 ]+$/;
export const onlyAlphaNumLodashPattern = /^[a-zA-Z0-9\_]+$/;
export const onlyAlphaNumDashPattern = /^[a-zA-Z0-9\-]+$/;
export const onlyAlphaNumHyphenSpacePattern = /^[a-zA-Z0-9- \/]+$/;
export const onlyAlphaNumHyphenSpaceCommaPattern = /^[a-zA-Z0-9-, \\/]+$/;
export const onlyNumbersPattern = /^[0-9]*$/;
export const EmailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const authCodePattern = /^[a-zA-Z0-9\-\/]*$/;
export const integerPattern = /^\d+$/;
export const websitePattern = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
export const attribCanBePrimary = 'can_use_as_mbr_primary';

export enum listTypeCodes {
  claimTimelinessCatg = 'CLAIM_TIMELINESS_CATG',
  mcqDecisonCodeCatg = 'MCQ_DECISION_CODE',
  contactTypeCodes = 'CONTACT_TYPE',
  contactInfoType = 'CONTACT_INFO_TYPE',
  addressType = 'ADDRESS_TYPE',
  queAddressType = 'QUE_ADDRESS_TYPE'
}

export enum listContexts {
  payerLOB = 'PAYER_LOB',
}
export enum listAttributes {
  queAddrType = 'que_address_type',
  canDelLobAddr = 'can_delete_lob_addr',
}


export enum accessControlOrigins {
  sched = 'sched',
}

