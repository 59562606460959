import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'prism-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent {

  @Input() show: boolean = null;

  @Input()
  control: AbstractControl;

  get showErrors(): boolean {
    return this.show !== null ? this.show :
      !!this.control &&
      (this.control.dirty || this.control.touched) &&
      this.control.invalid;
  }

}
