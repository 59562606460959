<div *ngIf="viewMode === 'dropdown'">
  <kendo-combobox
    class="fullWidth roboto-reg-gray"
    [popupSettings]="{ popupClass: 'prism-lookup-combobox' }"
    [data]="data"
    [(ngModel)]="value"
    [textField]="textField"
    valueField="listDtlCode"
    [valuePrimitive]="true"
    (valueChange)="writeValue($event)"
    [placeholder]="placeholder"
    (blur)="onBlur()"
    [disabled]="disabled"
    [clearButton]="clearButton"
    (opened)="expandDropdown()"
    [filterable]="filterable"
    (filterChange)="queryStringfilterChange($event)"
    ngDefaultControl
  >
  </kendo-combobox>
</div>
<div
  *ngIf="viewMode === 'radio'"
  class="wrap"
>
  <ng-container *ngFor="let item of data">
    <input
      kendoRadioButton
      type="radio"
      [name]="radioName"
      [id]="item.listDtlCode"
      [(ngModel)]="value"
      (change)="writeValue(item.listDtlCode)"
      [value]="item.listDtlCode"
      [disabled]="disabled"
    />
    <label
      class="k-radio-label mr-5"
      [for]="item.listDtlCode"
    >{{ item.listDtlDesc }}</label>
  </ng-container>
</div>

<div *ngIf="viewMode === 'multiselect'" class="wrapper">
  <kendo-multiselect
    [placeholder]="placeholder"
    [data]="data"
    [(ngModel)]="value"
    [textField]="textField"
    valueField="listDtlCode"
    [autoClose]="false"
    [disabled]="disabled"
    [itemDisabled]="isItemDisabled"
    [filterable]="true"
    (filterChange)="queryStringfilterChange($event)"
    (opened)="expandDropdown()"
    ngDefaultControl
    [checkboxes]="true"
  >
  <ng-template kendoMultiSelectItemTemplate let-dataItem>
    <span [ngClass]="{'selected': isItemSelected(dataItem)}">{{dataItem[textField]}}</span>
  </ng-template>
  </kendo-multiselect>
</div>
