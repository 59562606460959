import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {AccessControlService} from '../../services/access-control.service';
import {AccessInfo} from '../../openapi';

@Directive({
  selector: '[prismSimpleAccess]'
})
export class SimpleAccessDirective implements OnInit{
  @Input() widgetId: string;
  @Input() route: string;
  @Input() origin: string;
  @Input() action: any;

  private accessToElement: Array<AccessInfo.ActionsEnum>;

  constructor(
    private accessControlService: AccessControlService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  @Input() checkAccessFn: (widget: any) => Array<AccessInfo.ActionsEnum>;

  ngOnInit(): void {
    if (this.action && this.checkAccessFn) {
      this.accessToElement = this.checkAccessFn(this.action);
      if (this.accessToElement.includes(AccessInfo.ActionsEnum.Enabled)) {
        this.accessToElement.push(AccessInfo.ActionsEnum.Visible);
      }
    } else if (this.widgetId) {
      this.accessToElement = this.accessControlService.getAccessForWidget(this.widgetId, this.origin);
    } else if (this.route) {
      this.accessToElement = this.accessControlService.getAccessForRoute(this.route);
      if (this.accessToElement.includes(AccessInfo.ActionsEnum.Enabled)) {
        this.accessToElement.push(AccessInfo.ActionsEnum.Visible);
      }
    }

    if (this.widgetId || this.route || this.action?.accessId) {
      this.enforceAccess();
    }
  }

  private enforceAccess = (): void => {
    if (!this.accessToElement.includes(AccessInfo.ActionsEnum.Visible)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'hidden-content');
    } else if (!this.accessToElement.includes(AccessInfo.ActionsEnum.Enabled)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'disabled-content');
    }
  }
}
