import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { EditorModule } from '@progress/kendo-angular-editor';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgxMaskModule } from 'ngx-mask';

import { MdmPipesModule } from '../../components/mdm/mdm-pipes/mdm-pipes.module';
import { MdmService } from '../../components/mdm/services/mdm.service';
import { KendoModule } from '../kendo/kendo.module';
import { SharedAccessModuleModule } from '../shared-access-module/shared-access-module.module';
import { SharedPipesModule } from '../shared-pipes/shared-pipes.module';
import { ActivityLogListComponent } from './activity-log-list/activity-log-list.component';
import { AddressCardComponent } from './address-card/address-card.component';
import { AuditInfoComponent } from './audit-info/audit-info.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ButtonComponent } from './button/button.component';
import { CanDeactivateDialogComponent } from './can-deactivate-dialog/can-deactivate-dialog.component';
import { CardComponent } from './card/card.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChartComponent } from './chart/chart.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ComboBoxComponent } from './combo-box/combo-box.component';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { DeleteAddressComponent } from './delete-address/delete-address.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { DropdownListComponent } from './dropdown-list/dropdown-list.component';
import { EditorComponent } from './editor/editor.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormLabelErrorComponent } from './form-label-error/form-label-error.component';
import { FormTextboxArrayComponent } from './form-textbox-array/form-textbox-array.component';
import { GridWithMultiselectSearchComponent } from './grid-with-multiselect-search/grid-with-multiselect-search.component';
import { GridComponent } from './grid/grid.component';
import { SearchPipe } from './grid/search-pipe/search-pipe.pipe';
import { GroupedGridComponent } from './grouped-grid/grouped-grid.component';
import { InputChipListComponent } from './input-chip-list/input-chip-list.component';
import { IntegraSearchComponent } from './integra-search/integra-search.component';
import { LookupComponent } from './lookup/lookup.component';
import { ModalLoaderComponent } from './modal-loader/modal-loader.component';
import { ModalComponent } from './modal/modal.component';
import { MultiColumnComboboxComponent } from './multicolumncombobox/multicolumncombobox.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { PillFlagComponent } from './pill-flag/pill-flag.component';
import { PillComponent } from './pill/pill.component';
import { PrismSwitchComponent } from './prism-switch/prism-switch.component';
import { LeftSideSectionDirective } from './section-header/left-side-section.directive';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TermsConditionsDialogComponent } from './terms-conditions-dialog/terms-conditions-dialog.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { UploadComponent } from './upload/upload.component';
import { MultiselectTreeDropdownComponent } from './multiselect-tree-dropdown/multiselect-tree-dropdown.component';
import { PrefixedTextboxComponent } from './prefixed-textbox/prefixed-textbox.component';
import { TreeGridComponent } from './tree-grid/tree-grid.component';
import { ModalBottomComponent } from './modal-bottom/modal-bottom.component';
import { PrismDatepickerComponent } from './prism-datepicker/prism-datepicker.component';
import { DrawerComponent } from './drawer/drawer.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CardComponent,
    CheckboxComponent,
    ButtonComponent,
    SectionHeaderComponent,
    GridComponent,
    SearchPipe,
    PillFlagComponent,
    AddressCardComponent,
    ModalComponent,
    DeleteAddressComponent,
    ContactCardComponent,
    IntegraSearchComponent,
    LeftSideSectionDirective,
    EditorComponent,
    DropdownButtonComponent,
    UploadComponent,
    CanDeactivateDialogComponent,
    ComboBoxComponent,
    FormErrorsComponent,
    FormErrorComponent,
    SpinnerComponent,
    FormErrorComponent,
    NotificationBannerComponent,
    LookupComponent,
    PasswordInputComponent,
    FormLabelErrorComponent,
    AuditInfoComponent,
    ChartComponent,
    ModalLoaderComponent,
    ActivityLogListComponent,
    PillComponent,
    FormFieldComponent,
    FormTextboxArrayComponent,
    DropdownListComponent,
    MultiColumnComboboxComponent,
    MultiselectComponent,
    ChangePasswordFormComponent,
    TermsConditionsDialogComponent,
    InputChipListComponent,
    BreadcrumbComponent,
    PageFooterComponent,
    TextareaComponent,
    PrismSwitchComponent,
    GridWithMultiselectSearchComponent,
    TooltipComponent,
    GroupedGridComponent,
    MultiselectTreeDropdownComponent,
    PrefixedTextboxComponent,
    ModalBottomComponent,
    TreeGridComponent,
    PrismDatepickerComponent,
    DrawerComponent,
  ],
  imports: [
    KendoModule,
    NotificationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    NgxMaskModule.forChild(),
    PopupModule,
    EditorModule,
    UploadsModule,
    LoaderModule,
    SharedAccessModuleModule,
    ChartsModule,
    MdmPipesModule,
    MatDialogModule,
    NavigationModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [
    KendoModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    CheckboxComponent,
    ButtonComponent,
    SectionHeaderComponent,
    GridComponent,
    PillFlagComponent,
    AddressCardComponent,
    ModalComponent,
    ContactCardComponent,
    IntegraSearchComponent,
    LeftSideSectionDirective,
    EditorComponent,
    DropdownButtonComponent,
    UploadComponent,
    CanDeactivateDialogComponent,
    ComboBoxComponent,
    FormErrorsComponent,
    FormErrorComponent,
    SpinnerComponent,
    NotificationBannerComponent,
    LookupComponent,
    PasswordInputComponent,
    FormLabelErrorComponent,
    AuditInfoComponent,
    ChartComponent,
    ModalLoaderComponent,
    ActivityLogListComponent,
    PillComponent,
    FormFieldComponent,
    FormTextboxArrayComponent,
    DropdownListComponent,
    MultiColumnComboboxComponent,
    MultiselectComponent,
    ChangePasswordFormComponent,
    InputChipListComponent,
    PageFooterComponent,
    TextareaComponent,
    PrismSwitchComponent,
    GridWithMultiselectSearchComponent,
    GroupedGridComponent,
    MultiselectTreeDropdownComponent,
    PrefixedTextboxComponent,
    ModalBottomComponent,
    TreeGridComponent,
    PrismDatepickerComponent,
    DrawerComponent,
  ],
  providers: [MdmService],
})
export class SharedComponentsModule {}
