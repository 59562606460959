import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { noop } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const PRISM_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PrismSwitchComponent),
  multi: true
};

@Component({
  selector: 'prism-switch',
  templateUrl: './prism-switch.component.html',
  styleUrls: ['./prism-switch.component.scss'],
  providers: [PRISM_SWITCH_CONTROL_VALUE_ACCESSOR]
})
export class PrismSwitchComponent implements ControlValueAccessor {
  @Input() labelOn: string;
  @Input() labelOff: string;
  @Input() disabled: boolean;
  @Input() set controledValue(value: boolean) {
    this.writeValue(value);
  }

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  public innerValue: boolean;

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }


}
