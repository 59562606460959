<kendo-dropdownlist
  [ngStyle]="{width: '100%'}"
  [data]="data"
  [(ngModel)]="value"
  textField="displayName"
  valueField="id"
  [valuePrimitive]="true"
  ngDefaultControl
  [disabled]="disabled"
  (opened)="expandDropdown()"
  ></kendo-dropdownlist>