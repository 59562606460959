<div class="main-toolbar d-print-none">
  <div class="main-toolbar-content d-flex justify-content-between align-items-center w-100">
    <span class="mail-box">
      <span class="admin-workbrench">
        <a [routerLink]="['/home']">
          <img class="img-responsive" src="../../assets/images/gem.svg" alt="">
        </a>
      </span>
    </span>

    <div class="menu-item-wrapper d-flex align-items-center" (outsideClick)="toggleSubMenu()">
      <div *ngFor="let item of menuItems; let i = index" class="menu-item-template mr-5" (click)="toggleSubMenu(item)">
        <span class="k-level-{{ item.level }}">
          <span *ngIf="!!item.isCustom; else defaultIcon">
            <mat-icon class="k-icon" [svgIcon]="item.icon"></mat-icon>
          </span>
          <ng-template #defaultIcon>
            <span class="k-icon k-font-icon {{ item.icon }}"></span>
          </ng-template>
        </span>
        <span class="menu-item" [ngClass]="{ expanded: item.expanded}">{{ item.text }}</span>
        <span *ngIf="item.children" class="k-icon k-font-icon k-i-arrow-chevron-down"
          [ngClass]="{'k-i-arrow-chevron-down': item.expanded, 'k-i-arrow-chevron-right': !item.expanded}"></span>
      </div>
    </div>

    <span class="help ml-auto ml-15 mr-4">
      <span class="help-workbrench">
        <a [routerLink]="['/help-center']">
          <span class="k-icon k-font-icon k-i-help k-icon-20"> </span>
        </a>
      </span>
    </span>

    <kendo-menu class="user-menu" [items]="userMenuItems" (select)="selectUserMenuItem($event)">
      <ng-template kendoMenuItemTemplate let-item="item">
        <span *ngIf="item.text === 'userHeader'" class="k-icon k-font-icon k-i-user gray-color"></span>
        <span class="gray-color k-icon k-font-icon" [ngClass]="iconClass(item)"></span>
        <span [ngClass]="textClass(item.class)">{{item.text !== 'userHeader' ? item.text : ''}} </span>
      </ng-template>
    </kendo-menu>
  </div>
  <div class="floating-sub-menu" [ngClass]="{ expanded: subMenuOpen && selectedMenu !== null }">
    <ng-container *ngFor="let item of selectedMenu?.items">
      <div (click)="onSelect(item)" class="floating-sub-menu-item d-flex align-items-center mr-5">
        <div>
          <span *ngIf="iconClass(item)" class="k-icon k-font-icon mr-1" [ngClass]="iconClass(item)"></span>
          <span *ngIf="!item.header">{{item.text}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="layout-container">
  <router-outlet></router-outlet>
</div>
<div class="layout-footer container-fluid">
  <div class="row">
    <div class="col-6 offset-3">
      <div class="d-flex justify-content-around footer-texts">
        <span class="copyright">©2013-{{currentYear | date: 'yyyy': null: dateLocalePipe}} INTEGRA PARTNERS LLC</span>
        <a target="_blank" href="{{privacyPolicyURL}}" rel="noopener">Privacy Policy</a>
        <a target="_blank" href="{{termsURL}}" rel="noopener">Terms & Conditions</a>
      </div>
    </div>
  </div>
</div>

<prism-modal-loader></prism-modal-loader>

<prism-modal title="PRISM Maintenance Notice" titleIconType="danger" titleIcon="warning"
  [actions]="maintenancePopupActions" [opened]="showMaintenanceDialog" (cancel)="showMaintenanceDialog = false">
  <div>The Prism Platform will be down for scheduled maintance within {{timeUntilRedirect}}. Please save all changes.
  </div>
</prism-modal>