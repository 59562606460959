import { Inject, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SESSION_INSTANCE } from '../../../../msal/constants';
import { SessionService } from '../../../shared-services/session/session.service';
import { AccessControlService } from '../../services/access-control.service';

@Injectable()
export class LayoutAccessResolver  {
  constructor(private accessControlService: AccessControlService, @Inject(SESSION_INSTANCE) private sessionService: SessionService){
  }

  resolve(): Observable<boolean> {
    if (this.sessionService.isRouteAuthRequired()) {
      return this.accessControlService.loadAccess({
        originService: 'layout',
        loadRoutes: true,
        loadWidgets: true
      });
    } else {
      return of(true);
    }
  }
}

export const resolveLayoutData: ResolveFn<boolean> = () => {
  return inject(LayoutAccessResolver).resolve()
}
