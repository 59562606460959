<kendo-upload #kendoUploadComponent [ngClass]="{'hide-dropzone': hideUpload, 'hide-actions': hideActions}"
  [saveUrl]="uploadDocument" [removeUrl]="deleteDocument" [saveField]="saveField" [autoUpload]="autoUpload"
  [multiple]="allowMultiple" [disabled]="disabled" [restrictions]="fileRestrictions" (select)="onSelectEvent($event)"
  [(ngModel)]="filesList">
  <kendo-upload-messages class="d-print-none" select="Select Files"></kendo-upload-messages>
  <ng-template kendoUploadFileTemplate let-file>
    <span class="k-file-group-wrapper">
      <span class="k-file-group k-icon k-font-icon" [ngClass]="getFileExtensionCssClass(file[0])"></span>
    </span>
    <span class="k-file-name-size-wrapper">
      <span class="k-file-name roboto-reg-gray" [title]="file[0].name">{{ file[0].name }}</span>
      <span class="file-info" [ngClass]="{
            'text-success': isUploadSuccessful(file[0]),
            'text-error': file.validationErrors || isUploadFailed(file[0]),
            'file-size': file[0].state === 1 || isNotYetUploaded(file[0])
          }">{{getFileStatusText(file[0])}}</span>
    </span>
    <span *ngIf="file[0].state === 4">
      <prism-spinner></prism-spinner>
    </span>
    <span class="k-file-group-wrapper preview-content d-print-none"
      *ngIf="[3, 1].includes(file[0].state) && viewEnabled">
      <span data-testid="download-file" class="k-icon k-font-icon k-i-preview" (click)="downloadAttachment(file[0])"></span>
    </span>
    <span class="k-file-group-wrapper delete-content d-print-none"
      *ngIf="[3, 1, 0, 2].includes(file[0].state) && deleteEnabled">
      <span data-testid="delete-file" class="k-icon k-font-icon k-i-delete" (click)="deleteAttachment(file[0])"></span>
    </span>
  </ng-template>
</kendo-upload>