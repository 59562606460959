<div class="d-flex justify-content-between" [ngClass]="{ 'mb-3': !showPrint }">
  <div class="breadcrumbs-container">
    <div
      class="breadcrumb-panel"
      *ngIf="showBreadCrumbs"
      [ngClass]="{ 'k-disabled': !enableBreadCrumbs }"
    >
      <prism-breadcrumb></prism-breadcrumb>
    </div>
  </div>
  <div *ngIf="showPrint" class="d-print-none">
    <prism-button
      icon="print"
      type="flat"
      look="flat"
      (click)="print()">Print</prism-button>
  </div>
</div>
<div class="section-header-container d-flex align-items-center justify-content-between">
  <div class="d-flex" *ngIf="!leftSideTemplate">
    <span
      *ngIf="backAction"
      class="k-icon k-font-icon k-i-{{backActionIcon}} gray-color backAction mr-1"
      (click)="backAction()"
    ></span>
    <h2 class="header-title" [innerHTML]="title"></h2>
    <div *ngIf="pillText" class="pill"><prism-pill [status]="pillText"></prism-pill></div>
  </div>
  <div class="d-flex">
    <ng-container *ngTemplateOutlet="leftSideTemplate"></ng-container>
  </div>
  <div *ngIf="stepper">
    <kendo-stepper
      [steps]="stepper.steps"
      [stepType]="'full'"
      [(currentStep)]="stepper.current"
      [linear]="false"
      [style.width.px]="stepper.width"
      (currentStepChange)="stepChange.emit($event)">
    </kendo-stepper>
  </div>
  <div *ngIf="actions" class="actions d-flex justify-content-end d-print-none">
    <ng-container *ngFor="let action of actions">
      <prism-button
                    *ngIf="!action.dropDownData"
                    [disabled]="!!action.disabled"
                    [icon]="action.icon"
                    [iconImageUrl]="action.iconImageUrl"
                    [type]="action.type"
                    [size]="'small'"
                    [ngStyle]="{display: action.hidden ? 'none' : 'block'}"
                    (clicked)="action.callback()"
                    prismSimpleAccess
                    [widgetId]="action.widgetId"
                    [origin]="action.origin"
                    [route]="action.route"
                    [checkAccessFn]="checkAccessFn"
                    [action]="action"
                    [nativeType]="action.nativeType"
      >
        {{action.text}}
      </prism-button>
      <prism-dropdown-button class="mr-2"
                            *ngIf="action.dropDownData"
                            [disabled]="!!action.disabled"
                            [icon]="action.icon"
                            [appearance]="'button'"
                            [popupAlign]="'right'"
                            [type]="action.type"
                            [ngStyle]="{display: action.hidden ? 'none' : 'block'}"
                            [dropdownData]="action.dropDownData"
                            prismSimpleAccess
                            [widgetId]="action.widgetId"
                            [origin]="action.origin"
                            [route]="action.route"
                            [checkAccessFn]="checkAccessFn"
                            [action]="action"
      >
        {{action.text}}
      </prism-dropdown-button>
    </ng-container>
  </div>
</div>
