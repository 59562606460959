import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prism-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent {
  @Input() dropdownData: Array<any>;
  @Input() disabled: boolean;
  @Input() appearance: 'button'| 'dropdown' = 'dropdown';
  @Input() icon: string;
  @Input() width: string;
  @Input() size: string;
  @Input() type: string;
  @Input() look: any = 'flat';
  @Input() popupAlign: 'left' | 'center' | 'right' = 'center';
  @Output() itemClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public onItemClick(): void {
    this.itemClicked.emit();
  }

}
