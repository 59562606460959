/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Language } from './language';
import { OrdAuthWindowDt } from './ordAuthWindowDt';
import { OrderProviderLookupAddr } from './orderProviderLookupAddr';
import { OrderProviderLookupProduct } from './orderProviderLookupProduct';
import { OrderProviderLookupProductCatgs } from './orderProviderLookupProductCatgs';


export interface OrderProviderLookup { 
    payerLobId?: number;
    payerAcctId?: number;
    memberId?: number;
    deliveryMethod?: Array<OrderProviderLookup.DeliveryMethodEnum>;
    staAddress: OrderProviderLookupAddr;
    includeFromAvoidList?: boolean;
    products: Array<OrderProviderLookupProduct>;
    productCatgs?: Array<OrderProviderLookupProductCatgs>;
    ordAuthWindows?: Array<OrdAuthWindowDt>;
    asOfDate?: string;
    languages?: Array<Language>;
    ormEnabled?: OrderProviderLookup.OrmEnabledEnum;
    /**
     * mdm list PROV_ADDNL_SERVICES
     */
    includeProvSvcs: Array<string>;
    /**
     * mdm list PROV_BILLING_MONTH
     */
    billingRange?: number | null;
    verifiedFacilityIds?: Array<number>;
    verifiedOrganizationIds?: Array<number>;
    purpose: OrderProviderLookup.PurposeEnum;
}
export namespace OrderProviderLookup {
    export type DeliveryMethodEnum = 'Pickup' | 'DropShip' | 'Delivery';
    export const DeliveryMethodEnum = {
        Pickup: 'Pickup' as DeliveryMethodEnum,
        DropShip: 'DropShip' as DeliveryMethodEnum,
        Delivery: 'Delivery' as DeliveryMethodEnum
    };
    export type OrmEnabledEnum = 'All' | 'Enabled' | 'Disabled';
    export const OrmEnabledEnum = {
        All: 'All' as OrmEnabledEnum,
        Enabled: 'Enabled' as OrmEnabledEnum,
        Disabled: 'Disabled' as OrmEnabledEnum
    };
    export type PurposeEnum = 'SEARCH' | 'LOOKUP' | 'LOOKUP_ORT';
    export const PurposeEnum = {
        Search: 'SEARCH' as PurposeEnum,
        Lookup: 'LOOKUP' as PurposeEnum,
        LookupOrt: 'LOOKUP_ORT' as PurposeEnum
    };
}


