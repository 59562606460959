<div class="col-sm-12 col-lg-6 p-1 multi-select-search-box-wrapper">
  <prism-multicolumncombobox
    class="w-100"
    [placeholder]="searchPlaceHolderText"
    [data]="searchList"
    [columns]="searchColumns"
    [rowActions]="searchRowActions"
    [filterable]="true"
    (filterChange)="searchChanged($event)"
    (loadNextPage)="nextPage()"
    [disabled]="disabled"
  ></prism-multicolumncombobox>
</div>
<div class="col-12 mt-2 p-1">
  <kendo-grid *ngIf="selectedCodeList"
      [data]="selectedCodeList"
      [height]="300"
      [pageSize]="50"
      [skip]="0">
    <kendo-grid-column *ngFor="let item of displayColumns"
      [title]="item.title"
      [field]="item.field"
      [width]="item.width"
      [style]="item.style"
      [headerStyle]="item.headerStyle">
    </kendo-grid-column>
    <kendo-grid-column [width]="220" [headerStyle]="{'background-color': '#F6F6F6'}">
      <ng-template kendoGridHeaderTemplate>
        <label class="mt-2 mr-2" *ngIf="dispCheckBoxValue">
          <input
            type="checkbox"
            [checked]="isHeaderCheckboxChecked"
            [disabled]="dispCheckBoxValue.disabled"
            (change)="onHeaderCheckboxChange($event)">
          {{dispCheckBoxValue.label}}
        </label>
        <prism-button class="mr-0"
                      icon="close"
                      size="small"
                      type="danger"
                      (clicked)= "removeAllItems()"
                      [disabled]="disableRemoveAll" *ngIf="!disabled">Remove All</prism-button>
      </ng-template>
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        <div class="rowaction-container">
          <prism-button
            class="mr-0 danger"
            [icon]="'close'"
            size="'small'"
            (clicked)= "removeItems(rowIndex)"
            *ngIf="!disabled"
          ></prism-button>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

