<prism-modal
  [title]="title"
  [titleIcon]="titleIcon"
  [titleIconType]="titleIconType"
  blurBackground="true"
  [opened]="isOpen"
  [actions]="actions"
  [width]="width"
  (cancel)="closeModal(false)"
>
  <div class="mt-1 mb-2">
    <ng-content></ng-content>
  </div>
</prism-modal>
