/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeliveredDtls { 
    /**
     * date/time when order actually was delivered
     */
    deliveredTS: string;
    /**
     * code from MDM, forced to enter if current deliveredTS > today
     */
    lateDeliveredReason?: string;
    /**
     * description from MDM, forced to enter if current deliveredTS > today
     */
    readonly lateDeliveredReasonDesc?: string;
    isSigRequired: boolean;
    dlvMethodCode?: string | null;
    notes?: string | null;
}

