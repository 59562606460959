import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureFlagService } from '../../../shared/shared-services/feature-flag/feature-flag.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'prism-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  public routedFrom: string;
  public userType: string;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
  ) {}
  ngOnInit(): void {
    this.featureFlagService.getBooleanFlag('reports-summary-redirect')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(flagValue => {
        this.parseQueryParams(flagValue);
      });
  }
  parseQueryParams(redirectFlag: boolean): void {
    if (redirectFlag) {
      this.route.queryParams.subscribe(params => {
        this.routedFrom = params?.from;
        this.userType = params?.user;
      });
    }
    }
}
