import { Inject, Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, SESSION_INSTANCE } from './constants';
import { MsalGuardConfiguration } from './msal.guard.config';
import { MsalService } from './msal.service';
import { AuthErrorMode, SessionService } from '../shared/shared-services/session/session.service';

@Injectable()
export class MsalGuard {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(SESSION_INSTANCE) private sessionService: SessionService,
    private authService: MsalService,
    private location: Location,
    private router: Router,
  ) { }

  /**
   * Builds the absolute url for the destination page
   * @param path Relative path of requested page
   * @returns Full destination url
   */
  getDestinationUrl(path: string): string {
    // Absolute base url for the application (default to origin if base element not present)
    const baseElements = document.getElementsByTagName('base');
    const baseUrl = this.location.normalize(baseElements.length ? baseElements[0].href : window.location.origin);

    // Path of page (including hash, if using hash routing)
    const pathUrl = this.location.prepareExternalUrl(path);

    // Hash location strategy
    if (pathUrl.startsWith('#')) {
      return `${baseUrl}/${pathUrl}`;
    }

    // If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
    // Since baseUrl also includes /base, can just concatentate baseUrl + path
    return `${baseUrl}${path}`;
  }

  private loginInteractively(url: string): Observable<boolean> {
    const redirectStartPage = this.getDestinationUrl(url);
    this.authService.loginRedirect({
      redirectStartPage,
      scopes: this.msalGuardConfig.scopes,
      ...this.msalGuardConfig.authRequest,
    });
    return of(false);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.authService.handleRedirectObservable()
      .pipe(
        concatMap(() => {
          if (!this.authService.getAllAccounts().length) {
            return this.loginInteractively(state.url);
          }
          return of(true);
        }),
        catchError((err) => {
          // change authority to password reset policy
          if (err.message.indexOf('AADB2C90118') > -1) {
            this.authService.loginRedirect({
              scopes: this.msalGuardConfig.scopes,
              authority: this.msalGuardConfig.resetAuthority,
            });
            return of(true);
          } else {
            const numErrIndex = err.message.indexOf('AADB2C90');
            const strErrStart = err.message.indexOf('AADB2C:');
            let errorId = 'UNKNOWN';
            if (numErrIndex > -1) {
              errorId = err.message.slice(numErrIndex, numErrIndex + 11);
            } else if (strErrStart > -1) {
              const strErrEnd = err.message.indexOf('Correlation');
              errorId = err.message.slice(strErrStart + 8, strErrEnd - 1);
            }
            const showAuthErrorMode = this.sessionService.getShowAuthErrorMode(errorId);
            if (showAuthErrorMode === AuthErrorMode.RedirectToLogin) {
              this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest });
            } else {
              this.router.navigate([`/auth-error/${errorId}`]);
            }
            return of(false);
          }
        })
      );
  }

}

export const msalGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(MsalGuard).canActivate(route, state);
  };