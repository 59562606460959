/* eslint no-use-before-define: 0 */
import { Injectable } from '@angular/core';
import { FeeScheduleType } from '../enums/fee-schedule-type.enum';
import { FeeScheduleVersionsListConfiguration } from './fee-schedule-versions-list-configuration.service';
import { commonMiscListColumns } from '../models/fee-schedule';

@Injectable({
  providedIn: 'root'
})

export class FeeScheduleCatgListConfigurationService {
  private readonly catgListConfigs = new Map<FeeScheduleType, any>([
    [FeeScheduleType.PAYER, {
      defaultSort: [],
      idProp: 'feeSchedCatgId',
      gridColumns: [
        {
          title: 'Category Code',
          field: 'hcpcCatgCode',
          type: 'text',
        },
        {
          title: 'Category Description',
          field: 'hcpcCatgDesc',
          type: 'text',
          widthStyle: { width: '30%'},
        },
        ...commonMiscListColumns
      ],
      advSearchConfig: []
    }],
    [FeeScheduleType.PROVIDER, {
      defaultSort: [],
      idProp: 'feeSchedCatgId',
      gridColumns: [
        {
          title: 'Category Code',
          field: 'hcpcCatgCode',
          type: 'text',
        },
        {
          title: 'Category Description',
          field: 'hcpcCatgDesc',
          type: 'text',
          widthStyle: { width: '30%'},
        },
        ...commonMiscListColumns
      ],
      advSearchConfig: []
    }]
  ]);
  public getFeeSchedVerCatListConfig(scheduleType: FeeScheduleType): FeeScheduleVersionsListConfiguration | null {
    if (!this.catgListConfigs.has(scheduleType)) {
      return {
        title: '',
        idProp: '',
        defaultSort: [],
        sortableColumns: [],
        gridColumns: [],
        advSearchConfig: [],
      };
    }

    return this.catgListConfigs.get(scheduleType);
  }
}
