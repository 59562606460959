/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enum describing the date used to create the start or end of the FulfillmentRange.
 */
export type OrderFulfillmentRangeType = 'unknown' | 'today' | 'auth_start' | 'auth_end' | 'creation' | 'next_order';

export const OrderFulfillmentRangeType = {
    Unknown: 'unknown' as OrderFulfillmentRangeType,
    Today: 'today' as OrderFulfillmentRangeType,
    AuthStart: 'auth_start' as OrderFulfillmentRangeType,
    AuthEnd: 'auth_end' as OrderFulfillmentRangeType,
    Creation: 'creation' as OrderFulfillmentRangeType,
    NextOrder: 'next_order' as OrderFulfillmentRangeType
};

