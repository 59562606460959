import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { noop } from 'rxjs';

export const PASSWORD_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordInputComponent),
  multi: true
};


@Component({
  selector: 'prism-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [PASSWORD_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class PasswordInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('textbox') public textbox: TextBoxComponent;

  @Input()
  placeholder: string;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  private innerValue: string;
  private type: 'text' | 'password' = 'password';

  public ngAfterViewInit(): void {
    this.setType('password');
  }

  toggle(): void {
    this.setType(this.type === 'password' ? 'text' : 'password');
  }

  setType(type: 'text' | 'password'): void {
    this.type = type;
    const inputEl = this.textbox?.input?.nativeElement;
    if (inputEl) {
      inputEl.type = type;
    }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    this.writeValue(value);
  }

  writeValue(value: any): void {
      this.innerValue = value;
      this.onChangeCallback(value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
