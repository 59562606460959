/**
 * Payer Management
 * Payer Management Server.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PayerLookupRequest { 
    purpose: PayerLookupRequest.PurposeEnum;
    payerFamID: number;
    altSysID: string;
}
export namespace PayerLookupRequest {
    export type PurposeEnum = 'order_load' | 'enrollment';
    export const PurposeEnum = {
        OrderLoad: 'order_load' as PurposeEnum,
        Enrollment: 'enrollment' as PurposeEnum
    };
}


