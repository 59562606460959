import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MdmService } from '../../services/mdm.service';

@Pipe({
  name: 'codeToDescAsync'
})
export class CodeToDescAsyncPipe implements PipeTransform {

  constructor(private mdmService: MdmService) {}

  transform(value: unknown, listType: string, listTypeCtx?: string | ''): Observable<string> {
    return this.mdmService.getCachedMdmDataList(listType, listTypeCtx)
      .pipe(map(items => {
        return items.find(item => item.listDtlCode === value)?.listDtlDesc;
      }));
  }

}
