/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ListItemCtx { 
    listCtxId?: number;
    listCtxName: string;
    listCtxDesc: string;
    /**
     * create-update-delete
     */
    intaction?: ListItemCtx.IntactionEnum;
}
export namespace ListItemCtx {
    export type IntactionEnum = 'create' | 'update' | 'delete';
    export const IntactionEnum = {
        Create: 'create' as IntactionEnum,
        Update: 'update' as IntactionEnum,
        Delete: 'delete' as IntactionEnum
    };
}


