/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderNote { 
    readonly payerLobId?: number;
    readonly memberId?: number;
    readonly orderId?: number;
    readonly noteId?: number;
    readonly orderProviderId?: number;
    orderNote: string;
    isInternal?: boolean;
    readonly modifiedTs?: string;
    readonly modifiedBy?: string;
    readonly createdTs?: string;
    readonly createdBy?: string;
}

