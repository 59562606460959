import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubmitService {

  private busySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public busy$: Observable<boolean> = this.busySubject.asObservable();

  constructor() { }

  public toggleBusy(busy: boolean): void {
    this.busySubject.next(busy);
  }
}
