import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import {
  ParsedPayer,
  ParsedPayerType,
  PayerAcctsFormVals,
  PayerLobFormValues,
  PayerPlanFormValues,
  PayersFormValues
} from '../../../shared/shared-interfaces/shared-interfaces';
import { DateHelperService } from '../../../shared/shared-services/date-helper/date-helper.service';
import { ListItemDtl } from '../../mdm/openapi';
import {
  PayerAccount,
  PayerAccountInfo,
  PayerAccountList,
  PayerFamily,
  PayerFamilyList,
  PayerInfo,
  PayerLob,
  PayerLobList,
  PayerPlan,
  PayerPlanDetails,
  PayerPlanInfo,
  PayerPlanList
} from '../openapi';
import { FeatureFlagService } from '../../../shared/shared-services/feature-flag/feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class PayersMappingService {
  private omitTimelinessId = false;

  constructor(
    private dateHelperService: DateHelperService,
    private featureFlagService: FeatureFlagService
  ) {
    this.featureFlagService.getFlag('payers_go-upgrade_rollout')
      .subscribe(value => this.omitTimelinessId = value);
  }

  public static payerAccountToListItem(payers: PayerAccountList[]): ListItemDtl[] {
    return payers.map(payer => {
      return {
        listDtlCode: `${ payer.payerAcctId }`,
        listDtlDesc: payer.payerAcctName,
        listDtlStartDt: null
      };
    });
  }

  parsePayersFormValues(values: PayersFormValues): PayerFamily {
    const family: PayerFamily = {
      payerFamName: values.payerFamilyName,
      activeFromDt: !!values.activeFromDt ? this.dateHelperService.toISODate(values.activeFromDt) : null,
      activeToDt: !!values.activeToDt ? this.dateHelperService.toISODate(values.activeToDt) : null,
      domains: Array.isArray(values.domains) ? values.domains.map(domain => ({payerDomain: domain})) : []
    };
    return family;
  }

  parsePayersPlanDtoFormValues(payerPlan: PayerPlanDetails): PayerPlanFormValues {
    return {
      payerPlanId: payerPlan.payerPlanId,
      status: payerPlan.status,
      payerPlanName: payerPlan.payerPlanName,
      activeFromDt: this.dateHelperService.fromISO(payerPlan.activeFromDt),
      activeToDt: payerPlan.activeToDt ? this.dateHelperService.fromISO(payerPlan.activeToDt) : null,
    };
  }

  payerFamilyDtoToForm(payerFamily: PayerFamily): PayersFormValues {
    const parsedFormValues: PayersFormValues = {
      payerFamilyName: payerFamily.payerFamName,
      status: payerFamily.status,
      domains: Array.isArray(payerFamily.domains) ? payerFamily.domains.map(domain => domain.payerDomain) : [],
      activeFromDt: this.dateHelperService.fromISO(payerFamily.activeFromDt),
      activeToDt: payerFamily.activeToDt ? this.dateHelperService.fromISO(payerFamily.activeToDt) : null,
      createdBy: payerFamily.createdBy,
      createdTs: payerFamily.createdTs,
      modifiedBy: payerFamily.modifiedBy,
      modifiedTs: payerFamily.modifiedTs,
    };

    return parsedFormValues;
  }

  parsePayerAcctsFormVals(values: PayerAcctsFormVals): PayerAccount {
    const parsedValues: PayerAccount = {
      payerAcctName: values.payerAccountName,
      activeFromDt: !!values.activeFromDt ? this.dateHelperService.toISODate(values.activeFromDt) : null,
      activeToDt: !!values.activeToDt ? this.dateHelperService.toISODate(values.activeToDt) : null,
    };

    return parsedValues;
  }

  parsePayerAcctDtoToForm(payerAcct: PayerAccount): PayerAcctsFormVals {
    const accountValues: PayerAcctsFormVals = {
      payerAcctId: payerAcct.payerAcctId,
      payerAccountName: payerAcct.payerAcctName,
      status: payerAcct.status,
      activeFromDt: payerAcct.activeFromDt ? this.dateHelperService.fromISO(payerAcct.activeFromDt) : null,
      activeToDt: payerAcct.activeToDt ? this.dateHelperService.fromISO(payerAcct.activeToDt) : null,
      createdBy: payerAcct.createdBy,
      createdTs: payerAcct.createdTs,
      modifiedBy: payerAcct.modifiedBy,
      modifiedTs: payerAcct.modifiedTs,
    };
    return accountValues;
  }

  // PARSED PAYERS FOR GRID METHODS

  public familyListToFamilyInfo(payers: PayerFamilyList[]): PayerInfo[] {
    return payers.map((payer: PayerFamilyList) => {
      const payerInfo: PayerInfo = {
        payerFamId: payer.payerFamId,
        payerFamName: payer.payerFamName,
        status: payer.status,
        activeFromDt: payer.activeFromDt,
        activeToDt: payer.activeToDt,
        payerPlans: []
      };
      return payerInfo;
    });
  }

  public familyInfoToParsedPayer(payers: PayerInfo[]): ParsedPayer[] {
    const parsedPayers: ParsedPayer[] = payers.map((family: PayerInfo) => {
      const payerData: ParsedPayer = {
        payerId: family.payerFamId,
        payerFamId: family.payerFamId,
        payerName: family.payerFamName,
        payerStatus: family.status.toLowerCase(),
        activeDate: !!family.activeFromDt ? this.dateHelperService.toLocaleString(this.dateHelperService.fromISO(family.activeFromDt)) : '',
        benefitType: null,
        payerType: ParsedPayerType.FAMILY,
        isExpanded: false,
        hasChildren: false
      };
      if (family.payerPlans?.length) {
        payerData.hasChildren = true;
        payerData.children = this.planInfoToParsedPayer(family.payerPlans);
      }
      return payerData;
    });
    return parsedPayers;
  }

  public planListToPlanInfo(payers: PayerPlanList[]): PayerPlanInfo[] {
    return payers.map((plan: PayerPlanList) => {
      const planData: PayerPlanInfo = {
        payerPlanId: plan.payerPlanId,
        payerFamId: plan.payerFamId,
        payerPlanName: plan.payerPlanName,
        status: plan.status,
        activeFromDt: plan.activeFromDt,
        activeToDt: plan.activeToDt,
        payerAccts: []
      };
      return planData;
    });
  }

  public planInfoToParsedPayer = (payers: PayerPlanInfo[]): ParsedPayer[] => {
    return payers.map((plan: PayerPlanInfo) => {
      const payerData: ParsedPayer = {
        payerId: plan.payerPlanId,
        payerPlanId: plan.payerPlanId,
        payerFamId: plan.payerFamId,
        payerName: plan.payerPlanName,
        payerStatus: plan.status.toLowerCase(),
        activeDate: !!plan.activeFromDt ? this.dateHelperService.toLocaleString(this.dateHelperService.fromISO(plan.activeFromDt)) : '',
        benefitType: null,
        typeOfRelation: null,
        payerType: ParsedPayerType.PLAN,
        isExpanded: false,
        hasChildren: false
      };
      if (plan.payerAccts?.length) {
        payerData.hasChildren = true;
        payerData.children = this.accountInfoToParsedPayer(plan.payerAccts, plan.payerFamId, plan.payerPlanId);
      }
      return payerData;
    });
  }

  public accountListToAccountInfo(payers: PayerAccountList[]): PayerAccountInfo[] {
    return payers.map((account: PayerAccountList) => {
      const accountData: PayerAccountInfo = {
        payerAcctId: account.payerAcctId,
        payerPlanId: account.payerPlanId,
        payerAcctName: account.payerAcctName,
        status: account.status,
        activeFromDt: account.activeFromDt,
        activeToDt: account.activeToDt,
        payerLobs: []
      };
      return accountData;
    });
  }

  public accountInfoToParsedPayer = (payers: PayerAccountInfo[], payerFamId: number, payerPlanId: number): ParsedPayer[] => {
    return payers.map((acct: PayerAccountInfo) => {
      const payerData: ParsedPayer = {
        payerId: acct.payerAcctId,
        payerAcctId: acct.payerAcctId,
        payerPlanId,
        payerFamId,
        payerName: acct.payerAcctName,
        payerStatus: acct.status.toLowerCase(),
        activeDate: !!acct.activeFromDt ? this.dateHelperService.toLocaleString(this.dateHelperService.fromISO(acct.activeFromDt)) : '',
        benefitType: null,
        payerType: ParsedPayerType.ACCOUNT,
        isExpanded: false,
        hasChildren: false
      };
      if (acct.payerLobs?.length) {
        payerData.hasChildren = true;
        payerData.children = this.lobListToParsedPayer(acct.payerLobs, payerFamId, payerPlanId, acct.payerAcctId);
      }
      return payerData;
    });
  }

  public lobListToParsedPayer(payer: PayerLobList[], payerFamId: number, payerPlanId: number, payerAcctId: number): ParsedPayer[] {
    return payer.map((lob) => ({
      payerId: lob.payerLobId,
      payerAcctId,
      payerPlanId,
      payerFamId,
      payerName: lob.payerLobName,
      payerStatus: lob.status.toLowerCase(),
      activeDate: !!lob.activeFromDt ? this.dateHelperService.toLocaleString(this.dateHelperService.fromISO(lob.activeFromDt)) : '',
      benefitType: lob.benefitTypeCode,
      payerType: ParsedPayerType.LOB,
      isExpanded: false,
      hasChildren: false
    }));
  }

  // END PARSED_PAYERS FOR GRID METHODS

  parsePayersPlanFormValuesToDto(payerPlan: PayerPlanFormValues): PayerPlan {
    return {
      payerPlanName: payerPlan.payerPlanName,
      activeFromDt: !!payerPlan.activeFromDt ? this.dateHelperService.toISODate(payerPlan.activeFromDt) : null,
      activeToDt: payerPlan.activeToDt ? this.dateHelperService.toISODate(payerPlan.activeToDt) : null,
    };
  }

  parseAccountLOBFormToDTO(values: PayerLobFormValues): PayerLob {
    console.log('omitTimelinessId', this.omitTimelinessId);
    const payerLob: PayerLob = {
      payerLobName: values.payerLobName,
      activeFromDt: values.activeFromDt ? this.dateHelperService.toISODate(values.activeFromDt) : null,
      activeToDt: values.activeToDt ? this.dateHelperService.toISODate(values.activeToDt) : null,
      orderReceiptMethodCode: values.orderReceiptMethodCode,
      benefitTypeCode: values.benefitTypeCode,
      ormEnabled: !!values.ormEnabled,
      clmEnabled: !!values.clmEnabled,
      altSysID: values.altSysID ? values.altSysID : '',
      lobTimeliness: this.omitTimelinessId ?
        values.lobTimeliness.map(item => omit(item, ['timelinessID'])) : values.lobTimeliness,
      addresses: values.addresses.map(address => {
        if (!address.addressId || address.addressId < 0) {
          return omit(address, 'addressId');
        }
        return address;
      }),
      contacts: values.contacts.map(contact => {
        if (contact.contactId < 1) {
          delete contact.contactId;
        }

        contact.contactInfos.map(info => {
          if (info.contactInfoId < 1) {
            delete info.contactInfoId;
          }
          // contactId is readOnly and must be deleted or the
          // API will return an error
          delete info.contactId;
        });

        return contact;
      }),
    };

    payerLob.addresses = payerLob.addresses.map(address => {
      if (!address.addressLine2 || address.addressLine2 === '') {
        return omit(address, 'addressLine2');
      }
      return address;
    });

    return payerLob;
  }
}
