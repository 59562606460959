import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subscription } from 'rxjs';


export const FORM_TEXTBOX_ARRAY_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormTextboxArrayComponent),
  multi: true
};

@Component({
  selector: 'prism-form-textbox-array',
  templateUrl: './form-textbox-array.component.html',
  styleUrls: ['./form-textbox-array.component.scss'],
  providers: [FORM_TEXTBOX_ARRAY_CONTROL_VALUE_ACCESSOR]
})
export class FormTextboxArrayComponent implements ControlValueAccessor {

  private innerValue: any[];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public formArray: FormArray;

  private subs: Subscription;

  constructor() {
    this.formInit();
  }

  private formInit(defaultValues: any[] = []): void {
    this.formArray = new FormArray([]);
    defaultValues.filter(this.filterNull).forEach(value => {
      this.addNew(value);
    });
    this.addNew();
    this.subs = this.formArray.valueChanges.subscribe(values => {
      this.writeValue(values, true);
    });
  }

  private formReset(values: any[] = []): void {
    this.subs.unsubscribe();
    this.formInit(values);
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    this.writeValue(value);
  }

  writeValue(value: any, fromFormArray = false): void {
      this.innerValue = value;
      if (!fromFormArray && Array.isArray(this.innerValue)) {
        this.formReset(this.innerValue);
      }
      this.onChangeCallback(value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  addNew(value: any = ''): void {
    this.formArray.push(new FormControl(value));
  }

  remove(index: number): void {
    this.formArray.removeAt(index);
  }

  private filterNull(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
  }


}
