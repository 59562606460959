import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { ICON_SETTINGS, IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ContextMenuModule, MenuModule } from '@progress/kendo-angular-menu';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UtilsModule } from '@progress/kendo-angular-utils';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DialogsModule,
    GridModule,
    ButtonsModule,
    LayoutModule,
    MenuModule,
    ContextMenuModule,
    InputsModule,
    LabelModule,
    TreeViewModule,
    DropDownsModule,
    DropDownListModule,
    DateInputsModule,
    TreeListModule,
    IntlModule,
    IconsModule,
    TooltipModule,
    EditorModule,
    UtilsModule
  ],
  exports: [
    DialogsModule,
    GridModule,
    ButtonsModule,
    LayoutModule,
    MenuModule,
    ContextMenuModule,
    InputsModule,
    LabelModule,
    TreeViewModule,
    DropDownsModule,
    DropDownListModule,
    DateInputsModule,
    TreeListModule,
    IntlModule,
    IconsModule,
    TooltipModule,
    CommonModule,
    EditorModule,
    UtilsModule
  ],
  providers: [{ provide: ICON_SETTINGS, useValue: { type: 'font' } }]
})
export class KendoModule { }
